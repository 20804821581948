import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { OnboardingContext } from '../../../context/onboarding.context';
import { DashboardHttpService } from '../../../tools/api-services/dashboardHttpService';

export interface BusinessModelFormValues {
  businessModel: string;
  experience: string;
  needs: string[];
  package: string;
}

const businessModels = [
  'SaaS',
  'E-commerce / Manufacturing',
  'Professional Services',
  'Marketplace',
];
const experiences = [
  'First-time founder',
  'Need a refresher',
  'Financial director/CFO',
  'Familiar with P&L',
];
const needs = [
  'Fundraising',
  'Improve planning',
  'Understand my business',
  'Grow faster',
  'Improve profitability',
];

export const useBusinessModel = (isComplete = false) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const packageType = queryParams.get('package') || 'free';

  const context = useContext(OnboardingContext);
  const businessModel = localStorage.getItem('business-model');
  let businessModelValues;

  if (businessModel) {
    businessModelValues = JSON.parse(businessModel) as BusinessModelFormValues;
  }

  const submitWithoutLogin = async (values: BusinessModelFormValues) => {
    // todo ???
    context?.setOnboardingData?.(values);
    // todo ???
    localStorage.setItem('business-model', JSON.stringify(values));
    localStorage.setItem('package', packageType);
    const searchParams = new URLSearchParams(location.search);

    navigate(`/tutorials/2?${searchParams.toString()}`);
  };

  const submitOnComplete = async (values: BusinessModelFormValues) => {
    const result = await DashboardHttpService.submitNext(values);

    if (result.data.success) {
      navigate('/tutorials/2');
    }
  };

  const formik = useFormik<BusinessModelFormValues>({
    initialValues: {
      businessModel: businessModelValues?.businessModel || '',
      experience: businessModelValues?.experience || '',
      needs: businessModelValues?.needs || [],
      package: packageType || '',
    },
    onSubmit: async (values) => {
      if (isComplete) {
        await submitOnComplete(values);
        return;
      }

      await submitWithoutLogin(values);
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.businessModel) {
        errors.businessModel = 'Business model is required';
      }
      if (!values.experience) {
        errors.experience = 'Experience is required';
      }
      if (!values.needs.length) {
        errors.needs = 'Needs is required';
      }

      return errors;
    },
  });

  return {
    form: formik,
    businessModels,
    experiences,
    needs,
  };
};
