import { Container } from '../../../../shared-components/container';
import './style.css';
import { useContext, useEffect, useRef, useState } from 'react';
import { useStepper } from '../../hooks/stepper.hook';
import { tutorialThumbnails } from '../../../stepper/tutorial-video/tutorial-videos.type';
import tutorialLogo from '../../../../../../public/tutorial-logo.svg';
import playButton from '../../../../../../public/play-button.svg';
import YouTube from 'react-youtube';
import { DashboardHttpService } from '../../../../tools/api-services/dashboardHttpService';
import { trackOpenCabinetVideo } from '../../../../tools/analytics-events';
import { TutorialVideo } from '../../../../tools/api-services/types/tutorial-video';
import { RoleContext } from '../../../../context/role.context';
import { useNextTutorials } from '../../../../hooks/client/next-tutorials.hook';

const VideoCardComponent = ({
  idx,
  openModal,
  item,
}: {
  idx: number;
  openModal: (video: TutorialVideo) => void;
  item: TutorialVideo;
}) => {
  const getThumbnail = (videoItem: TutorialVideo) => {
    if (videoItem.thumbnail) return videoItem.thumbnail;
    return tutorialThumbnails.find(
      (it) => it.stepNumber === videoItem.stepNumber
    )?.thumbnail;
  };
  return (
    <div key={idx} className="video-card">
      <div onClick={() => openModal(item)} className="video-card_item">
        <div className="video-card_item-icon">
          <img src={playButton} alt="Play" />
        </div>
        <img className="card-thumbnail" src={getThumbnail(item)} alt="img" />
      </div>
      <p>{item.stepName}</p>
    </div>
  );
};
const VideoModal = ({
  closeModal,
  currentVideo,
}: {
  closeModal: () => void;
  currentVideo: TutorialVideo | null;
}) => {
  const ref = useRef<any>(null);

  const handleSeek = (seconds: number) => {
    ref.current.seekTo(seconds);
  };

  const onReady = (event: any) => {
    ref.current = event.target;
  };
  return (
    <div className="video-modal" onClick={closeModal}>
      <div className="video-modal-content-wrapper">
        <div
          className={`video-modal-content ${
            currentVideo?.timelines?.length ? '' : 'rounded'
          }`}
        >
          <YouTube
            videoId={currentVideo?.videoId}
            opts={{
              width: '100%',
              height: '100%',
              playerVars: {
                autoplay: 0,
                controls: 1,
                rel: 0,
              },
            }}
            onReady={onReady}
          />
        </div>
        {currentVideo?.timelines?.length && (
          <div className="tutorial-video-timeline">
            {currentVideo?.timelines?.map((item, idx) => (
              <div className="tutorial-video-timeline-item" key={idx}>
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSeek(item.seconds);
                  }}
                  className="tutorial-video-timeline-time"
                >
                  {item.timeLabel}
                </button>
                <p className="tutorial-video-timeline-label">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const OnboardingTutorials = ({ stepNumber }: { stepNumber: number }) => {
  const { userType } = useContext(RoleContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<TutorialVideo | null>(null);
  const [onboardingTutorials, setOnboardingTutorials] = useState<
    TutorialVideo[]
  >([]);
  const { businessModel } = useStepper();
  const { assignBasicNextTutorials } = useNextTutorials();

  const openModal = (video: TutorialVideo) => {
    trackOpenCabinetVideo(video.stepName);
    setCurrentVideo(video);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentVideo(null);
  };

  useEffect(() => {
    const fetchVideos = async () => {
      const response = await DashboardHttpService.getTutorialVideos();

      if (userType === 'BOOTSTRAP_NEXT') {
        const videosArray = assignBasicNextTutorials(response.data);
        setOnboardingTutorials(videosArray);
        return;
      }
      setOnboardingTutorials(response.data);
    };
    fetchVideos();
  }, [businessModel, stepNumber]);

  if (userType && !['BOOTSTRAP', 'BOOTSTRAP_NEXT'].includes(userType))
    return null;

  return (
    <Container extended={true}>
      <div className="video-tutorials-container">
        <div className="video-tutorials-header">
          <img src={tutorialLogo} alt="YouTube" className="youtube-logo" />
          <span className="video-tutorials-title">Tutorials</span>
        </div>
        <div className="video-tutorials">
          {onboardingTutorials.map((item, idx) => (
            <VideoCardComponent
              key={idx}
              idx={idx}
              openModal={openModal}
              item={item}
            />
          ))}
        </div>
      </div>
      {isModalOpen && (
        <VideoModal closeModal={closeModal} currentVideo={currentVideo} />
      )}
    </Container>
  );
};
