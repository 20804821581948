import { createContext } from 'react';

interface DrilldownDetails {
  date: {
    startDate: Date;
    endDate: Date;
  };
  name: string;
  identifier: string | null;
  setData?: (data: DrilldownDetails) => void;
}

export const DrilldownContext = createContext<DrilldownDetails | null>(null);
