import { useContext, useEffect } from 'react';
import {
  DataSourceDto,
  DataSourceListResponseDto,
  Provider,
} from '../../tools/api-services/types/connected-sources';
import { useState } from 'react';
import { ManagerCompaniesHttpService } from '../../tools/manager-api/http-companies';
import { DashboardHttpService } from '../../tools/api-services/dashboardHttpService';
import { ManagerHttpDataSourceService } from '../../tools/manager-api/manager-http-datasource';
import { IntegrationHttpService } from '../../tools/api-services/integrations.service';
import { useParams, useSearchParams } from 'react-router-dom';
import { ToasterContext } from '../../context/toaster.context';
import { useUser } from '../../context/user-details.context';
import {
  trackDeleteGSheetConnection,
  trackDeleteIntegration,
  trackStartImport,
} from '../../tools/analytics-events';

export const useDataSource = () => {
  const { companyId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const connection = searchParams.get('connection');
  const role = localStorage.getItem('role');
  const [connectedSources, setConnectedSources] =
    useState<DataSourceListResponseDto>({
      paymentSystems: [],
      accountingSystems: [],
      payrollSystems: [],
      crmSystems: [],
      gsheetFiles: [],
    });

  const { updateToaster } = useContext(ToasterContext);
  const { userDetails } = useUser();

  const handleDeleteSalesIntegration = async (salesIntegrationId: number) => {
    try {
      if (role === 'MANAGER') {
        await ManagerHttpDataSourceService.deleteSalesIntegration(
          salesIntegrationId
        );
        await fetchSources();
      }
      if (role === 'CLIENT') {
        await IntegrationHttpService.deleteSalesIntegration(salesIntegrationId);
        await fetchSources();
      }
      trackDeleteIntegration({
        companyName: userDetails.companyName,
        userName: userDetails.userName,
        userType: userDetails.userType,
      });
    } catch (error) {
      updateToaster({
        content: 'Sales integration delete failed',
        isError: true,
      });
      console.error(error);
    }
  };

  const handleDeleteCrmIntegration = async (crmIntegrationId: number) => {
    try {
      if (role === 'MANAGER') {
        await ManagerHttpDataSourceService.deleteCrmIntegration(
          crmIntegrationId
        );
        await fetchSources();
      }
      if (role === 'CLIENT') {
        await IntegrationHttpService.deleteCrmIntegration(crmIntegrationId);
        await fetchSources();
      }
    } catch (error) {
      updateToaster({
        content: 'Hubspot integration delete failed',
        isError: true,
      });
      console.error(error);
    }
  };

  const handleImportSalesIntegration = async (
    stripeIntegrationId: number,
    provider: Provider
  ) => {
    if (role === 'MANAGER') {
      try {
        const res = await ManagerHttpDataSourceService.importSalesIntegration(
          stripeIntegrationId,
          provider
        );
        if (res.status === 201) {
          updateToaster({
            content: 'Import is started',
            isError: false,
          });
          trackStartImport({
            companyName: userDetails.companyName,
            userName: userDetails.userName,
            userType: userDetails.userType,
          });
        }
      } catch (error) {
        updateToaster({
          content: 'Sales data import failed',
          isError: true,
        });
        console.error(error);
      }
    }
  };

  const handleImportPayrollIntegration = async (
    payrollIntegrationId: number
  ) => {
    if (role === 'MANAGER') {
      try {
        const res = await ManagerHttpDataSourceService.importPayrollIntegration(
          payrollIntegrationId
        );
        if (res.status === 201) {
          updateToaster({
            content: 'Import is started',
            isError: false,
          });
          trackStartImport({
            companyName: userDetails.companyName,
            userName: userDetails.userName,
            userType: userDetails.userType,
          });
        }
      } catch (error) {
        updateToaster({
          content: 'Payroll integration import failed',
          isError: true,
        });
        console.error(error);
      }
    }
  };

  const handleDeletePayrollIntegration = async (
    payrollIntegrationId: number
  ) => {
    try {
      if (role === 'MANAGER') {
        await ManagerHttpDataSourceService.deletePayrollIntegration(
          payrollIntegrationId
        );
        await fetchSources();
      }
      if (role === 'CLIENT') {
        await IntegrationHttpService.deletePayrollIntegration(
          payrollIntegrationId
        );
        await fetchSources();
      }
      trackDeleteIntegration({
        companyName: userDetails.companyName,
        userName: userDetails.userName,
        userType: userDetails.userType,
      });
    } catch (error) {
      updateToaster({
        content: 'Payroll integration delete failed',
        isError: true,
      });
      console.error(error);
    }
  };

  const handleCreatePayrollIntegration = async (
    gustoCode: string,
    provider: string
  ) => {
    try {
      if (role === 'MANAGER') {
        await ManagerHttpDataSourceService.createPayrollIntegration(
          Number(companyId),
          gustoCode,
          provider
        );
        updateToaster({
          content: 'Connection successful',
          isError: false,
        });
        window.location.reload();
        await fetchSources();
      }
      if (role === 'CLIENT') {
        await IntegrationHttpService.createPayrollIntegration(
          gustoCode,
          provider
        );
        updateToaster({
          content: 'Connection successful',
          isError: false,
        });
        window.location.reload();
        await fetchSources();
      }
    } catch (error: any) {
      if (error?.response?.status === 409) {
        updateToaster({
          content: 'You already have connected this account to the Fuel system',
          isError: true,
        });
      } else {
        updateToaster({
          content: 'Payroll integration create failed',
          isError: true,
        });
      }

      console.error(error);
    }
  };

  const handleImportCrmIntegration = async (
    hubspotIntegrationId: number,
    provider: Provider
  ) => {
    if (role === 'MANAGER') {
      try {
        const res = await ManagerHttpDataSourceService.importCrmIntegration(
          hubspotIntegrationId,
          provider
        );
        if (res.status === 201) {
          updateToaster({
            content: 'Import is started',
            isError: false,
          });
        }
      } catch (error) {
        updateToaster({
          content: 'Crm integration import failed',
          isError: true,
        });
        console.error(error);
      }
    }
  };

  const handleDeleteQuickbooksIntegration = async (
    stripeIntegrationId: number
  ) => {
    try {
      if (role === 'MANAGER') {
        await ManagerHttpDataSourceService.deleteQuickbooksIntegration(
          stripeIntegrationId
        );
        await fetchSources();
      }
      if (role === 'CLIENT') {
        await IntegrationHttpService.deleteQuickbooksIntegration(
          stripeIntegrationId
        );
        await fetchSources();
      }
      trackDeleteIntegration({
        companyName: userDetails.companyName,
        userName: userDetails.userName,
        userType: userDetails.userType,
      });
    } catch (error) {
      updateToaster({
        content: 'Quickbooks integration delete failed',
        isError: true,
      });
      console.error(error);
    }
  };

  const handleImportQuickbooksIntegration = async (
    stripeIntegrationId: number
  ) => {
    if (role === 'MANAGER') {
      try {
        const res =
          await ManagerHttpDataSourceService.importQuickbooksIntegration(
            stripeIntegrationId
          );
        if (res.status === 201) {
          updateToaster({
            content: 'Import is started',
            isError: false,
          });
          trackStartImport({
            companyName: userDetails.companyName,
            userName: userDetails.userName,
            userType: userDetails.userType,
          });
        }
      } catch (error) {
        updateToaster({
          content: 'Quickbooks integration import failed',
          isError: true,
        });
        console.error(error);
      }
    }
  };

  const handleDeleteGSheetConnection = async (fileId: number) => {
    try {
      await ManagerHttpDataSourceService.deleteGSheetConnection(Number(fileId));
      await fetchSources();
      trackDeleteGSheetConnection({
        companyName: userDetails.companyName,
        userName: userDetails.userName,
        userType: userDetails.userType,
      });
    } catch (error) {
      updateToaster({
        content: 'GSheet connection delete failed',
        isError: true,
      });
      console.error(error);
    }
  };

  const handleImportGSheetConnection = async (
    gsheetConnectionId: number,
    type: string
  ) => {
    if (role === 'MANAGER') {
      try {
        const res = await ManagerHttpDataSourceService.importGSheetConnection(
          gsheetConnectionId,
          type
        );
        if (res.status === 201) {
          updateToaster({
            content: 'Import is started',
            isError: false,
          });
          trackStartImport({
            companyName: userDetails.companyName,
            userName: userDetails.userName,
            userType: userDetails.userType,
          });
        }
      } catch (error) {
        updateToaster({
          content: 'GSheet integration import failed',
          isError: true,
        });
        console.error(error);
      }
    }
  };

  const fetchSources = async () => {
    if (role === 'MANAGER') {
      try {
        const dataSourcesResponse =
          await ManagerCompaniesHttpService.companyDataSources(
            Number(companyId)
          );
        setConnectedSources({
          paymentSystems: dataSourcesResponse.data.paymentSystems,
          accountingSystems: dataSourcesResponse.data.accountingSystems,
          payrollSystems: dataSourcesResponse.data.payrollSystems,
          crmSystems: dataSourcesResponse.data.crmSystems,
          gsheetFiles: dataSourcesResponse.data.gsheetFiles,
        });
      } catch (error) {
        updateToaster({
          content: 'Fetching data sources failed',
          isError: true,
        });
        console.error(error);
      }
    }

    if (role === 'CLIENT') {
      try {
        const dataSourcesResponse =
          await DashboardHttpService.companyDataSources();
        setConnectedSources({
          paymentSystems: dataSourcesResponse.data.paymentSystems,
          accountingSystems: dataSourcesResponse.data.accountingSystems,
          payrollSystems: dataSourcesResponse.data.payrollSystems,
          crmSystems: dataSourcesResponse.data.crmSystems,
          gsheetFiles: dataSourcesResponse.data.gsheetFiles,
        });
      } catch (error) {
        updateToaster({
          content: 'Fetching data sources failed',
          isError: true,
        });
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchSources();
    if (connection === 'success') {
      updateToaster({
        content: 'Connection successful',
        isError: false,
      });
      searchParams.delete('connection');
      setSearchParams(searchParams);
    }
  }, [connection]);

  return {
    connectedSources,
    handleDeleteSalesIntegration,
    handleImportSalesIntegration,
    handleDeleteQuickbooksIntegration,
    handleImportQuickbooksIntegration,
    handleDeleteGSheetConnection,
    handleImportPayrollIntegration,
    handleDeletePayrollIntegration,
    handleCreatePayrollIntegration,
    handleDeleteCrmIntegration,
    handleImportCrmIntegration,
    handleImportGSheetConnection,
  };
};
