import { useEffect, useRef, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { QuickbooksNotificationStatus } from '../shared-components/types/quickbooks-notification';
import { useNavigate } from 'react-router-dom';

export const useNotificationService = (token: string | null) => {
  const socketRef = useRef<Socket | null>(null);
  const [reportStatuses, setReportStatuses] = useState<
    QuickbooksNotificationStatus[]
  >([]);
  const [importStatuses, setImportStatuses] = useState<
    QuickbooksNotificationStatus[]
  >([]);
  const navigate = useNavigate();
  const [calendlyMessage, setCalendlyMessage] = useState<string>('');

  const clearAfterTimeout = (
    setter: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    setTimeout(() => setter((statuses) => statuses.slice(1)), 5000);
  };

  useEffect(() => {
    if (!token) return;
    socketRef.current = io(import.meta.env.VITE_APP_BACKEND_URL);
    socketRef.current.emit('requestForRoom', token);

    socketRef.current.on('connect_error', (error) => {
      console.error('Socket Connect Error:', error);
    });

    socketRef.current.on(
      'importStatus',
      (status: QuickbooksNotificationStatus) => {
        setImportStatuses((prev) => [...prev, status]);
        clearAfterTimeout(setImportStatuses);
      }
    );

    socketRef.current.on(
      'reportStatus',
      (status: QuickbooksNotificationStatus) => {
        setReportStatuses((prev) => [...prev, status]);
        clearAfterTimeout(setReportStatuses);
      }
    );

    socketRef.current.on('calendly', (message) => {
      setCalendlyMessage(message);
    });

    socketRef.current.on('sessionExpire', (message) => {
      localStorage.removeItem('token');
      localStorage.removeItem('userDetails');
      navigate('login');
    });

    return () => {
      socketRef.current?.disconnect();
    };
  }, [token]);

  return { importStatuses, reportStatuses, calendlyMessage };
};
