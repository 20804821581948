import {
  CategoriesBalanceTableDataset,
  OpexCogsTableDataset,
  OutflowCategoriesTableDataset,
} from '../../../../../../hooks/client/dashboard/interfaces/dashboard-card.type';
import './dashboard-table.style.css';

const Cell = (props: { type: string; children: React.ReactNode }) => {
  const { type, children } = props;

  let color = 'black';

  if (type === 'BUDGET') {
    color = '#00000080';
  }

  return <td style={{ color }}>{children}</td>;
};

export const CardTable = ({
  dataset,
  name,
}: {
  dataset: OpexCogsTableDataset;
  name: string;
}) => {
  if (!dataset.rows.length)
    return (
      <div className={'table-container'}>
        <p>{name}</p>
        <p>No data provided for the reporting month</p>
      </div>
    );

  const totalRow = (
    <tr
      style={{
        borderBottom: '2px solid black',
      }}
    >
      <td style={{ fontWeight: 'bold' }}>Total</td>
      <td style={{ fontWeight: 'bold' }}>{dataset.totalCurrentMonth}</td>
      <td>{dataset.totalPreviousMonth}</td>
      <td></td>
    </tr>
  );

  return (
    <div className="table-container">
      <table>
        <thead className="table-header">
          <tr>
            <th className="table-header-name">{name}</th>
            <th className="table-header-column">{dataset.currentHeader}</th>
            <th className="table-header-column">{dataset.previousHeader}</th>
            <th className="table-header-column">change, %</th>
          </tr>
        </thead>
        <tbody>
          {totalRow}
          {dataset.rows.map((row, index) => (
            <tr key={index}>
              <td>{row.category}</td>
              <Cell type={row.current.type}>{row.current.value}</Cell>
              <Cell type={row.previous.type}>{row.previous.value}</Cell>
              <td className="change">
                {row.change > 0 ? (
                  <span className="positive">↑ {row.change}%</span>
                ) : (
                  <span className="negative">↓ {row.change}%</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const CategoriesCardTable = ({
  dataset,
  name,
}: {
  dataset: OutflowCategoriesTableDataset;
  name: string;
}) => {
  if (!dataset.rows.length)
    return (
      <div className={'table-container'}>
        <p>{name}</p>
        <p>No data provided for the reporting month</p>
      </div>
    );

  const totalRow = (
    <tr
      style={{
        borderBottom: '2px solid black',
      }}
    >
      <td style={{ fontWeight: 'bold' }}>Total</td>
      <td style={{ fontWeight: 'bold' }}>{dataset.totalBudget}</td>
      <td>{dataset.totalActual}</td>
      <td>
        {dataset.totalDeviation > 0 ? (
          <span className="negative">↑ {dataset.totalDeviation}%</span>
        ) : (
          <span className="positive">↓ {dataset.totalDeviation}%</span>
        )}
      </td>
    </tr>
  );

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th className="table-header table-header-name">{name}</th>
            <th className="table-header table-header-column">budget</th>
            <th className="table-header table-header-column">actual</th>
            <th className="table-header table-header-column">deviation, %</th>
          </tr>
        </thead>
        <tbody>
          {totalRow}
          {dataset.rows.map((row, index) => (
            <tr key={index}>
              <td>{row.category}</td>
              <Cell type={row.budget.type}>{row.budget.value}</Cell>
              <Cell type={row.actual.type}>{row.actual.value}</Cell>
              <td className="change">
                {row.deviation > 0 ? (
                  <span className="negative">↑ {row.deviation}%</span>
                ) : (
                  <span className="positive">↓ {row.deviation}%</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const TopCategoriesBalanceCardTable = ({
  dataset,
  name,
}: {
  dataset: CategoriesBalanceTableDataset;
  name: string;
}) => {
  if (!dataset.rows.length)
    return (
      <div className={'no-data-container'}>
        <p>{name}</p>
        <p>No data provided for the reporting month</p>
      </div>
    );

  return (
    <div className="balance-table-container">
      <table>
        <thead>
          <tr>
            <th className="balance-table-header balance-table-header-name">
              {name}
            </th>
            <th className="balance-table-header balance-table-header-column"></th>
            <th
              className="balance-table-header balance-table-header-column balance-table-header-column-date"
              style={{
                textAlign: 'right', // Align the date to the right of the header
              }}
            >
              <span
                style={{
                  color: '#1D1B2080',
                  fontSize: '10px',
                }}
              >
                for {dataset.date}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {dataset.rows.map((row, index) => (
            <tr key={index}>
              <td className="balance-table-cell">{row.category}</td>
              <td className="balance-table-cell">${row.value}</td>
              <td className="balance-table-cell"></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
