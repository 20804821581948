import React, { useContext, useState, useEffect } from 'react';
import { Button } from '../../../../shared-components/button';
import './styles.css';
import { RadioInput } from '../../../../shared-components/radio-input/radio.component';
import { SquareX } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../../context/user-details.context';
import { ToasterContext } from '../../../../context/toaster.context';
import { ManagerHttpDataSourceService } from '../../../../tools/manager-api/manager-http-datasource';
import { ErrorContent, ErrorNoAccess } from './headers-error.modal';

import {
  trackGSheetImportFinancial,
  trackGSheetImportPayroll,
  trackGSheetImportSales,
} from '../../../../tools/analytics-events';
import { googleAuthFlow } from '../../../../../tools/google-auth';

export const GSheetConnectModal = ({
  onClose,
  openProgressModal,
}: {
  onClose: () => void;
  openProgressModal: () => void;
}) => {
  const { companyId } = useParams();
  const { userDetails } = useUser();
  const ctx = useContext(ToasterContext);
  const [dataType, setDataType] = useState<string>('Financial');
  const [title, setTitle] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [modalHeader, setModalHeader] = useState<string>('Connect GS file');
  const [errorHeaders, setErrorHeaders] = useState<[]>([]);

  const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
  const scopes = ['https://www.googleapis.com/auth/spreadsheets'];

  const handleGoogleSignIn = async () => {
    try {
      const code = await googleAuthFlow(clientId, scopes); // Get the auth code
      return code;
    } catch (error) {
      console.error('Google sign-in failed:', error);
    }
  };

  const handleDataTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataType(event.target.value);
  };

  const isValidGoogleSheetLink = (link: string) => {
    const googleSheetRegex =
      /https:\/\/docs\.google\.com\/spreadsheets\/d\/([a-zA-Z0-9_-]+)\/.+\?gid=([0-9]+)/;
    return googleSheetRegex.exec(link) || '';
  };

  const handleImportGSheet = async () => {
    if (!title || !link) {
      ctx.updateToaster({
        content: 'Please enter both title and link',
        isError: true,
      });
      return;
    }

    const gsheetMeta = isValidGoogleSheetLink(link);

    if (!gsheetMeta) {
      ctx.updateToaster({
        content: 'Invalid Google Sheet link',
        isError: true,
      });
      return;
    }

    try {
      const code = await handleGoogleSignIn();
      await ManagerHttpDataSourceService.connectGSheet(
        Number(companyId),
        dataType,
        code as string,
        title,
        { spreadsheet_id: gsheetMeta[1], sheet_id: parseInt(gsheetMeta[2]) }
      );

      switch (dataType) {
        case 'Financial':
          trackGSheetImportFinancial({
            companyName: userDetails.companyName,
            userName: userDetails.userName,
            userType: userDetails.userType,
          });
          break;
        case 'Sales':
          trackGSheetImportSales({
            companyName: userDetails.companyName,
            userName: userDetails.userName,
            userType: userDetails.userType,
          });
          break;
        case 'Payroll':
          trackGSheetImportPayroll({
            companyName: userDetails.companyName,
            userName: userDetails.userName,
            userType: userDetails.userType,
          });
          break;
        default:
          break;
      }

      onClose();
      openProgressModal();
    } catch (err: any) {
      if (err?.response?.status === 409) {
        const headers: [] = err?.response?.data?.message;
        setErrorHeaders(headers);
        setModalHeader('We can’t connect the file');
      } else if (err?.response?.status === 400) {
        ctx.updateToaster({
          content: 'Error uploading Google Sheet',
          isError: true,
        });
      } else if (err?.response?.status === 403) {
        setModalHeader('We can’t connect the file');
      } else if (err) {
        console.error(err);
        ctx.updateToaster({
          content: 'Error uploading Google Sheet',
          isError: true,
        });
      }
    }
  };

  return (
    <div className="csv-upload-modal">
      <div className="csv-upload-modal__header">
        <span style={{ fontSize: '20px', lineHeight: '24px' }}>
          {modalHeader}
        </span>
        <SquareX
          className="close-cross-button"
          onClick={onClose}
          style={{ width: '55px', height: '55px' }}
          strokeWidth={'1'}
        />
      </div>

      {modalHeader === 'Connect GS file' && (
        <>
          <div className="csv-data-types">
            <RadioInput
              value="Financial"
              checked={dataType === 'Financial'}
              name={'Financial'}
              handleChange={handleDataTypeChange}
            />
            <RadioInput
              value="Sales"
              checked={dataType === 'Sales'}
              name={'Sales'}
              handleChange={handleDataTypeChange}
            />
            <RadioInput
              value="Payroll"
              checked={dataType === 'Payroll'}
              name={'Payroll'}
              handleChange={handleDataTypeChange}
            />
          </div>
          <div className="csv-upload-modal__input-fields">
            <div className="input-group">
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
              />
            </div>
            <div className="input-group">
              <input
                type="text"
                id="link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                placeholder="Link"
              />
            </div>
          </div>
          <Button
            mini={true}
            active
            onClick={handleImportGSheet}
            style={{ width: '30%' }}
          >
            Add
          </Button>
        </>
      )}
      {modalHeader !== 'Connect GS file' &&
        (errorHeaders?.length === 0 ? (
          <ErrorNoAccess />
        ) : (
          <ErrorContent errorHeaders={errorHeaders} />
        ))}
    </div>
  );
};
