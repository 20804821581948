import { Trash2 } from 'lucide-react';
import { DataSourceDto } from '../../../tools/api-services/types/connected-sources';
import './styles.css';
import { useContext } from 'react';
import { RoleContext } from '../../../context/role.context';
import { formatDateTime } from '../../../utils/dates/formatter';

export interface DataSourceItem {
  source: DataSourceDto;
  handleDelete: (sourceId: number) => void;
  handleImport?: (sourceId: number) => void;
  icon: string;
  type: string;
}

const DataSourceItem = ({
  source,
  handleDelete,
  handleImport,
  icon,
  type,
}: DataSourceItem) => {
  const { role } = useContext(RoleContext);
  const sourceLastImportTime = source.lastImportTime
    ? formatDateTime(source.lastImportTime)
    : '';
  return (
    <div className="data-source__item" key={source.id}>
      <div className="data-source__name">
        <img
          src={icon}
          alt={`${type} integration`}
          style={{ width: '32px', height: '32px' }}
        />
        <span style={{ fontSize: '17px', lineHeight: '24px' }}>
          {source.name}
        </span>
      </div>
      <div className="data-source__actions">
        {handleImport && (
          <button
            className="data-source-import-button"
            onClick={() => handleImport(source.id)}
            style={{
              background: 'none',
              border: 'none',
              textDecorationLine: 'underline',
              cursor: 'pointer',
              fontSize: '15px',
            }}
          >
            Import
          </button>
        )}
        <Trash2
          onClick={() => handleDelete(source.id)}
          style={{ cursor: 'pointer' }}
        />
        <span
          className={'import-status'}
          style={{
            display: role === 'MANAGER' ? 'block' : 'none',
            textAlign: 'right',
            fontSize: '10px',
            color: source.lastImportStatus === 'Failed' ? '#FF5D5D' : '#B3B3B3',
          }}
        >
          Last update:{' '}
          {source.lastImportStatus === 'Failed'
            ? 'Failed'
            : sourceLastImportTime}
        </span>
      </div>
    </div>
  );
};

export default DataSourceItem;
