import cover1 from '../../../../../public/images/cover1.png';
import cover2 from '../../../../../public/images/cover2.png';
import cover3 from '../../../../../public/images/cover3.png';
import cover4 from '../../../../../public/images/cover4.png';
import cover5 from '../../../../../public/images/cover5.png';

export interface TutorialThumbnail {
  stepNumber: number;
  thumbnail: string;
}

export const tutorialThumbnails: TutorialThumbnail[] = [
  {
    stepNumber: 2,
    thumbnail: cover1,
  },
  {
    stepNumber: 3,
    thumbnail: cover2,
  },
  {
    stepNumber: 4,
    thumbnail: cover3,
  },
  {
    stepNumber: 5,
    thumbnail: cover4,
  },
  {
    stepNumber: 6,
    thumbnail: cover5,
  },
];
