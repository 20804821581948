import { TutorialVideo } from '../../tools/api-services/types/tutorial-video';
import NEXT_RUNAWAY_CALCULATOR from '../../../../public/images/NEXT_RUNAWAY_CALCULATOR.png';
import Dilution from '../../../../public/images/Dilution.png';

export const useNextTutorials = () => {
  const basicNextTutorials: TutorialVideo[] = [
    {
      id: '1',
      stepName: 'Dilution modeling',
      timelines: [],
      videoId: 'AcVeLlXMJzk',
      stepNumber: 2,
      businessModel: 'All',
      thumbnail: Dilution,
    },
    {
      id: '1',
      stepName: 'Runway',
      timelines: [],
      videoId: 'hN1YJuSlkdE',
      stepNumber: 2,
      businessModel: 'All',
      thumbnail: NEXT_RUNAWAY_CALCULATOR,
    },
  ];

  const assignBasicNextTutorials = (tutorials: TutorialVideo[]) => {
    return [...basicNextTutorials, ...tutorials];
  };

  return {
    assignBasicNextTutorials,
  };
};
