export const metricTypes = [
  'Net Income',
  'Expenses',
  'Debt',
  'Equity',
  'Investments',
  'Revenues',
  'Operating Expenses',
  'Net Cash Flow',
  'Earnings Per Share',
  'Return on Assets',
  'Return on Equity',
  'Current Ratio',
  'Quick Ratio',
  'Debt to Equity',
  'Gross Margin',
  'Operating Margin',
  'Net Margin',
  'Asset Turnover',
  'Free Cash Flow to Equity',
  'Current Assets',
  'Current Liabilities',
  'Long-term Debt',
  'Shareholders Equity',
  'Total Assets',
  'Total Liabilities',
  'Total Debt',
  'Total Revenue',
  'Total Expenses',
  'Net Income Before Tax',
  'Taxes',
  'Net Income After Tax',
  'EPS',
  'PEG Ratio',
];

export const metricGroups = [
  'Financial Metrics',
  'Profitability Metrics',
  'Growth Metrics',
  'Valuation Metrics',
  'Financial Health Metrics',
  'Management Metrics',
  'Operational Metrics',
  'Financial Performance Metrics',
  'Financial Ratios',
  'Financial Statements',
  'Market Metrics',
  'Environmental Metrics',
  'Investment Metrics',
  'Financial Control Metrics',
  'Risk Management Metrics',
  'Performance Metrics',
  'Fundamentals Metrics',
  'Competitors Metrics',
  'Executive Metrics',
  'Financial Projections',
  'Technical Metrics',
  'Analyst Metrics',
  'Company Metrics',
  'Investor Metrics',
  'Consumer Metrics',
  'Education Metrics',
  'Healthcare Metrics',
  'Real Estate Metrics',
  'Telecommunications Metrics',
  'Travel & Leisure Metrics',
  'Retail Metrics',
  'Insurance Metrics',
  'Banking Metrics',
  'Energy Metrics',
];
