import React from 'react';

interface SearchBarProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  placeholder: string;
  onSearch: () => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  searchValue,
  setSearchValue,
  placeholder,
  onSearch,
}) => {
  return (
    <div className="mapping-search-bar">
      <input
        type="text"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={placeholder}
        className="mapping-search-input"
      />
      <button onClick={onSearch} className="mapping-search-button">
        Search
      </button>
    </div>
  );
};
