import { DateTime } from 'luxon';
import { DashboardDataItem } from './interfaces/dashboard-data-item.interface';

export const generateRandomHistogramData = (length: number) => {
  const data = [];

  const date = DateTime.now();

  const yearsBefore = date.minus({ years: 2 });

  for (let i = 0; i < length; i++) {
    data.push({
      name: `${yearsBefore.plus({ months: i }).toFormat('yyyy-MM')}`,
      value1: Math.floor(Math.random() * 1000),
      value2: Math.floor(Math.random() * 1000),
    });
  }
  return { data, keys: ['value1', 'value2'], indexBy: 'name' };
};

export const generateRandomSummary = () => {
  return {
    value: Math.floor(Math.random() * 10000),
  };
};

export const generateRaceChartData = (length: number) => {
  const data = [];
  for (let i = 0; i < length; i++) {
    data.push({
      name: `Item ${i}`,
      value: Math.floor(Math.random() * 1000),
    });
  }
  return { data, keys: ['value'], indexBy: 'name' };
};

export const getLongestDateRange = (
  collections: DashboardDataItem[][]
): {
  start: DateTime;
  end: DateTime;
  rangeInMonths: number;
} => {
  let earliest: DateTime | null = null;
  let latest: DateTime | null = null;

  collections.forEach((collection) => {
    collection.forEach((item) => {
      const currentDate = DateTime.fromObject({
        year: item.year,
        month: item.month,
      });

      if (!earliest || currentDate < earliest) {
        earliest = currentDate;
      }

      if (!latest || currentDate > latest) {
        latest = currentDate;
      }
    });
  });
  if (earliest && latest) {
    const rangeInMonths = latest.diff(earliest, 'months').months;
    return {
      start: earliest,
      end: latest,
      rangeInMonths: Math.round(rangeInMonths),
    };
  }

  throw new Error('Collections are empty or invalid');
};

export const calculatePercentageChange = (
  current: number,
  previous: number | undefined
) => {
  if (previous === 0 || !previous) return 100;
  const change = ((current - previous) / previous) * 100;
  return Number(change.toFixed(2));
};

export const calculateRatio = (
  numerator?: number,
  denominator?: number
): number => {
  if (
    numerator === undefined ||
    denominator === undefined ||
    denominator === 0
  ) {
    return 0;
  }
  return numerator / denominator;
};

export const findMetricByDate = (
  metrics: DashboardDataItem[],
  year: number,
  month: number
): DashboardDataItem | undefined => {
  return metrics.find(
    (metric) => metric.year === year && metric.month === month
  );
};

export const getMonthName = (monthNumber: number) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return monthNames[monthNumber - 1].toUpperCase();
};

export const getLastClosedMonth = (endDate: Date | null) => {
  const referenceDate = endDate ? new Date(endDate) : new Date();

  let targetMonth = referenceDate.getMonth();
  let targetYear = referenceDate.getFullYear();

  if (!endDate) {
    if (targetMonth === 0) {
      targetMonth = 11;
      targetYear -= 1;
    } else {
      targetMonth -= 1;
    }
  }

  return { month: targetMonth + 1, year: targetYear };
};
