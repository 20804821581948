import { ResponsiveLine } from '@nivo/line';
import { DefaultTooltipBody } from './components/default-tooltip';
import {
  convertToDateRange,
  formatDate,
} from '../../../../utils/dashboard/formatters';
import { DrilldownContext } from '../../../../hooks/client/dashboard/drill-down/drill-down.context';
import { useContext } from 'react';

interface LineProps {
  data: any;
  options?: any;
  name: string;
  identifier: string;
}

const CustomPointLabelLayer =
  (properties: any) =>
  ({ points }: any) =>
    (
      <>
        {points.map((point: any, index: number) => {
          const val = properties.options?.percents
            ? ((point.data.y as number) * 100).toFixed(1) + '%'
            : point.data.y;

          return (
            <g key={index} transform={`translate(${point.x}, ${point.y - 10})`}>
              <text
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fontSize: 10,
                  fill: 'white',
                  strokeWidth: 3,
                  stroke: 'white',
                  paintOrder: 'stroke',
                  pointerEvents: 'none',
                }}
              >
                {val}
              </text>
              <text
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fontSize: 10,
                  fill: 'black',
                  pointerEvents: 'none',
                }}
              >
                {val}
              </text>
            </g>
          );
        })}
      </>
    );

export const LineChart = (properties: LineProps) => {
  const drillDownContext = useContext(DrilldownContext);
  const { dataset } = properties.data;

  const lines = dataset.map((data: any) => {
    return {
      id: data.key,
      data: data.data,
    };
  });

  const handleClick = (event: any, point: any) => {
    event.stopPropagation();

    const date = point.data.x;
    if (drillDownContext?.setData) {
      drillDownContext.setData({
        date: convertToDateRange(date),
        name: properties.name || '',
        identifier: properties.identifier || '',
      });
    }
  };

  return (
    <div className={'drag-cancel'} style={{ width: '100%', height: '100%' }}>
      <ResponsiveLine
        data={lines}
        margin={{ top: 50, right: 20, bottom: 60, left: 30 }}
        enableGridY={false}
        enableGridX={false}
        onClick={(point, event) => handleClick(event, point)}
        curve={properties.data?.curve || 'linear'}
        enablePoints={
          properties.data?.enablePoints !== undefined
            ? properties.data?.enablePoints
            : true
        }
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false,
        }}
        axisLeft={null}
        axisBottom={{
          format: (value: string) => formatDate(value),
          tickValues: lines[0]?.data.map((d: { x: number }) => d.x),
          tickSize: 0,
          tickPadding: 30,
        }}
        colors={properties.options?.colors || { scheme: 'greens' }}
        useMesh={true}
        tooltip={({ point }) => {
          const { serieId, data } = point;
          const value = properties.options?.percents
            ? `${((data.y as number) * 100).toFixed(1)}%`
            : data.y;
          const date = data.x;
          let marginLeft = '0px';
          const chartWidth = 800;
          const pointPositionX = point.x;

          if (pointPositionX < 100) {
            marginLeft = '150px';
          } else if (pointPositionX > chartWidth - 100) {
            marginLeft = '-150px';
          }
          return (
            <div style={{ marginLeft, position: 'relative' }}>
              <DefaultTooltipBody color={point.color}>
                <p>{serieId}</p>
                <p>{formatDate(date as string)}</p>
                <p>{value as number}</p>
              </DefaultTooltipBody>
            </div>
          );
        }}
        enableTouchCrosshair={false}
        layers={[
          'grid',
          'markers',
          'areas',
          'lines',
          CustomPointLabelLayer(properties),
          'points',
          'slices',
          'mesh',
          'axes',
          'legends',
        ]}
        legends={[
          {
            anchor: 'top-right',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: -50,
            itemsSpacing: 50,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'right-to-left',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};
