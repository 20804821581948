import './dynamic-table.style.css';

interface TableProps {
  tableName: string;
  columns: string[];
  data: { [key: string]: string | number }[];
}

export const DynamicCardTable = (props: TableProps) => {
  const { columns, data } = props;

  return (
    <div className="dynamic-table-container">
      <div className="dynamic-table-header">
        {columns.map((column, index) => (
          <div
            key={index}
            className={`dynamic-table-header-column dynamic-table-header-column-${index}`}
          >
            {column}
          </div>
        ))}
      </div>
      <div className="dynamic-table-body">
        {data.map((row, rowIndex) => (
          <div key={rowIndex} className="dynamic-table-row">
            {columns.map((column, colIndex) => (
              <div
                key={colIndex}
                className={`dynamic-table-cell dynamic-table-cell-${colIndex}`}
              >
                {row[column]}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
