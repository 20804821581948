import { useState } from 'react';
import { DefaultProps } from './types/default';
import closeEye from '../../../public/images/close-eye.png';
import openEye from '../../../public/images/open-eye.png';

export interface InputProps extends DefaultProps {
  handleChange: any;
  onFocus?: any;
  onBlur?: any;
  error?: any;
  value: string;
  placeholder: string;
  controlName: string;
  type: string;
  showPasswordToggle?: boolean;
}

export const Input = (props: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const inputType =
    props.type === 'password' && props.showPasswordToggle
      ? showPassword
        ? 'text'
        : 'password'
      : props.type;
  const errorClass = props.error ? 'input-error' : '';

  return (
    <div className="password-input-container">
      <input
        className={`input ${errorClass}`}
        id={props.controlName}
        type={inputType}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.handleChange}
        onFocus={props?.onFocus}
        onBlur={props?.onBlur}
      />
      {props.type === 'password' && props.showPasswordToggle && (
        <img
          src={inputType === 'text' ? openEye : closeEye}
          alt="eye"
          className={`${props.controlName}-show-password-icon`}
          onClick={togglePasswordVisibility}
        ></img>
      )}
    </div>
  );
};
