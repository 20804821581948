import { useEffect, useState } from 'react';
import { Head } from '../../../shared-components/head';
import { Container } from '../../../shared-components/container';
import { useCompanyDocuments } from './hooks/company-documents.hook';
import { Button } from '../../../shared-components/button';
import { DocumentsLoadingComponent } from './components/documents-loading.component';
import { DocumentButton } from './components/document-button.component';
import { AddReportModal } from './modals/add-document.modal';
import { DeleteReportModal } from './modals/delete-document.modal';
import './styles.css';
import { useParams } from 'react-router-dom';
import { useCompanyInfo } from './hooks/company-info.hook';

export const CompanyDocuments = () => {
  const { companyId } = useParams();
  const { isLoading, documents, fetchDocuments } = useCompanyDocuments();
  const { companyInfo, fetchCompanyInfo } = useCompanyInfo();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [documentIdToDelete, setDocumentIdToDelete] = useState<number | null>(
    null
  );

  const handleAddClick = () => {
    setIsAddModalOpen(true);
  };

  const handleDeleteIconClick = (documentId: number) => {
    setIsDeleteModalOpen(true);
    setDocumentIdToDelete(documentId);
  };

  const handleCloseModal = () => {
    setIsAddModalOpen(false);
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    if (companyId) {
      fetchDocuments(Number(companyId));
      fetchCompanyInfo(Number(companyId));
    }
  }, []);

  return (
    <Container extended={true}>
      {isLoading ? (
        <DocumentsLoadingComponent documentsLoading={isLoading} />
      ) : (
        <div className="company-document-container">
          <div className="company-document-header">
            <Head>{companyInfo?.name} reports</Head>
            <Button mini={true} active={true} onClick={handleAddClick}>
              Add
            </Button>
          </div>
          <div className="company-document-holder">
            {documents.map((document, index) => (
              <DocumentButton
                key={index}
                {...document}
                onDelete={() => handleDeleteIconClick(document.id)}
              />
            ))}
          </div>
        </div>
      )}
      {(isAddModalOpen || isDeleteModalOpen) && (
        <div className="modal-overlay" />
      )}
      {isAddModalOpen && (
        <AddReportModal
          onClose={handleCloseModal}
          fetchDocuments={fetchDocuments}
          companyId={Number(companyId)}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteReportModal
          onClose={handleCloseModal}
          documentId={documentIdToDelete}
          fetchDocuments={fetchDocuments}
          companyId={Number(companyId)}
        />
      )}
    </Container>
  );
};
