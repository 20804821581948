import { reformatToThousandsWithoutSign } from '../../../../utils/dashboard/formatters';
import { DateTime } from 'luxon';

export const Summary = (props: {
  name?: string;
  value?: number;
  prevValue: number;
  year: number;
  month: number;
  signType?: string;
}) => {
  if (!props.name || props.value === undefined || props.prevValue === null) {
    return <>No data provided for the reporting month</>;
  }

  const calculateMomDiff = (
    monthDataValue: number,
    prevMonthDataValue?: number
  ) => {
    if (!prevMonthDataValue) return 0;
    return ((monthDataValue - prevMonthDataValue) / prevMonthDataValue) * 100;
  };

  const momValue = calculateMomDiff(props.value, props.prevValue);
  const sign = momValue > 0 ? '↑' : '↓';
  const date = (() => {
    if (props.year && props.month) {
      return DateTime.fromObject({
        year: props.year,
        month: props.month,
      }).toFormat('MMM-yy');
    }

    const now = DateTime.now();
    const lastClosedMonth = now.minus({ months: 1 });

    return lastClosedMonth.toFormat('MMM-yy');
  })();
  const positivValue = props.value < 0 ? props.value * -1 : props.value;
  return (
    <div className={'summary-card'}>
      <p>{props.name}</p>
      <p>
        {props.signType === 'value'
          ? reformatToThousandsWithoutSign(positivValue)
          : reformatToThousandsWithoutSign(positivValue) + '%'}
      </p>

      <div className="actual-state-card-footer">
        <span
          style={{ color: momValue < 0 ? 'red' : '#4caf50' }}
          className="actual-state-card-change positive"
        >
          {sign} MOM {momValue.toFixed(0)}%
        </span>
        <span
          style={{
            color: '#1D1B2080',
            fontSize: '10px',
            marginLeft: '14px',
          }}
        >
          for {date}
        </span>
      </div>
    </div>
  );
};
