import { BasePage } from '../../pages/base';
import { useManagerCharts } from './manager-charts.hook';
import { ChartComponent } from '../../role-based-components/chart/chart.component';
import Footer from '../../shared-components/footer/footer.component';
import { Container } from '../../shared-components/container';
import React from 'react';
import { Head } from '../../shared-components/head';
import { Button } from '../../shared-components/button';
import { ManagerChartModalComponent } from './manager-chart-modal.component';
import { ManagerChartDeleteModalComponent } from './manager-chart-delete.modal.component';

export const ManagerChartsPage: React.FC = () => {
  const [modalActive, setModalActive] = React.useState(false);
  const [chartToDelete, setChartToDelete] = React.useState<null | number>(null);
  const { charts, createChart, deleteChart } = useManagerCharts();

  const onChartDelete = async (chartId: number) => {
    setChartToDelete(chartId);
  };

  const addChart = async (chartName: string) => {
    await createChart({
      source: chartName,
    });
    setModalActive(false);
  };

  return (
    <BasePage>
      <ManagerChartDeleteModalComponent
        isOpen={chartToDelete !== null}
        onSubmit={async () => {
          await deleteChart(chartToDelete as number);
          setChartToDelete(null);
        }}
        onCancel={() => {
          setChartToDelete(null);
        }}
      />
      <ManagerChartModalComponent
        isOpen={modalActive}
        onSubmit={addChart}
        onCancel={() => {
          setModalActive(false);
        }}
      />
      <Container extended={true} maxWidth={true}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Head>Dashboard</Head>
          <Button
            active={true}
            mini={true}
            onClick={() => {
              setModalActive(true);
            }}
          >
            Add chart
          </Button>
        </div>
      </Container>

      {charts.map((chart, index) => (
        <ChartComponent
          key={index}
          source={chart.source}
          onDelete={() => onChartDelete(chart.id)}
        />
      ))}

      <Footer />
    </BasePage>
  );
};
