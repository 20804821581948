import { createContext } from 'react';

export interface HomeRedirectContextType {
  homeRedirectUrl: string;
  setHomeRedirectUrl: (url: string) => void;
}

export const HomeRedirectContext = createContext<HomeRedirectContextType>({
  homeRedirectUrl: 'https://fuelfinance.me/bootstrap/',
  setHomeRedirectUrl: (url: string) => {
    console.warn('setHomeRedirectUrl is not implemented');
  },
});
