import { BasePage } from '../base';
import { AllCommentsComponent } from '../../role-based-components/comments/comments.component';
import { useEffect } from 'react';
import { useClientComments } from '../../role-based-components/comments/client-comments.hook';

export const CommentsPage = () => {
  const { fetchComments, comments } = useClientComments();

  useEffect(() => {
    fetchComments(20);
  }, []);

  return (
    <BasePage>
      <AllCommentsComponent comments={comments} />
    </BasePage>
  );
};
