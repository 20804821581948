import React from 'react';

interface PaginationProps {
  page: number;
  totalCount: number;
  onPageChange: (page: number) => void;
  pageLimit?: number;
}

export const Pagination: React.FC<PaginationProps> = ({
  page,
  totalCount,
  onPageChange,
  pageLimit = 50,
}) => {
  const totalPages = Math.ceil(totalCount / pageLimit);

  return (
    <div className="pagination-controls">
      <button
        disabled={page <= 1}
        onClick={() => onPageChange(page - 1)}
        className="pagination-button"
      >
        Previous
      </button>
      <span className="pagination-tracker">{`Page ${page} of ${
        totalPages || 1
      }`}</span>
      <button
        disabled={page >= (totalPages || 1)}
        onClick={() => onPageChange(page + 1)}
        className="pagination-button"
      >
        Next
      </button>
    </div>
  );
};
