import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRootfiLink } from 'rootfi-react-sdk';
import { BasePage } from '../base';
import Footer from '../../shared-components/footer/footer.component';
import { Container } from '../../shared-components/container';
import { ManagerComponent } from '../../role-based-components/manager/manager.component';
import { IntegrationHttpService } from '../../tools/api-services/integrations.service';
import { ToasterContext } from '../../context/toaster.context';
import stripeIcon from '../../../../public/images/stripe.png';
import quickbooksIcon from '../../../../public/images/qb.png';
import hubspotIcon from '../../../../public/images/hubspot-icon.svg';
import gustoIcon from '../../../../public/images/gusto.svg';
import paylocityIcon from '../../../../public/images/paylocity.svg';
import paypalIcon from '../../../../public/images/paypal.png';
import salesforceIcon from '../../../../public/images/salesforce.png';
import './sources-connect.css';
import { useFinchConnect } from '@tryfinch/react-connect';
import {
  CrmIntegrations,
  PayrollIntegrations,
  Provider,
  ProvidersMap,
  SalesIntegrations,
} from '../../tools/api-services/types/connected-sources';

export const SourcesConnectPage = () => {
  const [inviteLinkId, setInviteLinkId] = useState('');
  const [cred, setCred] = useState('');
  const [isIntegrationLoading, setIsIntegrationLoading] = useState(false);
  const navigate = useNavigate();
  const { updateToaster } = useContext(ToasterContext);
  const [provider, setProvider] = useState('');
  const CLIENT_ID = import.meta.env.VITE_FINCH_CLIENT_ID;

  const getQuickbooksAuthUrl = async () => {
    try {
      setIsIntegrationLoading(true);
      const { data } = await IntegrationHttpService.getQbLink('hubspot');
      window.location = data.authUri as string & Location;
      setIsIntegrationLoading(false);
    } catch (error) {
      console.error('Failed to fetch Quickbooks URL', error);
      setIsIntegrationLoading(false);
    }
  };

  const providerIcons: Record<Provider, string> = {
    GUSTO: gustoIcon,
    PAYLOCITY: paylocityIcon,
    STRIPE: stripeIcon,
    PAYPAL: paypalIcon,
    HUBSPOT: hubspotIcon,
    SALES_FORCE: salesforceIcon,
  };

  const getSalesAuthUrl = async (provider: Provider) => {
    try {
      const { data } = await IntegrationHttpService.getSalesAuthLink(provider);
      return data.authUri;
    } catch (error) {
      console.error('Failed to fetch Stripe URL', error);
      return '';
    }
  };

  const getCrmAuthUrl = async (provider: Provider) => {
    try {
      const { data } = await IntegrationHttpService.getCrmAuthLink(provider);
      return data.authUri;
    } catch (error) {
      console.error('Failed to fetch Hubspot URL', error);
      return '';
    }
  };

  const handleCreatePayrollIntegration = useCallback(
    async (authCode: string, provider: string) => {
      try {
        await IntegrationHttpService.createPayrollIntegration(
          authCode,
          provider
        );
        setIsIntegrationLoading(false);
      } catch (error) {
        updateToaster({
          content: 'Payroll integration create failed',
          isError: true,
        });
        console.error(error);
        setIsIntegrationLoading(false);
      }
    },
    [setIsIntegrationLoading, updateToaster]
  );

  const { closeLink, openLink } = useRootfiLink({
    environment: 'global',
    inviteLinkId: inviteLinkId,
    onSuccess: (data: any) => {
      setTimeout(closeLink, 1000);
      setInviteLinkId('');
      setIsIntegrationLoading(false);
      updateToaster({
        content: 'Connection successful',
        isError: false,
      });
      navigate('/');
    },
    onExit: () => {
      closeLink();
      setInviteLinkId('');
      setIsIntegrationLoading(false);
      updateToaster({
        content: 'Connection aborted',
        isError: true,
      });
      navigate('/');
    },
  });

  const { open } = useFinchConnect({
    clientId: CLIENT_ID,
    products: [
      'company',
      'directory',
      'individual',
      'employment',
      'payment',
      'pay_statement',
    ],
    payrollProvider: provider,
    sandbox: false,

    onSuccess: ({ code }: any) => {
      setCred(code);
      setIsIntegrationLoading(false);
      updateToaster({
        content: 'Connection successful',
        isError: false,
      });
      navigate('/');
    },
    onError: () => {
      setCred('');
      setIsIntegrationLoading(false);
      updateToaster({
        content: 'Connection failed',
        isError: true,
      });
      navigate('/');
    },
    onClose: () => {
      setIsIntegrationLoading(false);
      updateToaster({
        content: 'Connection aborted',
        isError: false,
      });
      navigate('/');
    },
  });

  useEffect(() => {
    if (inviteLinkId) {
      openLink();
    }
  }, [inviteLinkId, openLink]);

  useEffect(() => {
    if (cred) {
      handleCreatePayrollIntegration(cred, provider);
      window.location.reload();
    }
  }, [provider, handleCreatePayrollIntegration, cred]);

  const handleSalesIntegrationConnect = async (provider: Provider) => {
    setIsIntegrationLoading(true);
    const authId = await getSalesAuthUrl(provider);
    if (authId) {
      setInviteLinkId(authId);
    } else {
      setIsIntegrationLoading(false);
    }
  };

  const handleCrmIntegrationConnect = async (provider: Provider) => {
    setIsIntegrationLoading(true);
    const authId = await getCrmAuthUrl(provider);
    if (authId) {
      setInviteLinkId(authId);
    } else {
      setIsIntegrationLoading(false);
    }
  };
  const handlePayrollConnect = useCallback(async () => {
    try {
      open();
    } catch (error) {
      console.error('Error fetching Payroll code:', error);
    }
  }, [open]);

  useEffect(() => {
    if (provider) {
      handlePayrollConnect();
    }
  }, [handlePayrollConnect, provider]);

  return (
    <>
      {isIntegrationLoading && (
        <>
          <div className="loader-overlay"></div>
          <div className="loader"></div>
        </>
      )}
      <BasePage>
        <ManagerComponent />
        <Container extended={true}>
          <div className="connect-sources">
            <div className="connect-sources-header">
              <span style={{ fontSize: '24px', lineHeight: '28px' }}>
                Connect Your Data
              </span>
              <button
                className={'connect-sources__skip-button'}
                onClick={() => navigate('/')}
              >
                skip this step
              </button>
            </div>
            <div className="connect-sources-items">
              {SalesIntegrations.map((salesIntegration) => (
                <React.Fragment key={salesIntegration}>
                  <div className="connect-sources__item">
                    <div className="data-source__name">
                      <img
                        src={providerIcons[salesIntegration]}
                        alt={`${
                          salesIntegration.charAt(0).toUpperCase() +
                          salesIntegration.slice(1)
                        } integration`}
                        style={{ width: '32px', height: '32px' }}
                      />
                      <span style={{ fontSize: '17px', lineHeight: '24px' }}>
                        {salesIntegration.charAt(0).toUpperCase() +
                          salesIntegration.slice(1)}
                      </span>
                    </div>
                    <button
                      className="connect-button"
                      onClick={() =>
                        handleSalesIntegrationConnect(salesIntegration)
                      }
                    >
                      Connect
                    </button>
                  </div>
                  <div className="divider-horizontal"></div>
                </React.Fragment>
              ))}
              <div className="connect-sources__item">
                <div className="data-source__name">
                  <img
                    src={quickbooksIcon}
                    alt="Quickbooks integration"
                    style={{ width: '32px', height: '32px' }}
                  />
                  <span style={{ fontSize: '17px', lineHeight: '24px' }}>
                    Quickbooks
                  </span>
                </div>
                <button
                  className="connect-button"
                  onClick={getQuickbooksAuthUrl}
                >
                  Connect
                </button>
              </div>
              {CrmIntegrations.map((crmIntegration) => (
                <React.Fragment key={crmIntegration}>
                  <div className="divider-horizontal"></div>
                  <div className="connect-sources__item">
                    <div className="data-source__name">
                      <img
                        src={providerIcons[crmIntegration]}
                        alt={`${ProvidersMap[crmIntegration]} integration`}
                        style={{ width: '32px', height: '32px' }}
                      />
                      <span style={{ fontSize: '17px', lineHeight: '24px' }}>
                        {ProvidersMap[crmIntegration]}
                      </span>
                    </div>
                    <button
                      className="connect-button"
                      onClick={() =>
                        handleCrmIntegrationConnect(crmIntegration)
                      }
                    >
                      Connect
                    </button>
                  </div>
                </React.Fragment>
              ))}
              {PayrollIntegrations.map((payrollIntegration) => (
                <React.Fragment key={payrollIntegration}>
                  <div className="divider-horizontal"></div>
                  <div className="connect-sources__item">
                    <div className="data-source__name">
                      <img
                        src={providerIcons[payrollIntegration]}
                        alt={`${
                          payrollIntegration.charAt(0).toUpperCase() +
                          payrollIntegration.slice(1)
                        } integration`}
                        style={{ width: '32px', height: '32px' }}
                      />
                      <span style={{ fontSize: '17px', lineHeight: '24px' }}>
                        {payrollIntegration.charAt(0).toUpperCase() +
                          payrollIntegration.slice(1)}
                      </span>
                    </div>
                    <button
                      className="connect-button"
                      onClick={() => setProvider(payrollIntegration)}
                    >
                      Connect
                    </button>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </Container>
        <Footer />
      </BasePage>
    </>
  );
};
