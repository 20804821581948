import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { DashboardHttpService } from '../../../tools/api-services/dashboardHttpService';
import { useContext, useEffect, useState } from 'react';
import { useStepper } from '../../bootstrap/hooks/stepper.hook';
import {
  trackVideoTutorialOpened,
  trackVideoWatch,
} from '../../../tools/analytics-events';
import { TutorialVideo } from '../../../tools/api-services/types/tutorial-video';
import { RoleContext } from '../../../context/role.context';

export const useVideoHook = (stepNumber: number) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userType } = useContext(RoleContext);
  if (
    userType &&
    !['BOOTSTRAP', 'UNKNOWN', 'BOOTSTRAP_NEXT'].includes(userType)
  )
    navigate('/');

  const { isQuickbooksConnected, businessModel } = useStepper();
  const [videoData, setVideoData] = useState<TutorialVideo>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const businessModelName = [2, 6].includes(stepNumber)
          ? 'All'
          : businessModel;
        const response = await DashboardHttpService.getTutorialVideo(
          stepNumber,
          businessModelName
        );

        if (response.data) {
          setVideoData(response.data);
        }
      } catch (error) {
        console.error('Error during the request:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideoData();
  }, [stepNumber, businessModel]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      setLoading(true);
      try {
        trackVideoTutorialOpened(stepNumber);
        trackVideoWatch(stepNumber);
        const urlParams = new URLSearchParams(location.search);

        if (stepNumber === 2) {
          if (userType === 'BOOTSTRAP_NEXT') {
            navigate('/tutorials/3');
          } else {
            navigate(`/signup?${urlParams.toString()}`);
          }
          return;
        }

        if (stepNumber === 6) {
          navigate('/');
          return;
        }

        await DashboardHttpService.completeOnboardingStep(stepNumber);

        if (stepNumber === 5) {
          if (isQuickbooksConnected) {
            navigate('/');
          } else {
            navigate('/quickbooks-connect');
          }
          return;
        }

        navigate('/');
      } catch (error) {
        console.error('Error during the request:', error);
      } finally {
        setLoading(false);
      }
    },
  });

  return {
    videoData: videoData,
    form: formik,
    loading,
  };
};
