import { BasePage } from '../base';
import { Container } from '../../shared-components/container';
import { Head } from '../../shared-components/head';
import { Button } from '../../shared-components/button';
import { useDevelopment } from './development.hook';
import './development.pange.css';
import Footer from '../../shared-components/footer/footer.component';

const DevPageControl = ({
  action,
  upperText,
  lowerText,
  buttonText,
  actionWarning,
}: any) => {
  return (
    <div className="dev-page-control-group">
      <div>
        <p>{upperText}</p>
        <p>{lowerText}</p>
      </div>
      <Button grey={actionWarning} active={true} mini={true} onClick={action}>
        {buttonText}
      </Button>
    </div>
  );
};

export const DevelopmentPage = () => {
  const { removeSelf, startImport, isProduction } = useDevelopment();
  const triggerImport = async () => {
    await startImport();
  };

  return (
    <BasePage>
      <Container extended={true}>
        <Head>Additional actions</Head>
        <DevPageControl
          action={triggerImport}
          upperText="Start imports and update data in reports"
          lowerText="It can take up to 5 min for update"
          buttonText="Start import"
        />
        {isProduction ? null : (
          <DevPageControl
            action={removeSelf}
            upperText="Permanently delete this company"
            lowerText="Don’t use it if you are not sure, better ask R&D team"
            buttonText="Delete company"
            actionWarning={true}
          />
        )}
      </Container>
      <Footer />
    </BasePage>
  );
};
