import { createContext } from 'react';

interface ToasterContextType {
  isVisible: boolean;
  content: string;
  isError: boolean;
  updateToaster: ({
    content,
    isError,
  }: {
    content: string;
    isError?: boolean;
  }) => void;
}

export const ToasterContext = createContext<ToasterContextType>({
  isVisible: false,
  content: '',
  isError: false,
  updateToaster: ({ content, isError = false }) => {
    console.warn('updateToaster is not implemented');
  },
});
