import { reformatToThousands } from '../../../../utils/dashboard/formatters';
import React from 'react';

export const BalanceSummary = (props: {
  name?: string;
  value?: number | string;
}) => {
  if (!props.name || props.value === undefined) {
    return <p>No data provided for the reporting month</p>;
  }

  const displayValue =
    typeof props.value === 'number'
      ? reformatToThousands(Math.abs(props.value))
      : props.value;

  return (
    <div className="summary-card">
      <p>{props.name}</p>
      <p>{displayValue}</p>
    </div>
  );
};
