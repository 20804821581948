import { DiagramStringValueFormatBuilder } from '../../../../utils/dashboard/formatters';
import { DateTime } from 'luxon';

export const ComparisonSummary = (props: {
  firstName: string;
  secondName: string;
  firstValue: number;
  secondValue: number;
  year: number;
  month: number;
}) => {
  if (
    !props.firstName ||
    !props.secondName ||
    props.firstValue === undefined ||
    props.secondValue === undefined
  ) {
    return <p>No data provided for the reporting month</p>;
  }

  const calculateDeviation = (firstValue: number, secondValue: number) => {
    if (secondValue === 0) return 0;
    return ((firstValue / secondValue - 1) * 100).toFixed(0);
  };

  const formatValue = (value: number) => {
    const positiveValue = Math.abs(value);
    return new DiagramStringValueFormatBuilder(positiveValue)
      .toThousands(1)
      .includeK()
      .includeDollarSign()
      .getValue();
  };

  const deviation = calculateDeviation(200000, 20000);

  const date =
    props.year && props.month
      ? DateTime.fromObject({ year: props.year, month: props.month }).toFormat(
          'MMM-yy'
        )
      : DateTime.now().minus({ months: 1 }).toFormat('MMM-yy');

  return (
    <div className="comparison-summary-card">
      <div className="comparison-summary-card-header">
        <div>
          <p>{props.firstName}</p>
          <p>{formatValue(props.secondValue)}</p>
        </div>
        <div>
          <p>{props.secondName}</p>
          <p>{formatValue(props.secondValue)}</p>
        </div>
        <div>
          <p>DEV, %</p>
          <p>{deviation}%</p>
        </div>
      </div>
      <div className="comparison-summary-card-footer">
        <span>for {date}</span>
      </div>
    </div>
  );
};
