import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../shared-components/button';
import { Container } from '../../../../shared-components/container';
import { useStepper } from '../../hooks/stepper.hook';
import { useContext } from 'react';
import { RoleContext } from '../../../../context/role.context';

export const DashboardHeader = () => {
  const navigate = useNavigate();
  const { currentStep, stepName } = useStepper();
  const { userType } = useContext(RoleContext);
  if (
    userType &&
    !['BOOTSTRAP', 'UNKNOWN', 'BOOTSTRAP_NEXT'].includes(userType)
  )
    return null;

  const handleClick = () => {
    navigate(`/tutorials/${currentStep + 1}`);
  };
  return (
    <Container extended={true}>
      <div className="document-header">
        <div className="step-indicator">{`${currentStep}/5`}</div>
        <div className="step-name">
          <p className="name">{stepName}</p>
        </div>
        <Button
          mini
          onClick={handleClick}
          active={true}
          children={'Next'}
          style={{
            width: '69px',
            height: '36px',
          }}
          type="submit"
        />
      </div>
    </Container>
  );
};
