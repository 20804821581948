import React, { useState } from 'react';
import './add-client.style.css';
import { SquareX } from 'lucide-react';
import { UpdatedButtonComponent } from '../../../../shared-components/button/updated.button.component';

interface AddClientModalProps {
  onClose: () => void;
  onAdd: (accountName: string, startDate?: string, endDate?: string) => void;
}

const AddClientModal: React.FC<AddClientModalProps> = ({ onClose, onAdd }) => {
  const [accountName, setAccountName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleAddClick = () => {
    if (!accountName.trim()) {
      setError('Account name is required');
      return;
    }

    onAdd(accountName, startDate, endDate);
    onClose(); // Close the modal after successful addition
  };

  return (
    <div className="modal-overlay">
      <div className="add-client-modal">
        <div className="add-client-modal__header">
          <h2>Add Client</h2>
          <SquareX
            className="close-cross-button"
            onClick={onClose}
            style={{ width: '45px', height: '45px' }}
            strokeWidth={'0.7'}
          />
        </div>
        <div className="add-client-modal__body">
          <div className="input-group">
            <input
              id="account-name"
              type="text"
              placeholder="Account name"
              value={accountName}
              onChange={(e) => {
                setAccountName(e.target.value);
                setError(null); // Clear error when user starts typing
              }}
              name="accountName"
            />
            {error && <span className="error-message">{error}</span>}
          </div>
          <div className="input-group">
            <input
              id="start-date"
              type="date"
              placeholder="Start date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              name="startDate"
            />
          </div>
          <div className="input-group">
            <input
              id="end-date"
              type="date"
              placeholder="End date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              name="endDate"
            />
          </div>
        </div>

        <UpdatedButtonComponent
          mini={true}
          type={'default'}
          onClick={handleAddClick}
        >
          Add
        </UpdatedButtonComponent>
      </div>
    </div>
  );
};

export default AddClientModal;
