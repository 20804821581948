import { ManagerCompaniesHttpService } from '../../../../tools/manager-api/http-companies';
import { SquareX } from 'lucide-react';
import './styles.css';
import { UpdatedButtonComponent } from '../../../../shared-components/button/updated.button.component';
import { useUser } from '../../../../context/user-details.context';
import { trackDeleteReportLink } from '../../../../tools/analytics-events';

export const DeleteReportModal = ({
  onClose,
  documentId,
  fetchDocuments,
  companyId,
}: {
  onClose: () => void;
  documentId: number | null;
  fetchDocuments: (companyId: number) => void;
  companyId: number | undefined;
}) => {
  const { userDetails } = useUser();
  const handleDeleteDocument = async () => {
    try {
      if (documentId && companyId) {
        await ManagerCompaniesHttpService.deleteCompanyDocument(
          companyId,
          documentId
        );
        fetchDocuments(companyId);
      }
      trackDeleteReportLink({
        companyName: userDetails.companyName,
        userName: userDetails.userName,
        userType: userDetails.userType,
      });
      onClose();
    } catch (error) {
      console.error(error);
      onClose();
    }
  };

  return (
    <div className="delete-document-modal">
      <div className="delete-document-modal__header">
        <h2>Report Deleting</h2>
        <SquareX
          className="close-cross-button"
          onClick={onClose}
          style={{ width: '40px', height: '40px' }}
          strokeWidth={'1'}
        />
      </div>
      <p>Are you sure you want to delete report?</p>
      <UpdatedButtonComponent type={'warning'} onClick={handleDeleteDocument}>
        Yes, delete
      </UpdatedButtonComponent>
    </div>
  );
};
