import { BasePage } from '../../pages/base';
import { useManagerComments } from '../../role-based-components/comments/manager-comments.hook';
import { AllCommentsComponent } from '../../role-based-components/comments/comments.component';
import { useEffect } from 'react';

export const ManagerCommentsPages = () => {
  const { fetchComments, comments } = useManagerComments();

  useEffect(() => {
    fetchComments(20);
  }, []);

  return (
    <BasePage>
      <AllCommentsComponent comments={comments} />
    </BasePage>
  );
};
