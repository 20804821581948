import './style.css';
import classNames from 'classnames';

interface CongratulationComponentProps {
  active?: boolean;
}

export const CongratulationComponent = ({
  active,
}: CongratulationComponentProps) => {
  const congratulationClasses = classNames({
    'congratulation-container': true,
    active: active,
  });

  return (
    <div className={congratulationClasses}>
      <div className="congrats-icon-container">
        <span className="congrats-icon" role="img" aria-label="congrats">
          🎊
        </span>
      </div>
      <div className="congrats-title-container">
        <p className="congrats-title">
          Congrats! Your reports have been filled automatically
        </p>
        <p className="congrats-subtitle">
          You also have 2 new reports and video explainers to guide you.
        </p>
      </div>
    </div>
  );
};
