import { BasePage } from '../base';
import { Container } from '../../shared-components/container';
import { Head } from '../../shared-components/head';
import { useResetPassword } from './hook';
import { Input } from '../../shared-components/input';
import { Button } from '../../shared-components/button';
import { useEffect } from 'react';

// todo make shared wrapper for login
export const ForgotPasswordPage = ({
  isComplete,
}: {
  isComplete?: boolean;
}) => {
  const { form } = useResetPassword();

  const isActive = form.values.email.length > 0;

  const handleSubmit = (event: any) => {
    event.preventDefault();
    form.submitForm();
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has('email')) {
      return;
    }

    const email = urlParams.get('email');

    if (isComplete) {
      form.setFieldValue('email', email);
    }
  }, []);

  return (
    <BasePage mini>
      <Container extended={false}>
        <form onSubmit={handleSubmit}>
          <div className="login">
            <a id="back" href="/login">
              Back
            </a>
            {isComplete && <Head>To complete registration</Head>}
            <Head>Type your work email.</Head>
            <Input
              handleChange={form.handleChange}
              value={form.values.email}
              placeholder={'Email'}
              controlName={'email'}
              type={'email'}
            />
            <Button style={{ marginTop: '20px' }} active={isActive}>
              Get password
            </Button>
          </div>
        </form>
      </Container>
      <div>
        <p style={{ textAlign: 'center' }}>
          Don't have an account?{' '}
          <a href="https://fuelfinance.me/bootstrap">Register</a>
        </p>
      </div>
    </BasePage>
  );
};
