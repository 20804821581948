import { SquareX } from 'lucide-react';
import { UpdatedButtonComponent } from '../../../../shared-components/button/updated.button.component';
import './styles.css';

export const DeleteDataSourceModal = ({
  onClose,
  sourceId,
  handleDelete,
}: {
  onClose: () => void;
  sourceId: number | null;
  handleDelete: ((sourceId: number) => void) | null;
}) => {
  const handleDeleteDocument = () => {
    try {
      if (sourceId && handleDelete) {
        handleDelete(sourceId);
      }
      onClose();
    } catch (error) {
      console.error(error);
      onClose();
    }
  };

  return (
    <div className="delete-source-modal">
      <div className="delete-source-modal__header">
        <h2>Data Source Deleting</h2>
        <SquareX
          className="close-cross-button"
          onClick={onClose}
          style={{ width: '40px', height: '40px' }}
          strokeWidth={'1'}
        />
      </div>
      <p>Are you sure you want to delete this data source?</p>
      <UpdatedButtonComponent type={'warning'} onClick={handleDeleteDocument}>
        Yes, delete
      </UpdatedButtonComponent>
    </div>
  );
};
