import { Container } from '../../shared-components/container';
import { BasePage } from '../base';
import { Head } from '../../shared-components/head';
import { Button } from '../../shared-components/button';
import { useContactUs } from './hooks/contact-us.hook';
import Footer from '../../shared-components/footer/footer.component';

export const ContactUsPage = () => {
  const { contactUsForm, isButtonActive, touched, errors } = useContactUs();

  return (
    <BasePage>
      <Container extended={true}>
        <Head>Contact us</Head>
        <form>
          <textarea
            className="bug-textarea"
            placeholder="Hey there! Describe your request"
            name="contactDescription"
            style={{ marginTop: '16px' }}
            onChange={contactUsForm.handleChange}
          ></textarea>
          {errors.contactDescription && touched.contactDescription && (
            <div className="form-error">{errors.contactDescription}</div>
          )}
        </form>
        <Button
          onClick={contactUsForm.submitForm}
          active={isButtonActive}
          mini={true}
          type={'submit'}
          style={{ width: 200, height: 40, alignSelf: 'flex-end' }}
        >
          Submit
        </Button>
      </Container>
      <Footer></Footer>
    </BasePage>
  );
};
