export const settings = {
  production: {
    portalId: '23433501',
    formId: 'b0f662c6-989b-406f-b0ce-249f317a9857',
  },
  development: {
    portalId: '43550426',
    formId: 'f1e469c5-de3d-44a4-98c3-2335f633d708',
  },
};

export const hubspotEnvironmentMap = new Map(Object.entries(settings));
