import { useFormik } from 'formik';
import { DashboardHttpService } from '../../../tools/api-services/dashboardHttpService';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ToasterContext } from '../../../context/toaster.context';

const schema = Yup.object().shape({
  contactDescription: Yup.string()
    .min(3, 'Description should be at least 3 symbols length')
    .required('Description is required'),
});

export const useContactUs = () => {
  const { updateToaster } = useContext(ToasterContext);
  const navigate = useNavigate();
  const sendContactUs = async (data: { contactDescription: string }) => {
    await DashboardHttpService.contactUs(data);
  };

  const contactUsForm = useFormik({
    initialValues: {
      contactDescription: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      await sendContactUs(values);
      updateToaster({
        content:
          'Thank you for your feedback. We will respond to your email within 48 hours.',
      });
      navigate('/');
    },
  });

  return {
    contactUsForm,
    isButtonActive: contactUsForm.isValid,
    errors: contactUsForm.errors,
    touched: contactUsForm.touched,
  };
};
