import { useContext } from 'react';
import { RoleContext } from '../../context/role.context';
import { Container } from '../../shared-components/container';
import { useClientManager } from '../../hooks/client/client-manager.hook';
import './manager.component.style.css';

export const ManagerComponent = () => {
  const { userType } = useContext(RoleContext);
  const { manager, failed, getSlackLink } = useClientManager();

  if (failed || !manager) {
    return null;
  }

  if (userType !== 'HUBSPOT') {
    return null;
  }

  return (
    <Container extended={true}>
      <div className="manager-content">
        <div className="manager-content-image">
          <img
            src={manager?.avatarLink || 'default-avatar.png'}
            alt="manager"
          />
        </div>
        <div className="manager-content-info">
          <div className="manager-greeting">
            <p>Hi {manager?.companyName || 'Company'}.</p>
            <p>I'm {manager?.name || 'Your manager'}, your CFO.</p>
          </div>
          <div className="manager-contact">
            <a href={`mailto:${manager?.email || ''}`}>
              {manager?.email || 'Email not available'}
            </a>
            <a target="_blank" href={getSlackLink()} rel="noreferrer">
              Slack
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};
