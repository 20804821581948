import { Login } from './pages/login/login.page';
import { useContext, useEffect } from 'react';
import { RoleContext, userType } from './context/role.context';
import { Navigate, Route, useNavigate, useParams } from 'react-router-dom';

import { BootstrapMainPage } from './pages/bootstrap/bootstrapMainPage';
import { ManagementHomePage } from './managers-pages/main/manager-home.page';
import { BusinessModelPage } from './pages/stepper/business-model/business-model.page';
import { TutorialVideo } from './pages/stepper/tutorial-video/tutorial-video.page';
import { DirectRegistrationPage } from './pages/direct-registration/direct-registration/registration.page';
import { EmailVerification } from './pages/direct-registration/email-verification/email-verification.page';
import { ForgotPasswordPage } from './pages/forgot';
import { TextPage } from './pages/dynamic-content';
import { CompleteResetPage } from './pages/complete-reset/complete-reset.page';
import { PaymentDetailsPage } from './pages/payment-details/payment-details.page';
import { TransactionsPage } from './pages/payment-details/transactions/transactions.page';
import { BugReportPage } from './pages/bug-contact/bug-report.page';
import { ReportIssuePage } from './pages/bug-contact/report-issue.page';
import { ContactUsPage } from './pages/bug-contact/contact-us.page';
import { ManagerCompanyPage } from './managers-pages/manager-company/manager-company.page';
import { PaymentSuccess } from './pages/payment-success/payment.success';
import { DevelopmentPage } from './pages/development/development.page';
import { OnboardingPage } from './pages/onboarding/onboarding.page';
import { SubscriptionCancelPage } from './pages/payment-details/subscription-cancel.page';
import { QuickbooksConnectPage } from './pages/quickbooks-connect/quickbooks-connect.page';

import { instanceWithToken } from './tools/api';
import { DataSourcePage } from './role-based-components/data-source/data-source.page';
import { ManagerChartsPage } from './managers-pages/charts/manager-charts.page';
import { ManagerCommentsPages } from './managers-pages/comments/manager-comments.pages';
import { CommentsPage } from './pages/comments/comments.page';
import { ManagerCompaniesHttpService } from './tools/manager-api/http-companies';
import { UserRole } from './tools/api-services/types/client';
import { SourcesConnectPage } from './pages/sources-connect/sources-connect.page';
import { FirebaseCallbackPage } from './pages/firebase-callback/firebase-callback.page';
import { CompleteNextRegistration } from './pages/complete-registration/complete-registration.page';
import { SidebarHoc } from './components/layout/sidebar/sidebar';
import { HeaderHoc } from './components/layout/header/header.component';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import classNames from 'classnames';
import { UpdatedFinancialReportPage } from './pages/financial-reports/financial-reports.page';
import { MappingPage } from './managers-pages/mapping/mapping.page';
import { NextAuth } from './pages/next-auth/next-auth.page';
import { MetricsPage } from './managers-pages/metrics/metrics.page';
import { FinancialGroupsPage } from './managers-pages/mapping/financial-groups/financial-groups.page';

enum RenderConditions {
  PRODUCTION_DISABLED,
}

const renderConditionsImplemetation = {
  [RenderConditions.PRODUCTION_DISABLED]: () =>
    import.meta.env.VITE_APP_ENVIRONMENT === 'production',
};

interface RouterRoute {
  path: string;
  component: JSX.Element | null;
  protected: boolean;
  managerRoleComponent?: JSX.Element | null;
  displayConditionally?: null | RenderConditions;
  key?: number;
  availableForUserTypes?: userType[];
}

export const unprotectedRoutes: RouterRoute[] = [
  {
    path: '/login',
    component: <Login />,
    protected: false,
    displayConditionally: null,
  },
  {
    path: '/login/next',
    component: <NextAuth />,
    protected: false,
    displayConditionally: null,
  },
  {
    path: '/business-model',
    component: <BusinessModelPage />,
    protected: false,
    displayConditionally: null,
  },
  {
    path: 'signup',
    component: <DirectRegistrationPage />,
    protected: false,
  },
  {
    path: 'verify-email',
    component: <EmailVerification />,
    protected: false,
  },
  {
    path: 'forgot-password',
    component: <ForgotPasswordPage />,
    protected: false,
  },
  {
    path: 'complete-registration',
    component: <ForgotPasswordPage isComplete={true} />,
    protected: false,
  },
  {
    path: 'reset-success',
    component: <TextPage text="Instructions were sent to your email" />,
    protected: false,
  },
  {
    path: 'complete-reset',
    component: <CompleteResetPage />,
    protected: false,
  },
  {
    path: 'report-bug',
    component: <BugReportPage />,
    protected: false,
  },
  {
    path: 'support/report-issue',
    component: <ReportIssuePage />,
    protected: false,
  },
  {
    path: 'complete-registration',
    component: <CompleteResetPage />,
    protected: false,
  },
  {
    path: 'firebase-callback',
    component: <FirebaseCallbackPage />,
    protected: false,
  },
];

export const protectedRoutes: RouterRoute[] = [
  {
    path: '*',
    component: <Navigate to="/" />,
    protected: true,
    managerRoleComponent: null,
  },
  {
    path: '/',
    component: <BootstrapMainPage />,
    protected: true,
    managerRoleComponent: <ManagementHomePage />,
    displayConditionally: null,
  },
  {
    path: 'tutorials/:stepNumber',
    component: <TutorialVideo />,
    protected: true,
  },
  {
    path: 'payment-details',
    component: <PaymentDetailsPage />,
    protected: true,
    managerRoleComponent: <Navigate to="/" />,
  },
  {
    path: 'payment-details/transactions',
    component: <TransactionsPage />,
    protected: true,
    managerRoleComponent: <Navigate to="/" />,
  },
  {
    path: '/payment/success',
    component: <PaymentSuccess content={'Your payment was successful'} />,
    protected: true,
  },
  {
    path: '/payment-method/success',
    component: (
      <PaymentSuccess content="You successfully changed payment method" />
    ),
    protected: true,
  },
  {
    path: 'additional-actions',
    component: <DevelopmentPage />,
    protected: true,
  },
  {
    path: 'onboarding/:stepNumber',
    component: <OnboardingPage />,
    protected: true,
  },
  {
    path: 'data-sources',
    component: <DataSourcePage />,
    protected: true,
    managerRoleComponent: <Navigate to={'/'} />,
  },
  {
    path: 'cancel-subscription',
    component: <SubscriptionCancelPage />,
    protected: true,
  },
  {
    path: 'quickbooks-connect',
    component: <QuickbooksConnectPage />,
    protected: true,
    managerRoleComponent: <Navigate to={'/'} />,
  },
  {
    path: 'connect-sources',
    component: <SourcesConnectPage />,
    protected: true,
    managerRoleComponent: <Navigate to={'/'} />,
  },
  {
    path: 'contact-us',
    component: <ContactUsPage />,
    protected: true,
    managerRoleComponent: null,
  },
  {
    path: 'dashboard',
    component: <DashboardPage />,
    protected: true,
    managerRoleComponent: <Navigate to={'/'} />,
    availableForUserTypes: [],
  },
  {
    path: 'comments',
    component: <CommentsPage />,
    protected: true,
    managerRoleComponent: <Navigate to={'/'} />,
  },
  {
    path: 'complete-registration/next',
    component: <CompleteNextRegistration />,
    protected: true,
    managerRoleComponent: <Navigate to={'/'} />,
  },
  {
    path: 'financial-reports',
    component: <UpdatedFinancialReportPage />,
    protected: true,
    managerRoleComponent: <Navigate to={'/'} />,
  },
];

const managerRoutes: RouterRoute[] = [
  {
    path: 'manager/home',
    component: <Navigate to="/" />,
    protected: true,
    managerRoleComponent: <ManagementHomePage />,
  },
  {
    path: 'manager/companies/:companyId',
    managerRoleComponent: <ManagerCompanyPage />,
    protected: true,
    component: <Navigate to="/" />,
  },
  {
    path: 'manager/companies/:companyId/charts',
    managerRoleComponent: <ManagerChartsPage />,
    protected: true,
    component: <Navigate to="/" />,
  },
  {
    path: 'manager/companies/:companyId/data-sources',
    managerRoleComponent: <DataSourcePage />,
    protected: true,
    component: <Navigate to="/" />,
  },
  {
    path: 'manager/companies/:companyId/comments',
    managerRoleComponent: <ManagerCommentsPages />,
    protected: true,
    component: <Navigate to="/" />,
  },
  {
    path: 'manager/companies/:companyId/dashboard',
    managerRoleComponent: <DashboardPage />,
    protected: true,
    component: <Navigate to="/" />,
  },
  {
    path: 'manager/companies/:companyId/financial-reports',
    managerRoleComponent: <UpdatedFinancialReportPage />,
    protected: true,
    component: <Navigate to="/" />,
  },
  {
    path: 'manager/companies/:companyId/mapping',
    managerRoleComponent: <MappingPage />,
    protected: true,
    component: <Navigate to="/" />,
  },

  {
    path: 'manager/companies/:companyId/mapping/fin-groups',
    managerRoleComponent: <FinancialGroupsPage />,
    protected: true,
    component: <Navigate to="/" />,
  },

  {
    path: 'manager/companies/:companyId/metrics',
    managerRoleComponent: <MetricsPage />,
    protected: true,
    component: <Navigate to="/" />,
  },
];

const ProtectedRoute = ({
  children,
  managerPage,
}: {
  children: JSX.Element;
  managerPage?: JSX.Element;
}) => {
  const {
    role,
    setRole,
    setRoleType,
    setAccessibleResources,
    accessibleResources,
  } = useContext(RoleContext);
  const { companyId } = useParams();

  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!role) {
      const checkAuthAndRole = async () => {
        try {
          const result = await instanceWithToken.get<{
            role: UserRole;
            isAuthenticated: boolean;
            type: userType;
          }>('api/auth/is-authenticated');

          if (result.data.role) {
            setRole(result.data.role);
            setRoleType(result.data.type);
          }

          if (result.data.role === 'CLIENT') {
            const { data: accessibleResources } = await instanceWithToken.get(
              '/api/me/accessible_resources'
            );

            if (accessibleResources.dashboard) {
              setAccessibleResources(['DASHBOARD']);
            }
          }

          if (result.data.role === 'MANAGER') {
            if (companyId) {
              const result =
                await ManagerCompaniesHttpService.isManagerAssignedToCompany(
                  Number(companyId as string)
                );

              if (result.data.data === null) {
                navigate('/');
              }
            }
          }
        } catch (e) {
          localStorage.removeItem('token');
          localStorage.removeItem('userDetails');
          navigate('/login');
        }
      };
      checkAuthAndRole();
    }
  }, []);

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (!role) {
    return null;
  }
  const appContainerClasses = classNames('app-container', {});

  if (role === 'MANAGER' && managerPage) {
    return (
      <>
        <SidebarHoc />
        <div className={appContainerClasses}>
          <HeaderHoc />
          {managerPage}
        </div>
      </>
    );
  }

  return (
    <>
      <SidebarHoc />
      <div className={appContainerClasses}>
        <HeaderHoc />
        {children}
      </div>
    </>
  );
};

const Router = (routes: RouterRoute[]) => {
  return routes.map((route, index) => {
    if (route.protected && route.component && route.managerRoleComponent) {
      return (
        <Route
          key={index}
          path={route.path}
          element={
            <ProtectedRoute
              key={index}
              managerPage={route.managerRoleComponent}
            >
              {route.component}
            </ProtectedRoute>
          }
        />
      );
    }

    return (
      <Route
        key={index}
        path={route.path}
        element={<div className={'app-container'}>{route.component}</div>}
      />
    );
  });
};

export const AppRouter = () =>
  Router([...unprotectedRoutes, ...protectedRoutes, ...managerRoutes]);
