export const sidebarSvg = {
  DASHBOARD: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33 2.66667V4.66667C1.33 5.40489 1.92845 6.00333 2.66667 6.00333H4.66667C5.40489 6.00333 6.00333 5.40489 6.00333 4.66666V2.66667C6.00333 1.92845 5.40489 1.33 4.66667 1.33L2.66667 1.33C1.92845 1.33 1.33 1.92845 1.33 2.66667ZM9.33 11.4268C9.81871 11.9826 10.5351 12.3333 11.3333 12.3333H13.3333C14.8061 12.3333 16 11.1394 16 9.66666V2.66667C16 1.19391 14.8061 0 13.3333 0H11.3333C9.86057 0 8.66667 1.19391 8.66667 2.66667V9.66667C8.66667 10.2973 8.88557 10.8768 9.25154 11.3333C9.27699 11.3651 9.30315 11.3962 9.33 11.4268ZM7.33667 9.57323C6.84796 9.01743 6.13161 8.66667 5.33333 8.66667H2.66667C1.19391 8.66667 0 9.86057 0 11.3333V13.3333C0 14.8061 1.19391 16 2.66667 16H5.33333C6.80609 16 8 14.8061 8 13.3333V11.3333C8 10.7027 7.78109 10.1232 7.41512 9.66667C7.38967 9.63492 7.36351 9.60377 7.33667 9.57323ZM9.99667 2.66667V9.66667C9.99667 10.4049 10.5951 11.0033 11.3333 11.0033H13.3333C14.0716 11.0033 14.67 10.4049 14.67 9.66666V2.66667C14.67 1.92845 14.0716 1.33 13.3333 1.33L11.3333 1.33C10.5951 1.33 9.99667 1.92845 9.99667 2.66667ZM5.33333 9.99667H2.66667C1.92845 9.99667 1.33 10.5951 1.33 11.3333V13.3333C1.33 14.0716 1.92845 14.67 2.66667 14.67H5.33333C6.07155 14.67 6.67 14.0716 6.67 13.3333V11.3333C6.67 10.5951 6.07155 9.99667 5.33333 9.99667ZM2.66667 7.33333C1.19391 7.33333 0 6.13943 0 4.66667V2.66667C0 1.19391 1.19391 0 2.66667 0H4.66667C6.13942 0 7.33333 1.19391 7.33333 2.66667V4.66666C7.33333 6.13942 6.13943 7.33333 4.66667 7.33333H2.66667Z"
        fill="white"
      />
    </svg>
  ),
  FIN_REPORTS: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_186_139)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1.33333H4C2.52724 1.33333 1.33333 2.52724 1.33333 4V12C1.33333 13.4728 2.52724 14.6667 4 14.6667H12C13.4728 14.6667 14.6667 13.4728 14.6667 12V4C14.6667 2.52724 13.4728 1.33333 12 1.33333ZM4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4Z"
          fill="#F3F3F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.66667 2V0.666667H6V2C6 2.36819 6.29848 2.66667 6.66667 2.66667H9.33333C9.70152 2.66667 10 2.36819 10 2V0.666667H11.3333V2C11.3333 3.10457 10.4379 4 9.33333 4H6.66667C5.5621 4 4.66667 3.10457 4.66667 2Z"
          fill="#F3F3F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4 7.33336C11.7682 7.33336 12.0667 7.63184 12.0667 8.00003V11.6C12.0667 11.9682 11.7682 12.2667 11.4 12.2667C11.0318 12.2667 10.7334 11.9682 10.7334 11.6V9.6096L7.77141 12.5715C7.51107 12.8319 7.08897 12.8319 6.82862 12.5715L5.60001 11.343L2.47141 14.4715C2.21106 14.7319 1.78895 14.7319 1.5286 14.4715C1.26825 14.2111 1.26826 13.789 1.52861 13.5287L5.12861 9.92877C5.38896 9.66843 5.81106 9.66843 6.07141 9.92877L7.3 11.1573L9.79059 8.66674H7.8C7.43181 8.66674 7.13333 8.36826 7.13333 8.00007C7.13333 7.63188 7.43181 7.33341 7.8 7.33341H11.3919C11.3946 7.33338 11.3973 7.33336 11.4 7.33336Z"
          fill="#F3F3F3"
        />
      </g>
      <defs>
        <clipPath id="clip0_186_139">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  TUTORIALS: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6667 8C16.0349 8 16.3334 8.29848 16.3334 8.66667V12.6667C16.3334 13.0349 16.0349 13.3333 15.6667 13.3333C15.2985 13.3333 15 13.0349 15 12.6667V8.66667C15 8.29848 15.2985 8 15.6667 8Z"
        fill="#F3F3F3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66669 8C4.03488 8 4.33336 8.29848 4.33336 8.66667V12.6667C4.33336 13.0349 4.03488 13.3333 3.66669 13.3333C3.2985 13.3333 3.00003 13.0349 3.00003 12.6667V8.66667C3.00003 8.29848 3.2985 8 3.66669 8Z"
        fill="#F3F3F3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.07041 12.3685C3.23507 12.0392 3.63552 11.9057 3.96484 12.0704L6.38713 13.2815C8.45166 14.3138 10.8817 14.3138 12.9463 13.2815L15.3686 12.0704C15.6979 11.9057 16.0983 12.0392 16.263 12.3685C16.4276 12.6978 16.2942 13.0983 15.9648 13.263L13.5425 14.4741C11.1026 15.694 8.23075 15.694 5.79084 14.4741L3.36855 13.263C3.03923 13.0983 2.90575 12.6978 3.07041 12.3685Z"
        fill="#F3F3F3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 9C13.3682 9 13.6667 9.29848 13.6667 9.66667V17C13.6667 17.3682 13.3682 17.6667 13 17.6667C12.6318 17.6667 12.3334 17.3682 12.3334 17V9.66667C12.3334 9.29848 12.6318 9 13 9Z"
        fill="#F3F3F3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29117 3.44116C9.57294 3.34647 9.87827 3.34866 10.1587 3.44736L17.5533 6.05063C18.7132 6.45898 18.7491 8.08627 17.6082 8.54527L10.2129 11.5207C9.90037 11.6465 9.55177 11.6492 9.23724 11.5284L1.49039 8.55323C0.323833 8.10521 0.359162 6.44271 1.5437 6.04466L9.29117 3.44116ZM9.71589 4.70504L1.96842 7.30854L9.71526 10.2837L17.1106 7.3083L9.71589 4.70504Z"
        fill="#F3F3F3"
      />
    </svg>
  ),
  DATA_SOURCES: (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_186_120)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.33331 2.66667C2.7015 2.66667 2.99998 2.36819 2.99998 2C2.99998 1.63181 2.7015 1.33333 2.33331 1.33333C1.96512 1.33333 1.66665 1.63181 1.66665 2C1.66665 2.36819 1.96512 2.66667 2.33331 2.66667ZM2.33331 4C3.43788 4 4.33331 3.10457 4.33331 2C4.33331 0.895431 3.43788 0 2.33331 0C1.22874 0 0.333313 0.895431 0.333313 2C0.333313 3.10457 1.22874 4 2.33331 4Z"
          fill="#F3F3F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.33333 8.66667C8.70152 8.66667 9 8.36819 9 8C9 7.63181 8.70152 7.33333 8.33333 7.33333C7.96514 7.33333 7.66667 7.63181 7.66667 8C7.66667 8.36819 7.96514 8.66667 8.33333 8.66667ZM8.33333 10C9.4379 10 10.3333 9.10457 10.3333 8C10.3333 6.89543 9.4379 6 8.33333 6C7.22876 6 6.33333 6.89543 6.33333 8C6.33333 9.10457 7.22876 10 8.33333 10Z"
          fill="#F3F3F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3333 14.6667C14.7015 14.6667 15 14.3682 15 14C15 13.6318 14.7015 13.3333 14.3333 13.3333C13.9651 13.3333 13.6667 13.6318 13.6667 14C13.6667 14.3682 13.9651 14.6667 14.3333 14.6667ZM14.3333 16C15.4379 16 16.3333 15.1046 16.3333 14C16.3333 12.8954 15.4379 12 14.3333 12C13.2288 12 12.3333 12.8954 12.3333 14C12.3333 15.1046 13.2288 16 14.3333 16Z"
          fill="#F3F3F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.99998 2C2.99998 1.63181 3.29848 1.33333 3.66667 1.33333H12.6667C14.6917 1.33333 16.3333 2.97496 16.3333 5C16.3333 7.02504 14.6917 8.66667 12.6667 8.66667H9.66667C9.29848 8.66667 9 8.36819 9 8C9 7.63181 9.29848 7.33333 9.66667 7.33333H12.6667C13.9553 7.33333 15 6.28866 15 5C15 3.71134 13.9553 2.66667 12.6667 2.66667H3.66667C3.29848 2.66667 2.99998 2.36819 2.99998 2Z"
          fill="#F3F3F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.66667 8C7.66667 7.63181 7.36819 7.33333 7 7.33333H4C1.97496 7.33333 0.333333 8.97496 0.333333 11C0.333333 13.025 1.97496 14.6667 4 14.6667H13C13.3682 14.6667 13.6667 14.3682 13.6667 14C13.6667 13.6318 13.3682 13.3333 13 13.3333H4C2.71134 13.3333 1.66667 12.2887 1.66667 11C1.66667 9.71134 2.71134 8.66667 4 8.66667H7C7.36819 8.66667 7.66667 8.36819 7.66667 8Z"
          fill="#F3F3F3"
        />
      </g>
      <defs>
        <clipPath id="clip0_186_120">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.333313)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  MAPPING: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_186_126)">
        <path
          d="M0.666657 2.31396V1.76463C0.666303 1.47377 0.781506 1.19469 0.98692 0.988775C1.19234 0.78286 1.47114 0.666979 1.76199 0.666626H5.26199C5.55284 0.666979 5.83165 0.78286 6.03706 0.988775C6.24248 1.19469 6.35768 1.47377 6.35732 1.76463"
          stroke="#F3F3F3"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99979 11.2192C7.99955 9.68377 9.08398 8.362 10.5899 8.06225C12.0957 7.76249 13.6036 8.56823 14.1914 9.9867C14.7792 11.4052 14.2831 13.0413 13.0065 13.8945C11.73 14.7477 10.0285 14.5803 8.94267 13.4947C8.33903 12.8913 7.99986 12.0727 7.99979 11.2192Z"
          stroke="#F3F3F3"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4942 13.4952L15.3334 15.3344"
          stroke="#F3F3F3"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666667 1.33337C1.03486 1.33337 1.33333 1.63185 1.33333 2.00004V8.33337C1.33333 9.43794 2.22876 10.3334 3.33333 10.3334H5.66667C6.03486 10.3334 6.33333 10.6319 6.33333 11C6.33333 11.3682 6.03486 11.6667 5.66667 11.6667H3.33333C1.49238 11.6667 0 10.1743 0 8.33337V2.00004C0 1.63185 0.298477 1.33337 0.666667 1.33337Z"
          fill="#F3F3F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666656 2.66667C0.666656 2.29848 0.965133 2 1.33332 2H10.6667C12.5076 2 14 3.49238 14 5.33333V6.66667C14 7.03486 13.7015 7.33333 13.3333 7.33333C12.9651 7.33333 12.6667 7.03486 12.6667 6.66667V5.33333C12.6667 4.22876 11.7712 3.33333 10.6667 3.33333H1.33332C0.965133 3.33333 0.666656 3.03486 0.666656 2.66667Z"
          fill="#F3F3F3"
        />
      </g>
      <defs>
        <clipPath id="clip0_186_126">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
};

export const COLLAPSED_LOGO = (
  <svg
    className={'collapsed-logo'}
    width="60"
    height="8"
    viewBox="0 0 60 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1091 1.52588e-05V5.0611C15.1091 6.58936 16.4368 7.93658 17.9861 7.93658C19.1145 8.01 21.0783 7.99948 22.674 7.99948C24.511 7.99948 25.8573 7.93658 27.1004 7.93658C29.0071 7.93658 30.14 6.5225 30.14 5.0611V3.31602e-05H23.0868V3.98343C23.0868 5.85541 23.1315 5.85541 22.674 5.85541C22.2165 5.85541 22.2641 5.85541 22.2641 3.98343V3.31602e-05L15.1091 1.52588e-05Z"
      fill="#F3F3F3"
    />
    <path
      d="M14.4003 2.29926V0H0V7.99998H7.25303V5.12675H14.4003V2.88383H7.25303V2.29926H14.4003Z"
      fill="#F3F3F3"
    />
    <path
      d="M37.9556 5.75706V5.16123H45.1201V2.77115H37.9556V2.24292H45.1201V1.66931e-06H30.7199V7.99998H45.1201V5.75706H37.9556Z"
      fill="#F3F3F3"
    />
    <path
      d="M45.7357 7.99998H60V5.79154L52.8517 5.79154V8.34657e-07L45.7357 1.66931e-06V7.99998Z"
      fill="#F3F3F3"
    />
  </svg>
);
export const EXPANDED_LOGO = (
  <svg
    className={'expanded-logo'}
    width="216"
    height="40"
    viewBox="0 0 216 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.2182 12V22.1222C30.2182 25.1787 32.8735 27.8732 35.9722 27.8732C38.2289 28.02 42.1566 27.999 45.348 27.999C49.0219 27.999 51.7146 27.8732 54.2009 27.8732C58.0142 27.8732 60.28 25.045 60.28 22.1222V12.0001H46.1737V19.9669C46.1737 23.7108 46.263 23.7108 45.348 23.7108C44.4329 23.7108 44.5281 23.7108 44.5281 19.9669V12.0001L30.2182 12Z"
      fill="#F3F3F3"
    />
    <path
      d="M28.8005 16.5985V12H0V28H14.5061V22.2535H28.8005V17.7677H14.5061V16.5985H28.8005Z"
      fill="#F3F3F3"
    />
    <path
      d="M75.9113 23.5141V22.3225H90.2403V17.5423H75.9113V16.4858H90.2403V12H61.4398V28H90.2403V23.5141H75.9113Z"
      fill="#F3F3F3"
    />
    <path
      d="M91.4713 28H120V23.5831L105.703 23.5831V12L91.4713 12V28Z"
      fill="#F3F3F3"
    />
  </svg>
);

export const SIDEBAR_HIDE = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 13V14C10 15.1046 9.10457 16 8 16H4C2.89543 16 2 15.1046 2 14V4C2 2.89543 2.89543 2 4 2H8C9.10457 2 10 2.89543 10 4V5"
      stroke="#F3F3F3"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.52977 9.47023C4.27007 9.21053 4.27007 8.78947 4.52977 8.52977L6.7618 6.29774C7.0215 6.03804 7.44255 6.03804 7.70225 6.29774C7.96195 6.55744 7.96195 6.97849 7.70225 7.23819L6.60544 8.335H12C12.3673 8.335 12.665 8.63273 12.665 9C12.665 9.36727 12.3673 9.665 12 9.665H6.60544L7.70225 10.7618C7.96195 11.0215 7.96195 11.4426 7.70225 11.7023C7.44255 11.962 7.0215 11.962 6.7618 11.7023L4.52977 9.47023Z"
      fill="white"
    />
  </svg>
);
export const SIDEBAR_EXPAND = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2H4C2.89713 2 2 2.89713 2 4V8C2 9.10287 2.89713 10 4 10H8C9.10287 10 10 9.10287 10 8V4C10 2.89713 9.10287 2 8 2ZM8.66667 8C8.66667 8.36753 8.36753 8.66667 8 8.66667H4C3.63247 8.66667 3.33333 8.36753 3.33333 8V4C3.33333 3.63247 3.63247 3.33333 4 3.33333H8C8.36753 3.33333 8.66667 3.63247 8.66667 4V8ZM8 10.6667H4C2.89713 10.6667 2 11.5638 2 12.6667V16C2 17.1029 2.89713 18 4 18H8C9.10287 18 10 17.1029 10 16V12.6667C10 11.5638 9.10287 10.6667 8 10.6667ZM8.66667 16C8.66667 16.3675 8.36753 16.6667 8 16.6667H4C3.63247 16.6667 3.33333 16.3675 3.33333 16V12.6667C3.33333 12.2991 3.63247 12 4 12H8C8.36753 12 8.66667 12.2991 8.66667 12.6667V16ZM16 4H12.6667C11.5638 4 10.6667 4.89713 10.6667 6V13.3333C10.6667 14.4362 11.5638 15.3333 12.6667 15.3333H16C17.1029 15.3333 18 14.4362 18 13.3333V6C18 4.89713 17.1029 4 16 4ZM16.6667 13.3333C16.6667 13.7009 16.3675 14 16 14H12.6667C12.2991 14 12 13.7009 12 13.3333V6C12 5.63247 12.2991 5.33333 12.6667 5.33333H16C16.3675 5.33333 16.6667 5.63247 16.6667 6V13.3333Z"
      fill="white"
    />
  </svg>
);
