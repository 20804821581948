import { useState } from 'react';
import { CompanyDocument } from '../../../../tools/api-services/types/document';
import { ManagerCompaniesHttpService } from '../../../../tools/manager-api/http-companies';
export const useCompanyDocuments = () => {
  const [documents, setDocuments] = useState<CompanyDocument[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchDocuments = async (companyId: number) => {
    try {
      const res = await ManagerCompaniesHttpService.companyDocuments(companyId);
      setDocuments(res.data.data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    documents,
    isLoading,
    fetchDocuments,
  };
};
