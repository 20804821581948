import { HeaderError } from '../../../shared-components/header-error';
import { BasePage } from '../../base';
import { Container } from '../../../shared-components/container';
import { Head } from '../../../shared-components/head';
import { Button } from '../../../shared-components/button';
import { Input } from '../../../shared-components/input';
import './style.css';
import { useDirectRegistrationHook } from './registration.hook';
import { useState } from 'react';
import { isNotEmpty } from '../../../tools/utils';
import Footer from '../../../shared-components/footer/footer.component';

export const DirectRegistrationPage = () => {
  const { errorState, form } = useDirectRegistrationHook();
  const { email, password, confirmPassword, firstName, lastName, companyName } =
    form.values;

  const isActive =
    isNotEmpty(email) &&
    isNotEmpty(password) &&
    isNotEmpty(confirmPassword) &&
    isNotEmpty(firstName) &&
    !form.errors.password &&
    !form.errors.confirmPassword;
  const [isPasswordInputFocused, setPasswordInputFocused] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    form.submitForm();
  };

  return (
    <>
      <BasePage mini>
        {Boolean(errorState.error.length) && (
          <HeaderError message={errorState.error} />
        )}
        <Container extended={false}>
          <Head>Registration</Head>
          <form onSubmit={handleSubmit}>
            <div className="registration">
              <div className="registration-name">
                <Input
                  controlName={'firstName'}
                  type={'text'}
                  handleChange={form.handleChange}
                  value={firstName}
                  placeholder={'First name'}
                />
                <Input
                  controlName={'lastName'}
                  type={'text'}
                  handleChange={form.handleChange}
                  value={lastName}
                  placeholder={'Last name'}
                />
              </div>
              {form.errors.firstName && form.touched.firstName && (
                <div className="form-error">{form.errors.firstName}</div>
              )}
              <Input
                controlName={'companyName'}
                type={'text'}
                handleChange={form.handleChange}
                value={companyName}
                placeholder={'Company name'}
              />
              <Input
                controlName={'email'}
                type={'email'}
                handleChange={form.handleChange}
                value={email}
                onBlur={form.handleBlur}
                placeholder={'Email'}
                error={form.errors.email && form.touched.email}
              />
              {form.errors.email && form.touched.email && (
                <div className="form-error">{form.errors.email}</div>
              )}
              <div className="password-section">
                <div className="password-inputs">
                  <Input
                    controlName={'password'}
                    type={'password'}
                    handleChange={form.handleChange}
                    value={password}
                    placeholder={'Password'}
                    onFocus={() => setPasswordInputFocused(true)}
                    onBlur={() => setPasswordInputFocused(false)}
                    error={
                      (form.errors.password && password.length) ||
                      (confirmPassword !== password && confirmPassword.length)
                    }
                    showPasswordToggle={true}
                  />
                  {((form.errors.password && password.length) ||
                    confirmPassword !== password) && (
                    <div className="form-error">{form.errors.password}</div>
                  )}
                  <Input
                    controlName={'confirmPassword'}
                    type={'password'}
                    handleChange={form.handleChange}
                    value={confirmPassword}
                    placeholder={'Confirm password'}
                    error={
                      (form.errors.confirmPassword && confirmPassword.length) ||
                      (confirmPassword !== password && confirmPassword.length)
                    }
                  />
                  {((form.errors.confirmPassword && confirmPassword.length) ||
                    confirmPassword !== password) && (
                    <div className="form-error">
                      {form.errors.confirmPassword}
                    </div>
                  )}
                </div>
                {isPasswordInputFocused &&
                  (!password || form.errors.password) && (
                    <div className="password-requirements">
                      <ul>
                        <li>At least 12 characters long</li>
                        <li>
                          At least one of each: uppercase letter, lowercase
                          letter, number, and a special symbols (!@#$%^&*)
                        </li>
                        <li>
                          Tip: it's best not to use a word that can be found in
                          a dictionary or the name of a person, character,
                          product, or organization
                        </li>
                      </ul>
                    </div>
                  )}
              </div>
              <Button active={isActive} style={{ marginTop: '32px' }}>
                Sign Up
              </Button>
            </div>
            <p className="privacy-text">
              By clicking the Sign Up button, you agree to Fuel's Terms of
              Service and Privacy Policy.
            </p>
          </form>
        </Container>
        <div>
          <p style={{ textAlign: 'center' }}>
            Already use Fuel? <a href="/login">Login</a>
          </p>
        </div>
      </BasePage>
      <Footer preLogin={true}></Footer>
    </>
  );
};
