import { ManagerHttpDataSourceService } from '../../../../tools/manager-api/manager-http-datasource';
import { IntegrationHttpService } from '../../../../tools/api-services/integrations.service';
import './styles.css';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../../context/user-details.context';
import { trackAddIntegrationQB } from '../../../../tools/analytics-events';

export const AccountingSystemsSource = () => {
  const { companyId } = useParams();
  const { userDetails } = useUser();
  const getQuickbooksAuthUrl = async () => {
    const role = localStorage.getItem('role');
    trackAddIntegrationQB({
      companyName: userDetails.companyName,
      userName: userDetails.userName,
      userType: userDetails.userType,
    });
    if (role === 'MANAGER') {
      const { data } = await ManagerHttpDataSourceService.getQuickbooksAuthUrl(
        Number(companyId)
      );
      window.location = data.authUri as string & Location;
      return;
    }

    if (role === 'CLIENT') {
      const { data } = await IntegrationHttpService.getQbLink('hubspot');
      window.location = data.authUri as string & Location;
      return;
    }
  };
  return (
    <button
      key={'quickbooks'}
      onClick={() => getQuickbooksAuthUrl()}
      className="accounting-systems__quickbooks"
    >
      Quickbooks
    </button>
  );
};
