import React, { useState } from 'react';
import './update-account-group.style.css';
import { SquareX } from 'lucide-react';
import { UpdatedButtonComponent } from '../../../../shared-components/button/updated.button.component';

interface UpdateAccountGroupModalProps {
  onClose: () => void;
  onSave: (id: number, groupName: string, type: number) => void;
  group: {
    id: number;
    groupName: string;
    type: { id: number; name: string };
  };
  accountTypeOptions: { id: number; name: string }[];
}

const UpdateAccountGroupModal: React.FC<UpdateAccountGroupModalProps> = ({
  onClose,
  onSave,
  group,
  accountTypeOptions,
}) => {
  const [groupName, setGroupName] = useState(group?.groupName || '');
  const [accountType, setAccountType] = useState(group?.type?.id);
  const [emptyNameError, setEmptyNameError] = useState<string | null>(null);
  const [emptyTypeError, setEmptyTypeError] = useState<string | null>(null);

  const handleSaveClick = () => {
    if (!groupName.trim()) {
      setEmptyNameError('Account name is required');
      return;
    }

    if (!accountType) {
      setEmptyTypeError('Type is required');
      return;
    }

    if (group) {
      onSave(group.id, groupName, accountType);
      onClose();
    }
  };

  return (
    <div className="modal-overlay">
      <div className="update-account-group-modal">
        <div className="update-account-group-modal__header">
          <h2>Update Group</h2>
          <SquareX
            className="close-cross-button"
            onClick={onClose}
            style={{ width: '45px', height: '45px' }}
            strokeWidth={'0.7'}
          />
        </div>
        <div className="update-account-group-modal__body">
          <div className="input-group">
            <input
              id="group-name"
              type="text"
              placeholder="Group name"
              value={groupName}
              onChange={(e) => {
                setGroupName(e.target.value);
                setEmptyNameError(null);
              }}
              name="groupName"
            />
            {emptyNameError && (
              <span className="error-message">{emptyNameError}</span>
            )}
          </div>
          <div>
            <select
              id="account-type"
              onChange={(e) => {
                setAccountType(Number(e.target.value));
                setEmptyTypeError(null);
              }}
              name="accountGroup"
              className="select-input"
              value={accountType}
            >
              {accountTypeOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {emptyTypeError && (
              <span className="error-message">{emptyTypeError}</span>
            )}
          </div>
        </div>

        <UpdatedButtonComponent
          mini={true}
          type={'default'}
          onClick={handleSaveClick}
        >
          Save
        </UpdatedButtonComponent>
      </div>
    </div>
  );
};

export default UpdateAccountGroupModal;
