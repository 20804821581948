import { RoleContext } from '../../../../context/role.context';
import { trackBookDemoCall } from '../../../../tools/analytics-events';
import './index.css';
import classNames from 'classnames';
import { useContext } from 'react';

interface ContactComponentProps {
  active?: boolean;
  calendlyActive?: boolean;
}

export const ContactComponent = ({
  active,
  calendlyActive,
}: ContactComponentProps) => {
  const { userType } = useContext(RoleContext);
  if (userType && !['BOOTSTRAP', 'BOOTSTRAP_NEXT'].includes(userType))
    return null;
  if (calendlyActive) return null;
  const contact = () => {
    const link = import.meta.env.VITE_CALENDLY_LINK;

    const linkToUse = link || 'https://calendly.com/d/ck79-56f-d9h/fuel-intro';
    trackBookDemoCall();

    window.open(linkToUse, '_blank');
  };

  const contactClasses = classNames({
    'contact-container': true,
    active: active,
  });

  return (
    <div className={contactClasses}>
      <img alt="" src="images/contact-avatar.png" />
      <p>Get your dedicated financial manager from Fuel</p>
      <button onClick={contact}>Schedule a call</button>
    </div>
  );
};
