import { CompanyDocument } from '../../../../tools/api-services/types/document';
import { trackOpenDocumentLink } from '../../../../tools/analytics-events';
import { X } from 'lucide-react';
import { useUser } from '../../../../context/user-details.context';

export const DocumentButton = ({
  id,
  link,
  name,
  onDelete,
}: CompanyDocument) => {
  const { userDetails } = useUser();
  const documentClick = (link: string, name: string) => (): void => {
    trackOpenDocumentLink({
      companyName: userDetails.companyName,
      userName: userDetails.userName,
      userType: userDetails.userType,
      documentName: name,
    });
    window.open(link, '_blank');
  };

  return (
    <div className="company-document">
      <button
        onClick={documentClick(link, name)}
        className="company-document__link_button"
      >
        {name}
      </button>
      <button onClick={onDelete} className="company-document__delete_button">
        <X size={'16px'} className="document-delete-cross" />
      </button>
    </div>
  );
};
