import { useState } from 'react';
import { ManagerCompaniesHttpService } from '../../../../tools/manager-api/http-companies';
import { Company } from '../../../../tools/manager-api/types/company';
export const useCompanyInfo = () => {
  const [companyInfo, setCompanyInfo] = useState<Company | undefined>(
    undefined
  );

  const fetchCompanyInfo = async (companyId: number) => {
    try {
      const res = await ManagerCompaniesHttpService.availableCompanies();
      setCompanyInfo(
        res.data.result.find((company) => company.id === companyId)
      );
    } catch (e) {
      console.error(e);
    }
  };

  return {
    companyInfo,
    fetchCompanyInfo,
  };
};
