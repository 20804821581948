import { BasePage } from '../../base';
import { Container } from '../../../shared-components/container';
import { useBusinessModel } from './business-model.hook';
import './style.css';
import { Button } from '../../../shared-components/button';
import { RadioInput } from '../../../shared-components/radio-input/radio.component';
import { CheckboxInput } from '../../../shared-components/checkbox-input/checkbox.component';

const businessModels = [
  'SaaS',
  'E-commerce / Manufacturing',
  'Professional Services',
  'Marketplace',
];
const experiences = [
  'First-time founder',
  'Need a refresher',
  'Financial director/CFO',
  'Familiar with P&L',
];
const needs = [
  'Fundraising',
  'Improve planning',
  'Understand my business',
  'Grow faster',
  'Improve profitability',
];

export const BusinessModelPage = () => {
  const { form } = useBusinessModel();

  return (
    <BasePage publicPage={true}>
      <Container extended={true}>
        <div className="business-model-header">
          <span className="step-indicator">1/5</span>
          <Button
            mini
            onClick={form.handleSubmit}
            active={true}
            children={'Next'}
            style={{
              width: '69px',
              height: '36px',
            }}
            type="submit"
          />
        </div>

        <form onSubmit={form.handleSubmit}>
          <div className="form-container">
            <label className="form-label">Your business model:</label>
            <div className="radio-grid">
              {businessModels.map((option, idx) => (
                <RadioInput
                  value={option}
                  key={idx}
                  handleChange={form.handleChange}
                  checked={form.values.businessModel === option}
                  name="businessModel"
                />
              ))}
            </div>
            {form.errors.businessModel && form.touched.businessModel && (
              <div className="error-message">{form.errors.businessModel}</div>
            )}
          </div>
          <div className="divider-horizontal"></div>
          <div className="form-container">
            <label className="form-label">Your experience:</label>
            <div className="radio-grid">
              {experiences.map((exp, idx) => (
                <RadioInput
                  value={exp}
                  key={idx}
                  handleChange={form.handleChange}
                  checked={form.values.experience === exp}
                  name="experience"
                />
              ))}
            </div>
            {form.errors.experience && form.touched.experience && (
              <div className="error-message">{form.errors.experience}</div>
            )}
          </div>
          <div className="divider-horizontal"></div>
          <div className="form-container">
            <label className="form-label">Your needs:</label>
            <div className="radio-grid">
              {needs.map((need, idx) => (
                <CheckboxInput
                  controlName="needs"
                  value={need}
                  key={idx}
                  handleChange={form.handleChange}
                  checked={form.values.needs.includes(need)}
                />
              ))}
            </div>
            {form.errors.needs && form.touched.needs && (
              <div className="error-message">{form.errors.needs}</div>
            )}
          </div>
        </form>
      </Container>
      <div>
        <p style={{ textAlign: 'center' }}>
          Already use Fuel? <a href="/login">Login</a>
        </p>
      </div>
    </BasePage>
  );
};
