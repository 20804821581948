import { BasePage } from '../base';
import { Container } from '../../shared-components/container';
import { Head } from '../../shared-components/head';
import './report-issue.css';
import { Button } from '../../shared-components/button';
import { useReportIssue } from './hooks/report-issue.hook';
export const ReportIssuePage = () => {
  const { reportIssueForm, errors, touched } = useReportIssue();
  return (
    <BasePage mini>
      <Container extended={true}>
        <Head>Report a bug</Head>
        <br />
        <form>
          <p id="first-subtitle" className="bug-subtitle">
            Please enter your email address. We will respond to your email
            within 48 hours.
          </p>
          <input
            className="report-issuer-email"
            onChange={reportIssueForm.handleChange}
            placeholder="Email"
            name="email"
          />
          {errors.email && touched.email && (
            <div className="form-error">{errors.email}</div>
          )}
          <br />
          <br />
          <p id="last-subtitle" className="bug-subtitle">
            Please provide bug details
          </p>
          <textarea
            className="report-issue-textarea"
            onChange={reportIssueForm.handleChange}
            placeholder="Hey there! Describe your request"
            name="bugDescription"
          ></textarea>
          {errors.bugDescription && touched.bugDescription && (
            <div className="form-error">{errors.bugDescription}</div>
          )}
        </form>

        <Button
          onClick={reportIssueForm.submitForm}
          active={true}
          mini={true}
          type={'submit'}
          style={{ width: 200, height: 40, alignSelf: 'flex-end' }}
        >
          Submit
        </Button>
      </Container>
      <div>
        <p style={{ textAlign: 'center' }}>
          Already use Fuel? <a href="/login">Login</a>
        </p>
      </div>
    </BasePage>
  );
};
