import { RoleContext } from '../../../context/role.context';
import { useContext, useEffect, useState } from 'react';
import { DashboardHttpService } from '../../../tools/api-services/dashboardHttpService';
import { useNotificationService } from '../../../tools/notification';
import { ClientInfo } from '../../../tools/api-services/types/client';

export const useDashboard = () => {
  const [userData, setUserData] = useState<ClientInfo | null>(null);

  const token = localStorage.getItem('token');
  const { userType } = useContext(RoleContext);
  const { calendlyMessage } = useNotificationService(token);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false);
  const [isQuickbooksConnectPageValid, setIsQuickbooksConnectPageValid] =
    useState(false);

  const getUserInfo = async () => {
    try {
      const { data } = await DashboardHttpService.me();
      setUserData(data as ClientInfo);

      if (userType && ['BOOTSTRAP', 'BOOTSTRAP_NEXT'].includes(userType)) {
        const [onboardingData, quickbooksData] = await Promise.all([
          DashboardHttpService.isOnboardingCompleted(),
          DashboardHttpService.getQuickbooksConnectToken(),
        ]);
        setIsOnboardingCompleted(onboardingData.data.completed);
        setIsQuickbooksConnectPageValid(
          quickbooksData.data.isQuickbooksConnectTokenActive
        );
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, [calendlyMessage]);

  return {
    userData,
    isLoading,
    isOnboardingCompleted,
    isQuickbooksConnectPageValid,
  };
};
