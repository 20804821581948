import { useCallback, useEffect, useState } from 'react';
import { PaymentHttpService } from '../../tools/api-services/payment.http-service';
import {
  CardDetails,
  SubscriptionDetails,
} from '../../tools/api-services/types/payment';
import { DashboardHttpService } from '../../tools/api-services/dashboardHttpService';

export const usePaymentDetails = () => {
  const [isPaid, setIsPaid] = useState(false);
  const [cardDetails, setCardDetails] = useState<CardDetails | null>(null);
  const [subscriptionDetails, setSubscriptionDetails] =
    useState<SubscriptionDetails | null>(null);
  const [userData, setUserData] = useState({
    firstName: '',
    email: '',
  });
  const [isPaymentLoading, setIsPaymentLoading] = useState(true);
  const [isSubscriptionCanBeRenewed, setIsSubscriptionCanBeRenewed] = useState<
    boolean | null
  >(null);

  const getSubscriptionAndCardDetails = async () => {
    const {
      data: {
        cardDetails,
        subscriptionStatus,
        isSubscriptionGoingToBeCancelled,
        expirationDate,
      },
    } = await PaymentHttpService.getStripePaymentDetails();

    setCardDetails(cardDetails);
    setSubscriptionDetails({
      subscriptionStatus,
      isSubscriptionGoingToBeCancelled,
      expirationDate,
    });

    if (
      (subscriptionStatus === 'active' && isSubscriptionGoingToBeCancelled) ||
      subscriptionStatus === 'canceled'
    ) {
      setIsSubscriptionCanBeRenewed(true);
    } else if (!subscriptionStatus && !isSubscriptionGoingToBeCancelled) {
      setIsSubscriptionCanBeRenewed(null);
    } else {
      setIsSubscriptionCanBeRenewed(false);
    }
  };

  const getIsPayed = async () => {
    const {
      data: { paymentFinished },
    } = await PaymentHttpService.getPaymentStatus();
    setIsPaid(paymentFinished);
  };

  const getUserData = async () => {
    const { data } = await DashboardHttpService.me();
    setUserData({ firstName: data.firstName, email: data.email });
  };

  const flow = useCallback(async () => {
    await getIsPayed();
    await getSubscriptionAndCardDetails();
    await getUserData();
    setIsPaymentLoading(false);
  }, []);

  useEffect(() => {
    flow();
  }, [flow]);

  return {
    isPaid,
    cardDetails,
    subscriptionDetails,
    firstName: userData.firstName,
    email: userData.email,
    isPaymentLoading,
    isSubscriptionCanBeRenewed,
    flow,
  };
};
