import { useFormik } from 'formik';
import { DashboardHttpService } from '../../../tools/api-services/dashboardHttpService';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import ReactGA from 'react-ga4';
import { ToasterContext } from '../../../context/toaster.context';

const schema = Yup.object().shape({
  bugType: Yup.string().required('Bug type is required'),
  bugDescription: Yup.string()
    .min(3, 'Description should be at least 3 symbols length')
    .required('Description is required'),
});

export const useBugReport = () => {
  const { updateToaster } = useContext(ToasterContext);
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState('');

  const getUserEmail = async () => {
    try {
      await DashboardHttpService.isAuthenticated();
      const response = await DashboardHttpService.me();
      if (response.data) {
        setUserEmail(response.data.email);
      }
    } catch (e) {
      console.error('Error fetching user email:', e);
    }
  };

  useEffect(() => {
    getUserEmail();
  });

  const bugTypeValues = [
    'No data in my dashboard',
    'Calculations mismatch',
    "Quibckbooks integration doesn't work",
    'Errors in the reports',
    'Other',
  ];

  const sendBugReport = async (data: {
    bugType: string;
    bugDescription: string;
  }) => {
    await DashboardHttpService.bugReport(data);
  };

  const bugReportForm = useFormik({
    initialValues: {
      bugType: '',
      bugDescription: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const dateTimeOpened = new Date().toISOString();
      ReactGA.event({
        category: 'Bug Report',
        action: values.bugType,
        label: `${userEmail} - ${dateTimeOpened}`,
      });
      await sendBugReport(values);
      updateToaster({
        content:
          'Thank you for your feedback. We will respond to your email within 48 hours.',
      });
      navigate('/');
    },
  });

  return {
    bugReportForm,
    bugTypeValues,
    errors: bugReportForm.errors,
    touched: bugReportForm.touched,
  };
};
