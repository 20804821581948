import { instanceWithToken } from '../api';

export class DevelopmentHttpService {
  public static async removeSelf() {
    await instanceWithToken.delete('/api/me');
  }

  public static async startImport() {
    await instanceWithToken.post('/api/me/start-manual-import');
  }
}
