import { useState } from 'react';
import './styles.css';
import { GSheetConnectModal } from '../gsheet-upload-modal/gsheet-connect.modal';
import { GSheetConnectProgressModal } from '../gsheet-connect-progress-modal/gsheet-connect-progress.modal';

export const GSheetSource = () => {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openUploadProgressModal, setOpenUploadProgressModal] = useState(false);

  const closeModal = () => {
    setOpenUploadModal(false);
    setOpenUploadProgressModal(false);
  };

  return (
    <>
      <button
        className="google_sheet-data-source"
        onClick={() => setOpenUploadModal(true)}
      >
        Google Sheet
      </button>
      {openUploadModal && (
        <>
          <div className="modal-overlay" onClick={closeModal} />
          <GSheetConnectModal
            onClose={closeModal}
            openProgressModal={() => setOpenUploadProgressModal(true)}
          />
        </>
      )}
      {openUploadProgressModal && (
        <>
          <div className="modal-overlay" onClick={closeModal} />
          <GSheetConnectProgressModal onClose={closeModal} />
        </>
      )}
    </>
  );
};
