import axios from 'axios';
import { hubspotEnvironmentMap } from '../../../tools/hubspot';
export const useHubspot = () => {
  const ENV: string = import.meta.env.VITE_APP_ENVIRONMENT || 'development';

  const hubspotEnvironment = hubspotEnvironmentMap.get(ENV);

  if (!hubspotEnvironment) {
    return {
      hubspotSubmit: async () => {
        console.error('Hubspot environment not found');
      },
    };
  }

  const { portalId, formId } = hubspotEnvironment;

  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  const hubspotSubmit = async (formData: {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
  }) => {
    const body = {
      fields: [
        {
          name: 'email',
          value: formData.email,
        },
        {
          name: 'firstname',
          value: formData.firstName,
        },
        {
          name: 'lastname',
          value: formData.lastName,
        },
        {
          name: 'company',
          value: formData.company,
        },
      ],
    };

    try {
      await axios.post(url, body);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    hubspotSubmit,
  };
};
