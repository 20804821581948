import { ManagerHttpService } from '../../tools/manager-api/manager.http.service';
import { useEffect, useState } from 'react';

export const useManagerInfo = () => {
  const [manager, setManager] = useState<{
    managerUserImage: string;
    managerUserName: string;
  } | null>(null);

  const getManagerData = async () => {
    const result = await ManagerHttpService.info();

    if (!result) {
      return;
    }

    const {
      data: { userImageLink, firstName },
    } = result;

    setManager({
      managerUserImage: userImageLink,
      managerUserName: `${firstName}`,
    });
  };

  useEffect(() => {
    getManagerData();
  }, []);

  return {
    managerUserImage: manager?.managerUserImage,
    managerUserName: manager?.managerUserName,
  };
};
