import { useUser } from '../../context/user-details.context';
import {
  trackPressContactUs,
  trackPressPrivacy,
  trackPressTerms,
  trackReportBug,
} from '../../tools/analytics-events';
import './style.css';
import { useContext } from 'react';
import { RoleContext } from '../../context/role.context';
export const Footer = ({ preLogin = false }) => {
  const { userType } = useContext(RoleContext);

  const { userDetails } = useUser();

  if (!userType) {
    return null;
  }

  if (['MANAGER', 'HUBSPOT'].includes(userType)) {
    return null;
  }

  return (
    <footer className="footer">
      <a
        href="https://fuelfinance.me/terms-of-service/"
        onClick={() =>
          trackPressTerms({
            companyName: userDetails.companyName,
            userName: userDetails.userName,
            userType: userDetails.userType,
          })
        }
      >
        Terms
      </a>
      <a
        href="https://drive.google.com/file/d/1MRNGp9YXCOvnXFHBik7IZlqalU3PLroO/view"
        onClick={() =>
          trackPressPrivacy({
            companyName: userDetails.companyName,
            userName: userDetails.userName,
            userType: userDetails.userType,
          })
        }
      >
        Privacy
      </a>
      {!preLogin && (
        <a
          href="/contact-us"
          onClick={() =>
            trackPressContactUs({
              companyName: userDetails.companyName,
              userName: userDetails.userName,
              userType: userDetails.userType,
            })
          }
        >
          Contact us
        </a>
      )}
      <a
        href={preLogin ? '/support/report-issue' : '/report-bug'}
        onClick={() =>
          trackReportBug({
            companyName: userDetails.companyName,
            userName: userDetails.userName,
            userType: userDetails.userType,
          })
        }
      >
        Report a bug
      </a>
    </footer>
  );
};

export default Footer;
