import { BasePage } from '../../base';
import { Container } from '../../../shared-components/container';
import './style.css';
import { useLocation } from 'react-router-dom';
import { instanceWithToken } from '../../../tools/api';
export const EmailVerification = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');

  const sendVerifyEmail = async (email: string | null) => {
    await instanceWithToken.post(`api/auth/send-verify-email?email=${email}`);
  };

  const verifyEmailPath = `/verify-email?email=${email}`;
  return (
    <BasePage mini>
      <Container extended={false}>
        <div className="content-header">
          <h1 className="content-header-title">Verify Your Account</h1>
          <p className="content-header-description">
            <span style={{ color: '#808080' }}>
              We've sent a verification link to your email{' '}
            </span>
            <span style={{ textDecoration: 'underline' }}>{email}</span>
          </p>
        </div>
        <div className="content-body-1">
          <p className="content-header-description">
            <span style={{ color: '#808080' }}>
              Need to make changes to the email?{' '}
            </span>
            <a style={{ textDecoration: 'none' }} href="/login">
              Edit here.
            </a>
          </p>
        </div>
        <div className="content-body-2">
          <p className="content-header-description">
            <span style={{ color: '#808080' }}>
              Didn't find it in your inbox? Let us{' '}
            </span>
            <a
              style={{ textDecoration: 'none' }}
              href={verifyEmailPath}
              onClick={() => sendVerifyEmail(email)}
            >
              resend the email
            </a>
          </p>
        </div>
      </Container>
    </BasePage>
  );
};
