import { useEffect, useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  AccountGroup,
  AccountGroupSource,
} from '../../../tools/manager-api/types/mapping';
import { ManagerHttpMappingService } from '../../../tools/manager-api/manager-http-mapping';
import { Edit3, Trash2, ArrowUp, ArrowDown } from 'lucide-react';
import AddAccountGroupModalProps from '../modals/add-account-group/add-account-group.modal';
import UpdateAccountGroupModalProps from '../modals/update-account-group/update-account-group.modal';
import '../financial-accounts/financial-account.style.css';
import { ToasterContext } from '../../../context/toaster.context';
import { SearchBar } from '../components/search-bar.component';
import { Pagination } from '../components/pagination.component';
import { Loader } from '../components/loader.component';
const PAGE_LIMIT = 50;

export const FinancialGroupsPage = () => {
  const { companyId } = useParams();
  const [addAccountGroupModalActive, setAddAccountGroupModalActive] =
    useState<boolean>(false);
  const [updateAccountGroupModalActive, setUpdateAccountGroupModalActive] =
    useState<boolean>(false);
  const [accountGroupToUpdate, setGroupToUpdate] = useState<{
    id: number;
    groupName: string;
    type: { id: number; name: string };
  }>({
    id: 0,
    groupName: '',
    type: { id: 0, name: '' },
  });

  const [filteredGroups, setFilteredGroups] = useState<AccountGroup[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [allAccountsTypesData, setAllAccountTypes] = useState<AccountGroup[]>(
    []
  );
  const [sorting, setSorting] = useState<{
    field: string;
    order: 'asc' | 'desc';
  }>({
    field: 'name',
    order: 'asc',
  });

  const ctx = useContext(ToasterContext);

  const fetchAccountGroups = useCallback(
    async (searchTerm: string) => {
      setLoading(true);
      try {
        const {
          data: { result: accountGroup, total },
        } = await ManagerHttpMappingService.filterAccountGroups(
          Number(companyId),
          {
            page: page,
            limit: PAGE_LIMIT,
            search: searchTerm,
            sorting: [{ key: sorting.field, order: sorting.order }],
          }
        );

        setFilteredGroups(accountGroup);
        setTotalCount(total);
      } catch {
        ctx.updateToaster({
          content: 'Failed to fetch account groups list',
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [companyId, page, sorting]
  );

  useEffect(() => {
    fetchAccountGroups(search);
  }, [fetchAccountGroups, search]);

  useEffect(() => {
    const fetchAllAccountTypes = async () => {
      try {
        setLoading(true);
        const { data } = await ManagerHttpMappingService.getAccountTypes(
          Number(companyId)
        );
        setAllAccountTypes(data);
      } catch {
        ctx.updateToaster({
          content: 'Failed to fetch master list',
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchAllAccountTypes();
  }, [companyId]);

  const handleAddAccount = async (groupName: string, accountType: number) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.addAccountGroup(Number(companyId), {
        name: groupName,
        type: accountType,
        source: AccountGroupSource.MANUAL,
      });
      setAddAccountGroupModalActive(false);
      await fetchAccountGroups(search);
    } catch {
      ctx.updateToaster({
        content: 'Failed to add master account',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteMasterAccount = async (accountId: number) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.deleteMasterList(
        Number(companyId),
        accountId,
        'ACCOUNT'
      );
      await fetchAccountGroups(search);
    } catch {
      ctx.updateToaster({
        content: 'Failed to delete master account',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenUpdateModal = (group: {
    id: number;
    groupName: string;
    type: any;
  }) => {
    setGroupToUpdate(group);
    setUpdateAccountGroupModalActive(true);
  };

  const handleUpdateAccountGroup = async (
    id: number,
    groupName: string,
    type: number
  ) => {
    setLoading(true);
    try {
      await ManagerHttpMappingService.updateAccountGroup(
        Number(companyId),
        id,
        {
          name: groupName,
          type: type,
          source: AccountGroupSource.MANUAL,
        }
      );
      setUpdateAccountGroupModalActive(false);
      await fetchAccountGroups(search);
    } catch {
      ctx.updateToaster({
        content: 'Failed to update master account',
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleMasterSearch = () => {
    setPage(1);
    fetchAccountGroups(search);
  };

  const toggleMasterSorting = () => {
    setSorting((prev) => ({
      field: 'name',
      order: prev.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const renderTableHeaders = () => {
    return (
      <tr>
        <th
          className="master-table-header"
          onClick={toggleMasterSorting}
          style={{ cursor: 'pointer' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>GROUP NAME</span>
            {sorting.order === 'asc' ? (
              <ArrowUp size={20} style={{ marginLeft: '8px' }} />
            ) : (
              <ArrowDown size={20} style={{ marginLeft: '8px' }} />
            )}
          </div>
        </th>
        <th className="master-table-header">SOURCE OF THE GROUP</th>
        <th className="master-table-header">GROUP TYPE</th>
        <th
          onClick={() => setAddAccountGroupModalActive(true)}
          style={{ cursor: 'pointer', textAlign: 'end' }}
          className="master-table-header"
        >
          <span
            style={{
              textDecoration: 'underline',
              color: 'black',
              fontWeight: 'normal',
              fontSize: '1rem',
              margin: '0 0.5rem',
            }}
          >
            + GROUP
          </span>
        </th>
      </tr>
    );
  };

  const renderTableRows = () => {
    return (
      <>
        {filteredGroups?.map((account, index) => (
          <tr key={index}>
            <td className="master-table-row">{account.name}</td>
            <td className="master-table-row">{account.source}</td>
            <td className="master-table-row">{account.type?.name || ''}</td>
            <td className="actions master-table-row">
              <Edit3
                className="edit-icon"
                onClick={() =>
                  handleOpenUpdateModal({
                    id: account.id,
                    groupName: account.name,
                    type: account.type,
                  })
                }
              />
              <Trash2
                className="mapping-delete-icon"
                onClick={() => handleDeleteMasterAccount(account.id)}
              />
            </td>
          </tr>
        ))}
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ textAlign: 'end' }}>
            <Pagination
              page={page}
              totalCount={totalCount}
              onPageChange={setPage}
            />
          </td>
        </tr>
      </>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="mapping-financial-accounts">
      <div className="mapping-search-action">
        <SearchBar
          searchValue={search}
          setSearchValue={setSearch}
          placeholder="Type group name"
          onSearch={handleMasterSearch}
        />
      </div>
      <table className="master-table">
        <thead>{renderTableHeaders()}</thead>
        <tbody>{renderTableRows()}</tbody>
      </table>

      {addAccountGroupModalActive && (
        <AddAccountGroupModalProps
          onAdd={handleAddAccount}
          onClose={() => setAddAccountGroupModalActive(false)}
          accountTypeOptions={allAccountsTypesData}
        />
      )}
      {updateAccountGroupModalActive && (
        <UpdateAccountGroupModalProps
          group={accountGroupToUpdate}
          onSave={handleUpdateAccountGroup}
          onClose={() => setUpdateAccountGroupModalActive(false)}
          accountTypeOptions={allAccountsTypesData}
        />
      )}
    </div>
  );
};
