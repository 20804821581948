import { SquareX } from 'lucide-react';
import './styles.css';

export const GSheetConnectProgressModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  return (
    <div className="csv-upload-progress-modal">
      <div className="csv-upload-progress-modal__header">
        <span style={{ fontSize: '20px', lineHeight: '24px' }}>
          File uploading..
        </span>
        <SquareX
          className="close-cross-button"
          onClick={onClose}
          style={{ width: '40px', height: '40px' }}
          strokeWidth={'1'}
        />
      </div>
      <p>
        This might take a few minutes. You can leave this page we'll notify you
        when it's done.
      </p>
    </div>
  );
};
