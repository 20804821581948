import React, { useState } from 'react';
import '../add-account-group/add-account-group.style.css';
import { Edit3, SquareX } from 'lucide-react';
import { UpdatedButtonComponent } from '../../../../shared-components/button/updated.button.component';

interface AddAccountGroupModalProps {
  onClose: () => void;
  onAdd: (groupName: string, accountType: number) => void;
  accountTypeOptions: { id: number; name: string }[];
}

const AddAccountGroupModal: React.FC<AddAccountGroupModalProps> = ({
  onClose,
  onAdd,
  accountTypeOptions,
}) => {
  const [accountName, setAccountName] = useState('');
  const [accountType, setAccountType] = useState(0);
  const [emptyNameError, setEmptyNameError] = useState<string | null>(null);
  const [emptyTypeError, setEmptyTypeError] = useState<string | null>(null);

  const handleAddClick = () => {
    if (!accountName.trim()) {
      setEmptyNameError('Account name is required');
      return;
    }

    if (!accountType) {
      setEmptyTypeError('Group is required');
      return;
    }

    onAdd(accountName, accountType);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="add-account-group-modal">
        <div className="add-account-group-modal__header">
          <h2>Add Group</h2>
          <SquareX
            className="close-cross-button"
            onClick={onClose}
            style={{ width: '45px', height: '45px' }}
            strokeWidth={'0.7'}
          />
        </div>
        <div className="add-account-group-modal__body">
          <div className="input-group">
            <input
              id="account-name"
              type="text"
              placeholder="Group name"
              value={accountName}
              onChange={(e) => {
                setAccountName(e.target.value);
                setEmptyNameError(null);
              }}
              name="accountName"
            />
            {emptyNameError && (
              <span className="error-message">{emptyNameError}</span>
            )}
          </div>
          <div>
            <select
              id="account-group"
              onChange={(e) => {
                setAccountType(Number(e.target.value));
                setEmptyTypeError(null);
              }}
              name="accountType"
              className="select-input"
              value={accountType}
            >
              <option value="" hidden>
                Group of Account
              </option>
              {accountTypeOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {emptyTypeError && (
              <span className="error-message">{emptyTypeError}</span>
            )}
          </div>
        </div>

        <UpdatedButtonComponent
          mini={true}
          type={'default'}
          onClick={handleAddClick}
        >
          Add
        </UpdatedButtonComponent>
      </div>
    </div>
  );
};

export default AddAccountGroupModal;
