import { BasePage } from '../base';
import Footer from '../../shared-components/footer/footer.component';
import './quickbooks-connect.css';
import { Button } from '../../shared-components/button';
import { Container } from '../../shared-components/container';
import { useQbConnector } from '../../shared-components/feature/qb-connector.hook';
import { useContext } from 'react';
import { RoleContext } from '../../context/role.context';

export const QuickbooksConnectPage = () => {
  const { userType } = useContext(RoleContext);
  const { getLinkAndRedirect, createQuickbooksConnectToken } = useQbConnector();

  return (
    <BasePage>
      <Container extended={true}>
        <div className="quickbooks-connect-content">
          <div className="quickbooks-image">
            <img alt="" src="images/quickbooks.png" />
          </div>
          <div className="quickbooks-connect-content-title">
            <h2>
              Have <span>QuickBooks</span> account?
            </h2>
          </div>
          <div className="quickbooks-connect-content-actions">
            <div className="quickbooks-connect-content-actions__item">
              <button
                className={'skip-button'}
                onClick={() => createQuickbooksConnectToken()}
              >
                SKIP
              </button>
            </div>
            <div className="quickbooks-connect-content-actions__item">
              <Button
                active={true}
                mini={true}
                onClick={() =>
                  getLinkAndRedirect(userType !== 'BOOTSTRAP_NEXT')
                }
              >
                CONNECT NOW
              </Button>
            </div>
          </div>
        </div>
        <div className="quickbooks-connect-content-description">
          {userType === 'BOOTSTRAP' && (
            <p>
              Connect it now for <span>$39</span>/month:
            </p>
          )}
          <p>
            <span role="img" aria-label="Hourglass">
              ⌛️
            </span>{' '}
            Save hours of manual data input.
          </p>
          <p>
            <span role="img" aria-label="Document">
              📑
            </span>{' '}
            Get all the financial data needed for the reports.
          </p>
        </div>
      </Container>
      <Footer />
    </BasePage>
  );
};
