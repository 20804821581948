import { Container } from '../shared-components/container';
import { BasePage } from './base';
import { Head } from '../shared-components/head';

export const TextPage = ({ text }: any) => {
  return (
    <BasePage mini>
      <Container extended={false}>
        <Head>{text}</Head>
      </Container>
    </BasePage>
  );
};
