import React from 'react';
import YouTube from 'react-youtube';

interface YouTubePlayerProps {
  videoId?: string;
  onReady: (event: any) => void;
  onVideoEnd: (event: any) => void;
  onVideoPause: (event: any) => void;
  onPlay: (event: any) => void;
  showVideo: boolean;
}

export const YouTubePlayer: React.FC<YouTubePlayerProps> = ({
  videoId,
  onReady,
  onVideoEnd,
  onVideoPause,
  onPlay,
  showVideo,
}) => {
  return (
    <div
      style={{
        visibility: showVideo ? 'visible' : 'hidden',
      }}
    >
      <YouTube
        videoId={videoId}
        opts={{
          width: '100%',
          height: '100%',
          playerVars: {
            autoplay: 0,
            rel: 0,
          },
        }}
        onReady={onReady}
        onEnd={onVideoEnd}
        onPause={onVideoPause}
        onPlay={onPlay}
      />
    </div>
  );
};
