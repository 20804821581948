import classNames from 'classnames';
import './updated-button.style.css';

interface ButtonProps {
  type: 'default' | 'empty' | 'bordered' | 'warning';
  onClick: () => void;
  children: string;
  mini?: boolean;
}

export const UpdatedButtonComponent = ({
  type,
  onClick,
  children,
  mini,
}: ButtonProps) => {
  const buttonClasses = classNames('updated-button', {
    default: type === 'default',
    empty: type === 'empty',
    bordered: type === 'bordered',
    mini: mini,
    warning: type === 'warning',
  });

  return (
    <button onClick={onClick} className={buttonClasses}>
      {children}
    </button>
  );
};
