import { DashboardHttpService } from '../../tools/api-services/dashboardHttpService';
import { useContext, useEffect, useState } from 'react';
import { ClientManagerResponse } from '../../tools/api-services/types/client-manager.response';
import { RoleContext } from '../../context/role.context';

export const useClientManager = () => {
  const { userType } = useContext(RoleContext);
  const [manager, setManager] = useState<ClientManagerResponse | null>(null);
  const [failed, setFailed] = useState(false);
  const fetchManager = async () => {
    try {
      const response = await DashboardHttpService.getManager();
      setManager(response.data);
      return response.data;
    } catch (error) {
      setFailed(true);
      console.error(error);
    }
  };

  const getSlackLink = () => {
    return `https://slack.com/app_redirect?channel=${manager?.slackId}`;
  };

  useEffect(() => {
    if (userType === 'HUBSPOT') {
      fetchManager();
    }
  }, []);

  return {
    manager,
    failed,
    getSlackLink,
  };
};
