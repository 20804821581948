import { BasePage } from '../../pages/base';
import { Container } from '../../shared-components/container';
import { useManagerHome } from './manager.home.hook';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../shared-components/footer/footer.component';

export const ManagementHomePage = () => {
  const { companies, getAvailableCompanies } = useManagerHome();
  const navigate = useNavigate();

  useEffect(() => {
    getAvailableCompanies();

    if (companies.length > 0) {
      const url = `/manager/companies/${companies[0].id}/`;
      navigate(url);
    }
  }, [getAvailableCompanies]);

  return (
    <BasePage publicPage={false}>
      <Container extended={true}>
        {companies.length > 0 ? (
          <p>Redirecting</p>
        ) : (
          <p>There are no companies assigned</p>
        )}
      </Container>
      <Footer />
    </BasePage>
  );
};
