export const Preloader = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <div
      style={{ display: isLoading ? 'block' : 'none' }}
      className="preloader"
    >
      <svg
        width="118"
        height="15"
        viewBox="0 0 118 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.7145 0.240994V9.28904C29.7145 12.0212 32.3256 14.4297 35.3726 14.4297C37.5918 14.561 41.454 14.5422 44.5922 14.5422C48.2049 14.5422 50.8527 14.4297 53.2975 14.4297C57.0473 14.4297 59.2754 11.9017 59.2754 9.28904V0.241026H45.4041V7.36242C45.4041 10.7091 45.4919 10.7091 44.5922 10.7091C43.6924 10.7091 43.786 10.7091 43.786 7.36242V0.241026L29.7145 0.240994Z"
          fill="white"
        />
        <path
          d="M28.3205 4.35151V0.240967H0V14.5431H14.2643V9.40641H28.3205V5.39659H14.2643V4.35151H28.3205Z"
          fill="white"
        />
        <path
          d="M74.6461 10.5333V9.46805H88.7363V5.19515H74.6461V4.2508H88.7363V0.24097H60.4158V14.5431H88.7363V10.5333H74.6461Z"
          fill="white"
        />
        <path
          d="M89.9468 14.5431H118V10.5949L103.942 10.5949V0.240968L89.9468 0.24097V14.5431Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
