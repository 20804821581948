import { DevelopmentHttpService } from '../../tools/api-services/development.http.service';
import { useNavigate } from 'react-router-dom';

export const useDevelopment = () => {
  const navigate = useNavigate();
  const isProduction = import.meta.env.VITE_APP_ENVIRONMENT === 'production';
  const removeSelf = async () => {
    if (import.meta.env.VITE_APP_ENVIRONMENT === 'production') {
      return;
    }
    await DevelopmentHttpService.removeSelf();
    localStorage.removeItem('token');
    localStorage.removeItem('userDetails');
    navigate('/login');
  };
  const startImport = async () => {
    await DevelopmentHttpService.startImport();
    navigate('/');
  };

  return { removeSelf, startImport, isProduction };
};
