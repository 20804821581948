import { ChangeEvent, useState } from 'react';
import { ManagerCompaniesHttpService } from '../../../../tools/manager-api/http-companies';
import { Button } from '../../../../shared-components/button';
import { SquareX } from 'lucide-react';
import './styles.css';
import { useUser } from '../../../../context/user-details.context';
import { trackAddReportLink } from '../../../../tools/analytics-events';

export const AddReportModal = ({
  onClose,
  fetchDocuments,
  companyId,
}: {
  onClose: () => void;
  fetchDocuments: (companyId: number) => void;
  companyId: number | undefined;
}) => {
  const { userDetails } = useUser();
  const [newDocument, setNewDocument] = useState({
    documentName: '',
    documentLink: '',
  });
  const handleDocumentNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNewDocument((prevDocument) => ({
      ...prevDocument,
      documentName: value,
    }));
  };

  const handleDocumentLinkChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNewDocument((prevDocument) => ({
      ...prevDocument,
      documentLink: value,
    }));
  };

  const handleAddDocument = async () => {
    const { documentName, documentLink } = newDocument;
    if (documentName && documentLink) {
      try {
        if (companyId) {
          await ManagerCompaniesHttpService.addCompanyDocument(
            companyId,
            newDocument
          );
          fetchDocuments(companyId);
        }
        trackAddReportLink({
          companyName: userDetails.companyName,
          userName: userDetails.userName,
          userType: userDetails.userType,
        });
        onClose();
      } catch (error) {
        console.error(error);
      }
      setNewDocument({ documentName: '', documentLink: '' });
    } else {
      alert('Please fill in all fields');
    }
  };

  return (
    <div className="add-document-modal">
      <div className="add-document-modal__header">
        <h2>Add report</h2>
        <SquareX
          className="close-cross-button"
          onClick={onClose}
          style={{ width: '40px', height: '40px' }}
          strokeWidth={'1'}
        />
      </div>
      <input
        type="text"
        placeholder="Title"
        onChange={handleDocumentNameChange}
        value={newDocument.documentName}
      />
      <input
        type="text"
        placeholder="Link"
        onChange={handleDocumentLinkChange}
        value={newDocument.documentLink}
      />
      <Button
        mini={true}
        active={!!(newDocument.documentName && newDocument.documentLink)}
        onClick={handleAddDocument}
        style={{ marginTop: '10px' }}
      >
        Add
      </Button>
    </div>
  );
};
