import { useEffect, useState, useCallback } from 'react';
import { ManagerHttpDashboardService } from '../../tools/manager-api/manager-http-dashboard';
import { useParams } from 'react-router-dom';
import { Metric } from '../../tools/manager-api/types/metrics';

export const useMetrics = () => {
  const { companyId } = useParams();
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchMetrics = useCallback(
    async (page = 1, limit = 50) => {
      try {
        const {
          data: { result, total },
        } = await ManagerHttpDashboardService.getMetricsList(
          Number(companyId),
          page,
          limit
        );

        setMetrics(result);
        setTotalCount(total);
      } catch (error) {
        console.error('Failed to fetch metrics:', error);
      }
    },
    [companyId]
  );

  const updateMetric = useCallback(
    async (
      metricId: number,
      updatedData: {
        description: string;
        metricGroup: string;
        metricType: string;
        benchmark: number;
      }
    ) => {
      try {
        await ManagerHttpDashboardService.updateMetric(
          Number(companyId),
          metricId,
          updatedData
        );
        await fetchMetrics();
        console.log(`Metric ${metricId} updated successfully`, updatedData);
      } catch (error) {
        console.error('Failed to update metric:', error);
      }
    },
    [companyId, fetchMetrics]
  );

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  return { metrics, updateMetric, totalCount, fetchMetrics };
};
