import { BasePage } from '../base';
import { Container } from '../../shared-components/container';
import { Head } from '../../shared-components/head';
import { Button } from '../../shared-components/button';
import { useLoginHook } from './login.hook';
import { Input } from '../../shared-components/input';
import './login.css';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useContext, useEffect } from 'react';
import Footer from '../../shared-components/footer/footer.component';
import { ToasterContext } from '../../context/toaster.context';

export const Login = () => {
  const { form } = useLoginHook();
  const [searchParams] = useSearchParams();

  const ctx = useContext(ToasterContext);

  const verifiedParam = searchParams.get('verified');
  const errorParam = searchParams.get('error');

  const getMessageFromQuery = useCallback(() => {
    const isVerifiedShown = localStorage.getItem('verifiedShown');

    if (!isVerifiedShown && verifiedParam === 'true') {
      return {
        message: 'Your account is verified! Please login.',
        isError: false,
      };
    } else if (errorParam) {
      let errorMessage = '';
      switch (errorParam) {
        case 'invalid-token':
          errorMessage = 'The verification token is invalid.';
          break;
        case 'expired-link':
          errorMessage = 'The verification link is expired.';
          break;
        case 'already-active':
          errorMessage = 'Your account is already active.';
          break;
        case 'user-not-found':
          errorMessage = 'User not found. Please register.';
          break;
        case 'unexpected-error':
        default:
          errorMessage = 'An unexpected error occurred.';
          break;
      }
      return {
        message: errorMessage,
        isError: true,
      };
    }
    return null;
  }, [verifiedParam, errorParam]);

  useEffect(() => {
    const messageObject = getMessageFromQuery();
    if (messageObject) {
      ctx.updateToaster({
        content: messageObject.message,
        isError: messageObject.isError,
      });
      if (!messageObject.isError) {
        localStorage.setItem('verifiedShown', 'true');
      }
    }
  }, [verifiedParam, errorParam]);

  const isActive =
    form.values.email.length > 0 && form.values.loginPassword.length > 0;

  const handleSubmit = (event: any) => {
    event.preventDefault();
    form.submitForm();
  };

  return (
    <>
      <BasePage mini publicPage={true}>
        <Container extended={false}>
          <Head>Login</Head>
          <div className="login">
            <form onSubmit={handleSubmit}>
              <div className="login-input-section">
                <Input
                  controlName={'email'}
                  type={'email'}
                  handleChange={form.handleChange}
                  value={form.values.email}
                  placeholder={'Email'}
                />
                <Input
                  controlName={'loginPassword'}
                  type={'password'}
                  handleChange={form.handleChange}
                  value={form.values.loginPassword}
                  placeholder={'Password'}
                  showPasswordToggle={true}
                />
              </div>
              <a href="/forgot-password">Forgot password?</a>
              <Button active={isActive} style={{ marginTop: '12px' }}>
                Login
              </Button>
            </form>
          </div>
        </Container>
        <div>
          <p style={{ textAlign: 'center' }}>
            Don't have an account?{' '}
            <a href="https://fuelfinance.me/bootstrap">Register</a>
          </p>
        </div>
      </BasePage>
      <Footer preLogin={true}></Footer>
    </>
  );
};
