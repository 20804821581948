import { useEffect, useState } from 'react';
import { PaymentHttpService } from '../../../tools/api-services/payment.http-service';

export const useTransactions = () => {
  const [transactions, setTransactions] = useState<any[]>([]);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false);

  const loadTransactions = async () => {
    setIsTransactionsLoading(true);
    const { data } = await PaymentHttpService.getStripePayments();

    const transformedData = data.map((item) => {
      return {
        orderNumber: item.id,
        price: `${item.amount} USD`,
        date: new Date(item.date).toLocaleDateString(),
      };
    });

    setTransactions(transformedData);
    setIsTransactionsLoading(false);
  };

  useEffect(() => {
    loadTransactions();
  }, []);

  return {
    transactions,
    isTransactionsLoading,
  };
};
