import React, { useState } from 'react';
import './update-employee.style.css';
import { SquareX } from 'lucide-react';
import { UpdatedButtonComponent } from '../../../../shared-components/button/updated.button.component';

interface UpdateEmployeeModalProps {
  onClose: () => void;
  onSave: (
    id: number,
    employeeName: string,
    deliveryTeam?: boolean,
    startDate?: string,
    endDate?: string
  ) => void;
  employee: {
    id: number;
    employeeName: string;
    deliveryTeam?: boolean;
    startDate?: string;
    endDate?: string;
  } | null;
}

const UpdateEmployeeModal: React.FC<UpdateEmployeeModalProps> = ({
  onClose,
  onSave,
  employee,
}) => {
  const [employeeName, setAccountName] = useState(employee?.employeeName || '');
  const [deliveryTeam, setDeliveryTeam] = useState(
    employee?.deliveryTeam || false
  );
  const [startDate, setStartDate] = useState(employee?.startDate);
  const [endDate, setEndDate] = useState(employee?.endDate);
  const [error, setError] = useState<string | null>(null);

  const handleSaveClick = () => {
    if (!employeeName?.trim()) {
      setError('Account name is required');
      return;
    }

    if (employee) {
      onSave(employee.id, employeeName, deliveryTeam, startDate, endDate);
      onClose();
    }
  };

  return (
    <div className="modal-overlay">
      <div className="update-employee-modal">
        <div className="update-employee-modal__header">
          <h2>Update Employee</h2>
          <SquareX
            className="close-cross-button"
            onClick={onClose}
            style={{ width: '45px', height: '45px' }}
            strokeWidth={'0.7'}
          />
        </div>
        <div className="update-employee-modal__body">
          <div className="input-group">
            <input
              id="account-name"
              type="text"
              placeholder="Employee name"
              value={employeeName}
              onChange={(e) => {
                setAccountName(e.target.value);
                setError(null);
              }}
              name="employeeName"
            />
            {error && <span className="error-message">{error}</span>}
          </div>
          <div className="input-group checkbox-group">
            <input
              id="delivery-team"
              type="checkbox"
              checked={deliveryTeam}
              onChange={(e) => setDeliveryTeam(e.target.checked)}
              name="deliveryTeam"
            />
            <label htmlFor="delivery-team">Delivery Team</label>
          </div>
          <div className="input-group">
            <input
              id="start-date"
              type="date"
              placeholder="Start date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              name="startDate"
            />
          </div>
          <div className="input-group">
            <input
              id="end-date"
              type="date"
              placeholder="Start date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              name="endDate"
            />
          </div>
        </div>

        <UpdatedButtonComponent
          mini={true}
          type={'default'}
          onClick={handleSaveClick}
        >
          Save
        </UpdatedButtonComponent>
      </div>
    </div>
  );
};

export default UpdateEmployeeModal;
