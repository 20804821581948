import { useContext } from 'react';
import { useQbConnector } from './qb-connector.hook';
import { RoleContext } from '../../context/role.context';

export const QuickbooksConnectorComponent = ({
  paymentRequired = true,
}: {
  paymentRequired?: boolean;
}) => {
  const { getLinkAndRedirect } = useQbConnector();

  const { userType } = useContext(RoleContext);
  if (userType && !['BOOTSTRAP', 'BOOTSTRAP_NEXT'].includes(userType)) {
    return null;
  }
  if (userType === 'BOOTSTRAP_NEXT') paymentRequired = false;
  return (
    <>
      <div className="divider-horizontal"></div>
      <div className="quickbooks-container">
        <div className="quickbooks-content">
          <div className="quickbooks-image">
            <img alt="" src="images/quickbooks.png" />
          </div>
          <div className="quickbooks-text-container">
            <p className="quickbooks-title">
              Replace manual work with our Quickbooks integration.
            </p>
            <p className="quickbooks-subtitle">
              {userType === 'BOOTSTRAP'
                ? 'Get Cash Flow and Balance Sheet Reports as a bonus for $39/mo'
                : 'Get Cash Flow and Balance Sheet Reports as a bonus'}
            </p>
          </div>
        </div>
        <button onClick={() => getLinkAndRedirect(paymentRequired)}>
          <div className="quickbooks-connect">
            <span>Connect</span>
            <img
              style={{ width: '20px', height: '20px' }}
              alt=""
              src="images/quickbooks.png"
            />
          </div>
        </button>
      </div>
    </>
  );
};
