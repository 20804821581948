import { ChangeEvent } from 'react';
import './default-input.style.css';

interface DefaultInputProps {
  value: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  style?: any;
}

export const DefaultInput = (props: DefaultInputProps) => {
  return (
    <input
      className={'base-input'}
      value={props.value}
      onChange={props.handleChange}
      placeholder={props.placeholder}
      type="text"
      style={props.style}
    />
  );
};
