import { useContext, useState } from 'react';
import { ManagerHttpDashboardService } from '../../tools/manager-api/manager-http-dashboard';
import { useParams } from 'react-router-dom';
import { CommentResponse } from '../../tools/api-services/types/Comment';
import { DateTime } from 'luxon';
import { useUser } from '../../context/user-details.context';
import { trackAddUpdate } from '../../tools/analytics-events';
import { ToasterContext } from '../../context/toaster.context';

const changeCommentsDate = (comments: CommentResponse[]) => {
  return comments.map((comment) => {
    return {
      ...comment,
      createdAt: DateTime.fromISO(comment.createdAt).toFormat(
        'ccc, LLL dd, yyyy'
      ),
    };
  });
};

export const useManagerComments = () => {
  const { companyId } = useParams();
  const [comments, setComments] = useState<CommentResponse[]>([]);
  const { userDetails } = useUser();

  const { updateToaster } = useContext(ToasterContext);

  const fetchComments = async (limit = 3) => {
    const { data } = await ManagerHttpDashboardService.comments(
      parseInt(companyId as string),
      limit
    );

    setComments(changeCommentsDate(data.result));
  };

  const createComment = async (text: string, images: File[]) => {
    try {
      const formData = new FormData();
      formData.append('text', text);
      images.forEach((image, index) => {
        formData.append(`images`, image, image.name);
      });

      await ManagerHttpDashboardService.createComment(
        parseInt(companyId as string),
        formData
      );
      trackAddUpdate({
        companyName: userDetails.companyName,
        userName: userDetails.userName,
        userType: userDetails.userType,
      });
      await fetchComments(20);
    } catch (error: any) {
      updateToaster({
        content: error.response.data.message,
        isError: true,
      });
    }
  };

  const deleteComment = async (commentId: number) => {
    await ManagerHttpDashboardService.deleteComment(
      parseInt(companyId as string),
      commentId
    );
    await fetchComments(20);
  };

  return {
    comments,
    createComment,
    fetchComments,
    deleteComment,
  };
};
