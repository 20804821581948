import { AxiosData } from './interfaces';
import { instanceWithToken } from '../api';
import { SubscriptionAndCardDetails } from './types/payment';

export class PaymentHttpService {
  public static async getPaymentStatus(): Promise<
    AxiosData<{ paymentFinished: boolean }>
  > {
    return instanceWithToken.get('/api/payment/status');
  }

  public static async getPaymentLink(
    qbRedirect = false
  ): Promise<AxiosData<{ link: string }>> {
    return instanceWithToken.get(
      `/api/payment/payment-link?${qbRedirect ? 'qb_redirect=true' : ''}}`
    );
  }

  public static async getStripePayments(): Promise<
    AxiosData<
      {
        id: string;
        amount: number;
        date: number;
      }[]
    >
  > {
    return instanceWithToken.get('/api/payment/payment-history');
  }

  public static async getStripePaymentDetails(): Promise<
    AxiosData<SubscriptionAndCardDetails>
  > {
    return instanceWithToken.get('/api/payment/payment-details');
  }

  public static async cancelSubscription(): Promise<AxiosData<any>> {
    return instanceWithToken.post('/api/payment/cancel-subscription');
  }

  public static async renewSubscription(): Promise<AxiosData<any>> {
    return instanceWithToken.post('/api/payment/renew-subscription');
  }

  public static async updateCard(): Promise<AxiosData<any>> {
    return instanceWithToken.get('/api/payment/update-card');
  }
}
