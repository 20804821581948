import { ModalComponent } from '../../shared-components/modal/modal-component';
import { UpdatedButtonComponent } from '../../shared-components/button/updated.button.component';

interface ManagerChartDeleteModalComponentProps {
  onSubmit: () => Promise<void> | void | null;
  onCancel: () => void;
  isOpen: boolean;
}

export const ManagerChartDeleteModalComponent: React.FC<
  ManagerChartDeleteModalComponentProps
> = (props) => {
  if (!props.isOpen) return null;
  const submit = () => {
    props.onSubmit();
  };

  return (
    <ModalComponent
      name={'Chart Deleting'}
      isOpen={props.isOpen}
      onClose={props.onCancel}
    >
      <div className="delete-chart-form">
        <div style={{ marginBottom: '16px', marginTop: '16px' }}>
          Are you sure you want to delete this chart?
        </div>
        <UpdatedButtonComponent type={'warning'} onClick={submit}>
          Delete
        </UpdatedButtonComponent>
      </div>
    </ModalComponent>
  );
};
