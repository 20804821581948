import { useNavigate, useParams } from 'react-router-dom';
import {
  ClientOnboardingContents,
  ManagerOnboardingContents,
} from './onboarding-content';
import { BasePage } from '../base';
import { Container } from '../../shared-components/container';
import { Button } from '../../shared-components/button';
import Footer from '../../shared-components/footer/footer.component';
import { DashboardHttpService } from '../../tools/api-services/dashboardHttpService';
import { useContext, useEffect, useState } from 'react';
import { ManagerHttpDashboardService } from '../../tools/manager-api/manager-http-dashboard';
import './styles.css';
import { RoleContext } from '../../context/role.context';

export const OnboardingPage = () => {
  const { stepNumber } = useParams();
  const currentStep = parseInt(stepNumber || '1', 10);
  const { role } = useContext(RoleContext);
  const navigate = useNavigate();
  if (!stepNumber) {
    navigate('/');
  }
  const [onboardingContent, setOnboardingContent] = useState<
    | {
        stepNumber: number;
        title: string;
        subtitle: string;
        image: string;
      }
    | undefined
    | null
  >(null);
  const handleNextStep = async () => {
    const nextStep = currentStep + 1;
    if (nextStep > 3) {
      if (role === 'MANAGER') {
        await ManagerHttpDashboardService.markOnboardingCompleted();
        navigate('/manager/home');
      } else {
        await DashboardHttpService.markOnboardingCompleted();
        navigate('/connect-sources');
      }
    } else {
      navigate(`/onboarding/${nextStep}`);
    }
  };
  useEffect(() => {
    const onboardingContent =
      role === 'CLIENT'
        ? ClientOnboardingContents.find(
            (onboardingContent) => onboardingContent.stepNumber === currentStep
          )
        : ManagerOnboardingContents.find(
            (onboardingContent) => onboardingContent.stepNumber === currentStep
          );
    setOnboardingContent(onboardingContent);
  }, [currentStep, role]);

  return (
    <BasePage publicPage={true}>
      <Container extended={true}>
        <div className="onboarding-header">
          <div className="onboarding-step-indicator">{`Welcome Onboard (${currentStep}/3)`}</div>
          <div className="onboarding-next-button">
            <Button
              mini
              onClick={handleNextStep}
              active={true}
              children={'Next'}
              style={{
                width: '69px',
                height: '36px',
              }}
              type="submit"
            />
          </div>
        </div>
        <div className="onboarding-image-container">
          <img
            src={onboardingContent?.image}
            alt="onboarding"
            className="onboarding-image"
          />
        </div>
        {onboardingContent?.title && (
          <div className="onboarding-content">
            <p>{onboardingContent?.title}</p>
            <p>{onboardingContent?.subtitle}</p>
          </div>
        )}
      </Container>
      <Footer />
    </BasePage>
  );
};
