import { ChangeEvent, useState } from 'react';
import { DefaultInput } from '../../shared-components/default-input/default-input';
import { UpdatedButtonComponent } from '../../shared-components/button/updated.button.component';
import { ModalComponent } from '../../shared-components/modal/modal-component';

interface ManagerChartModalComponentProps {
  onSubmit: (r: any) => Promise<void> | void | null;
  onCancel: () => void;
  isOpen: boolean;
}

export const ManagerChartModalComponent = ({
  isOpen,
  onCancel,
  onSubmit,
}: ManagerChartModalComponentProps) => {
  const [chartName, setChartName] = useState('');

  if (!isOpen) return null;

  const handleChartChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setChartName(e.target.value);
  };

  const submitCreateChart = async () => {
    if (chartName.length) {
      await onSubmit(chartName);
    }
  };

  return (
    <ModalComponent name={'Add chart'} isOpen={isOpen} onClose={onCancel}>
      <div className="create-chart-form">
        <DefaultInput
          value={chartName}
          handleChange={handleChartChange}
          placeholder={'Link'}
          style={{ marginBottom: '16px', marginTop: '16px' }}
        />
        <UpdatedButtonComponent type={'default'} onClick={submitCreateChart}>
          Add
        </UpdatedButtonComponent>
      </div>
    </ModalComponent>
  );
};
