export const formatDateTime = (inputDateTime: string | Date) => {
  const date =
    typeof inputDateTime === 'string' ? new Date(inputDateTime) : inputDateTime;

  const formattedDate = date.toLocaleString('en-US', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  return formattedDate;
};
