import { useFormik } from 'formik';
import { DashboardHttpService } from '../../../tools/api-services/dashboardHttpService';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ToasterContext } from '../../../context/toaster.context';

const schema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  bugDescription: Yup.string()
    .min(3, 'Description should be at least 3 symbols length')
    .required('Description is required'),
});

export const useReportIssue = () => {
  const { updateToaster } = useContext(ToasterContext);
  const navigate = useNavigate();

  const sendReportIssue = async (data: {
    email: string;
    bugDescription: string;
  }) => {
    await DashboardHttpService.reportIssue(data);
  };

  const reportIssueForm = useFormik({
    initialValues: {
      email: '',
      bugDescription: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      await sendReportIssue(values);
      updateToaster({
        content:
          'Thank you for your feedback. We will respond to your email within 48 hours.',
      });
      navigate('/login');
    },
  });

  return {
    reportIssueForm,
    errors: reportIssueForm.errors,
    touched: reportIssueForm.touched,
  };
};
