import { Preloader } from '../../../shared-components/preloader';
import React from 'react';
import { BasePage } from '../../base';
import { Container } from '../../../shared-components/container';
import DataTable from 'react-data-table-component';
import { useTransactions } from './transactions.hook';

export const TransactionsPage = () => {
  const { transactions, isTransactionsLoading } = useTransactions();

  const cols = [
    {
      name: 'Date',
      selector: (row: any) => row.date,
      width: '120px',
    },
    {
      name: 'Price',
      selector: (row: any) => row.price,
      width: '120px',
    },
    {
      name: 'Order number',
      selector: (row: any) => row.orderNumber,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        color: '#000000',
        fontSize: '15px',
        fontFamily: 'Karelia',
        paddingLeft: '0',
      },
    },
    cells: {
      style: {
        fontSize: '15px',
        fontFamily: 'Karelia',
        paddingLeft: '0',
      },
    },
  };

  return (
    <>
      <Preloader isLoading={isTransactionsLoading} />
      <BasePage>
        <Container extended={true}>
          <p className="payment-details__h1" style={{ marginBottom: '16px' }}>
            Your payment details
          </p>
          <DataTable
            columns={cols}
            data={transactions}
            customStyles={customStyles}
          />
        </Container>
      </BasePage>
    </>
  );
};
