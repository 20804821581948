import { createContext, useContext, useState, useEffect } from 'react';

type UserDetails = {
  userName: string;
  companyName: string;
  userType: string;
};

type UserContextType = {
  userDetails: UserDetails;
  setUserDetails: (details: UserDetails) => void;
};

export const UserContext = createContext<UserContextType>({
  userDetails: {
    userName: '',
    companyName: '',
    userType: '',
  },
  setUserDetails: (details) => {
    console.warn('setUserDetails is not implemented');
  },
});

export const useUserDetails = () => {
  const [userDetails, setUserDetailsState] = useState<UserDetails>(() => {
    const storedUserDetails = localStorage.getItem('userDetails');
    return storedUserDetails
      ? JSON.parse(storedUserDetails)
      : {
          userName: '',
          companyName: '',
          userType: '',
        };
  });

  useEffect(() => {
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
  }, [userDetails]);

  const setUserDetails = (details: UserDetails) => {
    setUserDetailsState(details);
  };

  return {
    userDetails,
    setUserDetails,
  };
};

export const useUser = () => {
  return useContext(UserContext);
};
