import { ChartResponse } from '../../tools/api-services/types/chart.response';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ManagerHttpDashboardService } from '../../tools/manager-api/manager-http-dashboard';
import { useUser } from '../../context/user-details.context';
import {
  trackAddDashboard,
  trackDeleteDashboard,
} from '../../tools/analytics-events';
import { instanceWithToken } from '../../tools/api';
import { RoleContext } from '../../context/role.context';

export const useManagerCharts = (type: string | null) => {
  const { userType } = useContext(RoleContext);
  const { companyId } = useParams();
  const [charts, setCharts] = useState<ChartResponse | null>(null);
  const { userDetails } = useUser();

  const getManagerCharts = async () => {
    if (!type) return;
    const {
      data: { chart },
    } = await instanceWithToken.get(
      `manager/company/${companyId}/charts/${type}`
    );
    return chart;
  };

  const getClientCharts = async () => {
    if (!type) return;
    const {
      data: { chart },
    } = await instanceWithToken.get(`api/me/charts/${type}`);

    return chart;
  };

  const getClientTypedCharts = async () => {
    const chartsData =
      userType === 'MANAGER'
        ? await getManagerCharts()
        : await getClientCharts();
    setCharts(chartsData);
  };

  const deleteChart = async (chartId: number) => {
    await ManagerHttpDashboardService.deleteChart(
      parseInt(companyId as string),
      chartId
    );

    trackDeleteDashboard({
      companyName: userDetails.companyName,
      userName: userDetails.userName,
      userType: userDetails.userType,
    });
    await getClientTypedCharts();
  };

  const createChart = async (data: { source: string }) => {
    if (!type) return;
    await ManagerHttpDashboardService.createChart(
      parseInt(companyId as string),
      {
        ...data,
        type,
      }
    );
    await getClientTypedCharts();
    trackAddDashboard({
      companyName: userDetails.companyName,
      userName: userDetails.userName,
      userType: userDetails.userType,
    });
  };

  useEffect(() => {
    getClientTypedCharts();
  }, [type, companyId]);

  return {
    charts,
    deleteChart,
    createChart,
  };
};
