import { useState, useEffect } from 'react';
import './date-range-modal.style.css';
import { SquareX } from 'lucide-react';
import { UpdatedButtonComponent } from '../../../../../shared-components/button/updated.button.component';

interface DateRangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (dateRange: { startDate: Date; endDate: Date }) => void;
}

const DateRangeModal = ({
  isOpen,
  onClose,
  onConfirm,
}: DateRangeModalProps) => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [maxEndDate, setMaxEndDate] = useState<string>('');
  const [minStartDate, setMinStartDate] = useState<string>('');

  useEffect(() => {
    const today = new Date();
    const lastYearToday = new Date(
      today.getFullYear() - 1,
      today.getMonth(),
      today.getDate()
    );
    setMinStartDate(lastYearToday.toISOString().split('T')[0]);
  }, []);

  useEffect(() => {
    if (startDate && endDate && new Date(startDate) <= new Date(endDate)) {
      setError('');
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate) {
      const maxDate = new Date(startDate);
      maxDate.setFullYear(maxDate.getFullYear() + 1);
      setMaxEndDate(maxDate.toISOString().split('T')[0]);
    } else {
      setMaxEndDate('');
    }
  }, [startDate]);

  const handleConfirm = () => {
    if (!startDate || !endDate) {
      setError('Please select both start and end dates.');
      return;
    }

    if (new Date(startDate) > new Date(endDate)) {
      setError('End date cannot be earlier than start date.');
      return;
    }

    const start = new Date(startDate);
    start.setHours(start.getHours() + 12);

    const end = new Date(endDate);
    end.setHours(end.getHours() + 12);

    onConfirm({ startDate: start, endDate: end });
    setStartDate('');
    setEndDate('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>Select Range</h3>
          <SquareX
            className="close-cross-button"
            onClick={onClose}
            style={{ width: '32px', height: '32px' }}
            strokeWidth={'1'}
          />
        </div>
        <div className="date-selectors">
          <div className="date-picker">
            <label htmlFor="start-date">Start Date</label>
            <input
              id="start-date"
              type="date"
              value={startDate}
              min={minStartDate}
              max={new Date().toISOString().split('T')[0]}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="date-picker">
            <label htmlFor="end-date">End Date</label>
            <input
              id="end-date"
              type="date"
              value={endDate}
              min={startDate}
              max={maxEndDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        {error && <div className="error-message">{error}</div>}
        <div className="modal-actions">
          <UpdatedButtonComponent onClick={handleConfirm} type="default">
            Confirm
          </UpdatedButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default DateRangeModal;
