import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './app/app';
import { sentryInit } from './tools/sentry';
import { initializeGoogleAnalytics } from './tools/google-analytics';
import { hubspotEnvironmentMap } from './app/tools/hubspot';
import { Helmet, HelmetProvider } from 'react-helmet-async';

(async () => {
  try {
    initializeGoogleAnalytics();
  } catch (e) {
    console.log(e);
  } finally {
    sentryInit();

    const ENV: string = import.meta.env.VITE_APP_ENVIRONMENT || 'development';

    const hubspotEnvironment = hubspotEnvironmentMap.get(ENV);

    let hubspotPortalId;

    if (hubspotEnvironment) {
      hubspotPortalId = hubspotEnvironment.portalId;
    } else {
      hubspotPortalId = '43550426';
    }

    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
    );

    root.render(
      // <StrictMode>
      <HelmetProvider>
        <Helmet>
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src={`//js.hs-scripts.com/${hubspotPortalId}.js`}
          ></script>
        </Helmet>
        <App />
      </HelmetProvider>
      // </StrictMode>
    );
  }
})();
