import { AxiosData } from './interfaces';
import { instanceWithToken } from '../api';
import { TutorialVideo } from './types/tutorial-video';
import { OnboardingStep } from './types/onboarding-step';
import { CompanyDocument } from './types/document';
import { ClientInfo, ManagerInfo } from './types/client';
import { CommentPaginatedResponse } from './types/Comment';
import { CompanyDataSourcesResponse } from '../manager-api/types/company-data-source';
import { ChartPaginatedResponse } from './types/chart.response';
import { ClientManagerResponse } from './types/client-manager.response';
import { MetricsDataFilterDto } from '../manager-api/types/filter.dto';

export class DashboardHttpService {
  public static me(): Promise<AxiosData<ClientInfo | ManagerInfo>> {
    return instanceWithToken.get('/api/me');
  }

  public static async documents(): Promise<AxiosData<CompanyDocument[]>> {
    return instanceWithToken.get('/api/me/documents');
  }

  public static getLatestOnboardingStep(): Promise<AxiosData<OnboardingStep>> {
    return instanceWithToken.get(`/api/onboarding-steps/latest`);
  }

  public static completeOnboardingStep(
    stepNumber: number
  ): Promise<AxiosData<void>> {
    return instanceWithToken.post(`/api/onboarding-steps`, { stepNumber });
  }

  public static isAuthenticated(): Promise<AxiosData<boolean>> {
    return instanceWithToken.get('/api/me/authenticated');
  }

  public static isOnboardingCompleted(): Promise<
    AxiosData<{ completed: boolean }>
  > {
    return instanceWithToken.get('/api/me/is-onboarding-completed');
  }

  public static contactUs(data: {
    contactDescription: string;
  }): Promise<AxiosData<void>> {
    return instanceWithToken.post('/api/contact-us', data);
  }

  public static bugReport(data: {
    bugDescription: string;
    bugType: string;
  }): Promise<AxiosData<void>> {
    return instanceWithToken.post('/api/bug-report', data);
  }

  public static reportIssue(data: {
    bugDescription: string;
    email: string;
  }): Promise<AxiosData<void>> {
    return instanceWithToken.post('/api/report-issue', data);
  }

  public static getDocumentsLoadingStatus(): Promise<
    AxiosData<{
      documentsLoading: boolean;
    }>
  > {
    return instanceWithToken.get('/api/me/documents/loading-status');
  }

  public static createQuickbooksConnectToken(): Promise<AxiosData<void>> {
    return instanceWithToken.post('/api/me/quickbooks-connect-token');
  }

  public static getQuickbooksConnectToken(): Promise<
    AxiosData<{ isQuickbooksConnectTokenActive: boolean }>
  > {
    return instanceWithToken.get('/api/me/quickbooks-connect-token');
  }

  public static getTutorialVideo(
    stepNumber: number,
    businessModel: string
  ): Promise<AxiosData<TutorialVideo>> {
    return instanceWithToken.get(
      `/api/tutorial-videos/${stepNumber}?businessModel=${businessModel}`
    );
  }

  public static getTutorialVideos(): Promise<AxiosData<TutorialVideo[]>> {
    return instanceWithToken.get('/api/tutorial-videos');
  }

  public static comments(limit: number): Promise<CommentPaginatedResponse> {
    return instanceWithToken.get('/api/me/comments?limit=' + limit);
  }

  public static companyDataSources(): Promise<CompanyDataSourcesResponse> {
    return instanceWithToken.get(`api/me/data-sources`);
  }

  public static markOnboardingCompleted(): Promise<void> {
    return instanceWithToken.post('/api/me/onboarding/complete');
  }

  public static getCharts(): Promise<ChartPaginatedResponse> {
    return instanceWithToken.get('/api/me/charts');
  }

  public static getManager(): Promise<AxiosData<ClientManagerResponse>> {
    return instanceWithToken.get('/api/me/manager');
  }

  public static nextRegistrationCompleted(): Promise<
    AxiosData<{ redirectUrl: string; customToken: string }>
  > {
    return instanceWithToken.get('/api/auth/next');
  }

  public static getCompanies(): Promise<AxiosData<{ result: string[] }>> {
    return instanceWithToken.get('/api/me/companies');
  }

  public static submitNext(data: { businessModel: string }): Promise<
    AxiosData<{
      success: boolean;
    }>
  > {
    return instanceWithToken.post('/api/me/next/submit', data);
  }

  public static getMetricData(
    metricsDataFilterDto: MetricsDataFilterDto
  ): Promise<
    AxiosData<{
      financialData: {
        data: any[];
        total: number;
      };
      salesData: {
        data: any[];
        total: number;
      };
    }>
  > {
    return instanceWithToken.post(`api/dashboard/metrics/drill-down`, {
      ...metricsDataFilterDto,
    });
  }

  public static exportMetricsData(
    metricsDataFilterDto: MetricsDataFilterDto
  ): Promise<any> {
    return instanceWithToken.post(`api/dashboard/metrics/export`, {
      ...metricsDataFilterDto,
    });
  }
}
