import { DefaultProps } from '../types/default';
import './style.css';

export interface RadioInputProps extends DefaultProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange: any;
  value: string;
  name: string;
  checked?: boolean;
}

export const RadioInput = (props: RadioInputProps) => {
  return (
    <label>
      <input
        id={props.name}
        type="radio"
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
        checked={props.checked}
      />
      <span></span>
      {props.value}
    </label>
  );
};
