import { DashboardHttpService } from '../../tools/api-services/dashboardHttpService';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '../../shared-components/container';
import { Button } from '../../shared-components/button';
import { RadioInput } from '../../shared-components/radio-input/radio.component';
import { CheckboxInput } from '../../shared-components/checkbox-input/checkbox.component';
import { BasePage } from '../base';
import { useBusinessModel } from '../stepper/business-model/business-model.hook';

export const CompleteNextRegistration = () => {
  const { form, businessModels, experiences, needs } = useBusinessModel(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fnc = async () => {
      const res = await DashboardHttpService.getCompanies();
      if (res.data.result.length) {
        navigate('/');
        return;
      }
    };

    fnc();
  }, []);

  return (
    <BasePage publicPage={true}>
      <Container extended={true}>
        <div className="business-model-header">
          <span style={{ fontWeight: 'bold' }}>
            Get Started with Fuel's Financial Dashboard, Cap Table Modeler and
            Runway Calculator
          </span>
          <Button
            mini
            onClick={form.handleSubmit}
            active={true}
            children={'Next'}
            style={{
              width: '69px',
              height: '36px',
            }}
            type="submit"
          />
        </div>

        <form onSubmit={form.handleSubmit}>
          <div className="form-container">
            <label className="form-label">Your business model:</label>
            <div className="radio-grid">
              {businessModels.map((option, idx) => (
                <RadioInput
                  value={option}
                  key={idx}
                  handleChange={form.handleChange}
                  checked={form.values.businessModel === option}
                  name="businessModel"
                />
              ))}
            </div>
            {form.errors.businessModel && form.touched.businessModel && (
              <div className="error-message">{form.errors.businessModel}</div>
            )}
          </div>
          <div className="divider-horizontal"></div>
          <div className="form-container">
            <label className="form-label">Your experience:</label>
            <div className="radio-grid">
              {experiences.map((exp, idx) => (
                <RadioInput
                  value={exp}
                  key={idx}
                  handleChange={form.handleChange}
                  checked={form.values.experience === exp}
                  name="experience"
                />
              ))}
            </div>
            {form.errors.experience && form.touched.experience && (
              <div className="error-message">{form.errors.experience}</div>
            )}
          </div>
          <div className="divider-horizontal"></div>
          <div className="form-container">
            <label className="form-label">Your needs:</label>
            <div className="radio-grid">
              {needs.map((need, idx) => (
                <CheckboxInput
                  controlName="needs"
                  value={need}
                  key={idx}
                  handleChange={form.handleChange}
                  checked={form.values.needs.includes(need)}
                />
              ))}
            </div>
            {form.errors.needs && form.touched.needs && (
              <div className="error-message">{form.errors.needs}</div>
            )}
          </div>
        </form>
      </Container>
      <div>
        <p style={{ textAlign: 'center' }}>
          Already use Fuel? <a href="/login">Login</a>
        </p>
      </div>
    </BasePage>
  );
};
