import { CashflowDashboardData } from './cashflow-dashboard-data.interface';
import { SnapshotDashboardData } from './snapshot-dashboard-data.interface';

export type CardType =
  | 'HistogramCard'
  | 'SummaryCard'
  | 'RaceChart'
  | 'StackedBarCard'
  | 'HorizontalStackedBarCard'
  | 'TableCard'
  | 'PieChartCard';

export interface HistogramDataset {
  data: any[];
  keys: string[];
  indexBy: string;
  extraLineData?: any;
  date?: string;
  xAxis?: boolean;
}

export interface SummaryDataset {
  value: number | string;
  prevMonthData: string | number;
  year: number;
  month: number;
  signType: 'percent' | 'value';
}

export interface BalanceSummaryDataset {
  value: number | string;
}

export interface ComparisonSummaryDataset {
  firstValue: number | string;
  secondValue: string | number;
  year: number;
  month: number;
}

export type RaceChartDataset = HistogramDataset;

export interface PieChartDataset {
  name1: string;
  name2: string;
  value1: number | null;
  value2: number | null;
}

export interface OpexCogsTableDataset {
  rows: {
    category: string;
    current: { type: string; value: number };
    previous: { type: string; value: number };
    change: number;
  }[];
  totalCurrentMonth: number;
  totalPreviousMonth: number;
  currentHeader: string;
  previousHeader: string;
}

export interface OutflowCategoriesTableDataset {
  rows: {
    category: string;
    budget: { type: string; value: number };
    actual: { type: string; value: number };
    deviation: number;
  }[];
  totalBudget: number;
  totalActual: number;
  totalDeviation: number;
}

export interface CategoriesBalanceTableDataset {
  rows: {
    category: string;
    value: number;
  }[];
  date?: string;
}

export interface TransformedDataItem {
  date: string;
  [key: string]: string | number;
}

export interface DynamicTableDataset {
  columns: string[];
  data: {
    [key: string]: string | number;
  }[];
}

export enum SnapshotDashboardDataType {
  INFLOW_OUTFLOW = 'INFLOW_OUTFLOW',
  LTV_SUMMARY = 'LTV_SUMMARY',
  CAC_SUMMARY = 'CAC_SUMMARY',
  CHURN_RATE_SUMMARY = 'CHURN_RATE_SUMMARY',
  LTV_CAC_SUMMARY = 'LTV_CAC_SUMMARY',
  NET_BURN_RATE_SUMMARY = 'NET_BURN_RATE_SUMMARY',
  RUNWAY = 'RUNWAY',
  NET_BURN_RATE = 'NET_BURN_RATE',
  EXPENSES_VS_BUDGET = 'EXPENSES_VS_BUDGET',
  AVB_OUTFLOW = 'AVB_OUTFLOW',
  AVB_INFLOW = 'AVB_INFLOW',
  REVENUE = 'REVENUE',
  REVENUE_GROWTH = 'REVENUE_GROWTH',
  PL = 'PL',
  COGS_OPEX = 'COGS_OPEX',
  CUSTOMERS_SUMMARY = 'CUSTOMERS_SUMMARY',
  ARPU_SUMMARY = 'ARPU_SUMMARY',
  OPEX_TABLE = 'OPEX_TABLE',
  COGS_TABLE = 'COGS_TABLE',
  MARGIN = 'MARGIN',
  NET_INCOME_PROFITABILITY = 'NET_INCOME_PROFITABILITY',
  PL_STRUCTURE_TABLE = 'PL_STRUCTURE_TABLE',
  WATERFALL_1 = 'WATERFALL_1',
  TEST_COMBINED_V1 = 'TEST_COMBINED_V1',
  TEST_COMBINED_V2 = 'TEST_COMBINED_V2',
}

export enum CashflowDashboardDataType {
  INFLOW_OUTFLOW = 'INFLOW_OUTFLOW',
  CASH_BALANCE = 'CASH_BALANCE',
  RUNWAY = 'RUNWAY',
  ACCOUNTS = 'ACCOUNTS',
  FREE_CASH_FLOW = 'FREE_CASH_FLOW',
  BURN_RATE = 'BURN_RATE',
  CASH_END_MONTH = 'CASH_END_MONTH',
  FUNDS_NEEDED = 'FUNDS_NEEDED',
  OPERATING_INFLOW_ACTUAL = 'OPERATING_INFLOW_ACTUAL',
  OPERATING_INFLOW_REVENUE = 'OPERATING_INFLOW_REVENUE',
  BUDGET_ACTUAL_INFLOW = 'BUDGET_ACTUAL_INFLOW',
  ACCOUNT_RECEIVABLES = 'ACCOUNT_RECEIVABLES',
  TOP_DEBTORS = 'TOP_DEBTORS',
  OPERATING_OUTFLOW_ACTUAL = 'OPERATING_OUTFLOW_ACTUAL',
  BUDGET_ACTUAL_OUTFLOW = 'BUDGET_ACTUAL_OUTFLOW',
  BUDGET_ACTUAL_CATEGORY = 'BUDGET_ACTUAL_CATEGORY',
  ACCOUNT_PAYABLES = 'ACCOUNT_PAYABLES',
  WORKING_CAPITAL = 'WORKING_CAPITAL',
  FINANCIAL_INFLOW_OUTFLOW = 'FINANCIAL_INFLOW_OUTFLOW',
  INVESTING_INFLOW_OUTFLOW = 'INVESTING_INFLOW_OUTFLOW',
  FINANCING_ACTIVITIES = 'FINANCING_ACTIVITIES',
  INVESTING_ACTIVITIES = 'INVESTING_ACTIVITIES',
}

export enum PlDashboardDataType {
  PROJECTED_ACTUAL_REVENUE = 'PROJECTED_ACTUAL_REVENUE',
  PROJECTED_ACTUAL_NET_PROFIT = 'PROJECTED_ACTUAL_NET_PROFIT',
  PL_WATERFALL = 'PL_WATERFALL',
  // PL_STRUCTURE = 'PL_STRUCTURE',
  PROJECTED_ACTUAL_REVENUE_STREAMS_BREAKDOWN = 'PROJECTED_ACTUAL_REVENUE_STREAMS_BREAKDOWN',
  PROJECTED_ACTUAL_REVENUE_GROWTH_PERCENT = 'PROJECTED_ACTUAL_REVENUE_GROWTH_PERCENT',
  PROJECTED_ACTUAL_REVENUE_GROWTH_SUM = 'PROJECTED_ACTUAL_REVENUE_GROWTH_SUM',
  PROJECTED_ACTUAL_GROSS_PROFIT_MARGIN = 'PROJECTED_ACTUAL_GROSS_PROFIT_MARGIN',
  PROJECTED_ACTUAL_COGS = 'PROJECTED_ACTUAL_COGS',
  // PL_COGS_TABLE = 'PL_COGS_TABLE',
  REVENUE_COGS_GROWTH = 'REVENUE_COGS_GROWTH',
  // PROJECTED_ACTUAL_OPEX_BREAKDOWN = 'PROJECTED_ACTUAL_OPEX_BREAKDOWN',
  OPEX_SUMMARY = 'OPEX_SUMMARY',
  REVENUE_OPEX_GROWTH = 'REVENUE_OPEX_GROWTH',
  // PROJECTED_ACTUAL_TEAM_BREAKDOWN = 'PROJECTED_ACTUAL_TEAM_BREAKDOWN',
  // PROJECTED_ACTUAL_TEAM_BREAKDOWN_TABLE = 'PROJECTED_ACTUAL_TEAM_BREAKDOWN_TABLE',
  EBITDA = 'EBITDA',
  NET_PROFIT_MARGIN = 'NET_PROFIT_MARGIN',
}
export type DashboardDataFunction = (
  args: SnapshotDashboardData | CashflowDashboardData
) =>
  | HistogramDataset
  | SummaryDataset
  | RaceChartDataset
  | OpexCogsTableDataset[]
  | PieChartDataset;
