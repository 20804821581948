import { useEffect, useState } from 'react';
import { DashboardHttpService } from '../../tools/api-services/dashboardHttpService';
import { CommentResponse } from '../../tools/api-services/types/Comment';
import { DateTime } from 'luxon';

const changeCommentsDate = (comments: CommentResponse[]) => {
  return comments.map((comment) => {
    return {
      ...comment,
      createdAt: DateTime.fromISO(comment.createdAt).toFormat(
        'ccc, LLL dd, yyyy'
      ),
    };
  });
};

export const useClientComments = () => {
  const [comments, setComments] = useState<CommentResponse[]>([]);

  const fetchComments = async (limit = 3) => {
    const { data } = await DashboardHttpService.comments(limit);
    setComments(changeCommentsDate(data.result));
  };

  useEffect(() => {
    fetchComments(3);
  }, []);

  return {
    comments,
    fetchComments,
  };
};
