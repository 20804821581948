import { useContext, useEffect, useState } from 'react';
import { RoleContext } from '../../../../../context/role.context';
import { useLocation, useParams } from 'react-router-dom';
import { Company } from '../../../../../tools/manager-api/types/company';
import { ManagerCompaniesHttpService } from '../../../../../tools/manager-api/http-companies';
import './manager-company-selector.style.css';
import classNames from 'classnames';
import { COLLAPSED_LOGO } from '../../sidebar-svg';

const getImage = (company: Company) => {
  if (company.imageUrl) {
    return (
      <img
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '50%',
        }}
        src={company.imageUrl}
      />
    );
  }

  return company.name[0];
};

const CompanySelectorItem = ({
  company,
  onClick,
  active,
}: {
  company: Company;
  onClick: () => void;
  logo?: string;
  active: boolean;
}) => {
  const itemClassNames = classNames('manager-company-dropdown-item', {
    active: active,
  });

  return (
    <div onClick={onClick} className={itemClassNames}>
      <div className={'manager-company-dropdown-item-icon'}>
        {getImage(company)}
      </div>
      <span className="manager-company-dropdown-item-text">{company.name}</span>
    </div>
  );
};
export const CompanySelector = () => {
  const { role } = useContext(RoleContext);

  const location = useLocation();
  const path = location.pathname;

  const { companyId } = useParams();
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  const [companies, setCompanies] = useState<Company[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const getAvailableCompanies = async () => {
    const response = await ManagerCompaniesHttpService.availableCompanies();
    if (!response.data.result.length) {
      return;
    }

    if (companyId) {
      const selected = response.data.result.find(
        (company: Company) => company.id === Number(companyId)
      );
      if (selected) {
        setSelectedCompany(selected);
      }
    }
    return setCompanies(response.data.result);
  };

  const navigateToCompany = (id: number) => {
    const splitPath = path.split('/');
    const companiesIndex = splitPath.findIndex(
      (segment) => segment === 'companies'
    );

    if (
      companiesIndex !== -1 &&
      !isNaN(Number(splitPath[companiesIndex + 1]))
    ) {
      splitPath[companiesIndex + 1] = id.toString();
    } else {
      splitPath.push(id.toString());
    }

    window.location.href = splitPath.join('/');
  };

  useEffect(() => {
    getAvailableCompanies();
  }, []);

  if (!role || role === 'CLIENT') return null;

  const menuClassNames = classNames('manager-company-dropdown-menu', {
    open: isDropdownOpen,
  });
  if (!selectedCompany)
    return (
      <div className="company-selector-loader">
        <div className="loading-circle"></div>
      </div>
    );
  return (
    <>
      {COLLAPSED_LOGO}
      <div className="manager-company-dropdown-container">
        <div
          onClick={() => {
            setIsDropdownOpen(!isDropdownOpen);
          }}
          className="manager-company-dropdown"
        >
          <div className="manager-company-dropdown-toggle">
            {getImage(selectedCompany)}
          </div>
          <svg
            className={`dropdown-toggle-arrow ${isDropdownOpen ? 'open' : ''}`}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 15.4L6 9.4L7.4 8L12 12.6L16.6 8L18 9.4L12 15.4Z"
              fill="#5F6368"
            />
          </svg>
          <div className={menuClassNames}>
            {companies.map((company: Company) => (
              <CompanySelectorItem
                onClick={() => navigateToCompany(company.id)}
                company={company}
                key={company.id}
                active={companyId === company.id.toString()}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
