import './dashboard.style.css';
import { Updates } from './components/updates/updates.components';
import { DashboardCardHolder } from './components/dashboard-cards/dashboard-card-holder';
import { useSnapshotDashboard } from '../../hooks/client/dashboard/hooks/snapshot-dashboard.hook';
import { useCallback, useMemo, useState } from 'react';
import PeriodSelector, {
  getDateRange,
} from './components/period-selector/period-selector.component';
import { SnapshotDiagramHeader } from './components/headers/snapshot-header.component';
import { CashflowDiagramHeader } from './components/headers/cashflow-header.component';
import { useCashflowDashboard } from '../../hooks/client/dashboard/hooks/cashflow-dashboard.hook';
import { usePlDashboard } from '../../hooks/client/dashboard/hooks/pl-dashboard.hook';
import { PlDiagramHeader } from './components/headers/pl-header.component';
import { DiagramChartsHolderWithType } from '../../components/shared/charts/manager-client.charts.components';
import { DiagramLookerHolderContext } from '../../context/iframe-chart.context';

const Snapshot = ({
  dateRange,
}: {
  dateRange: { startDate: Date; endDate: Date } | null;
}) => {
  const { cards, loading } = useSnapshotDashboard(dateRange);

  const { headerMetrics, cashflowCards, plCards, ungrouppedCards } = cards;

  const env = import.meta.env.VITE_APP_ENVIRONMENT;

  return (
    <div className={'charts-holder'}>
      <SnapshotDiagramHeader headerMetrics={headerMetrics} />
      <Updates />
      <DashboardCardHolder
        dateRange={dateRange}
        name={'CASHFLOW MANAGEMENT'}
        cards={cashflowCards}
        isLoading={loading}
        dashboardTab={'SNAPSHOT'}
      />
      <DashboardCardHolder
        name={'PROFIT & LOSS'}
        cards={plCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'SNAPSHOT'}
      />
      {env === 'development' && (
        <DashboardCardHolder
          name={'Test view'}
          cards={ungrouppedCards}
          isLoading={false}
          dateRange={dateRange}
          dashboardTab={'SNAPSHOT'}
        ></DashboardCardHolder>
      )}
    </div>
  );
};

const CashFlow = ({
  dateRange,
}: {
  dateRange: { startDate: Date; endDate: Date } | null;
}) => {
  const { cards, loading } = useCashflowDashboard(dateRange);

  const {
    headerMetrics,
    generalCards,
    operatingInflowCards,
    operatingOutflowCards,
    financialInvestingCards,
  } = cards;

  return (
    <div className={'charts-holder'}>
      <CashflowDiagramHeader headerMetrics={headerMetrics} />
      <Updates />
      <DashboardCardHolder
        name={'GENERAL'}
        cards={generalCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'CASHFLOW'}
      />
      <DashboardCardHolder
        name={'OPERATING INFLOW'}
        cards={operatingInflowCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'CASHFLOW'}
      />
      <DashboardCardHolder
        name={'OPERATING OUTFLOW'}
        cards={operatingOutflowCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'CASHFLOW'}
      />
      <DashboardCardHolder
        name={'FINANCIAL AND INVESTING ACTIVITY'}
        cards={financialInvestingCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'CASHFLOW'}
      />
    </div>
  );
};

const ProfitLoss = ({
  dateRange,
}: {
  dateRange: { startDate: Date; endDate: Date } | null;
}) => {
  const { cards, loading } = usePlDashboard(dateRange);

  const {
    headerMetrics,
    pLStatementCards,
    revenueCards,
    grossProfitMarginAndCogsCards,
    operationalExpensesCards,
    ebitdaNetProfitCards,
  } = cards;

  return (
    <div className={'charts-holder'}>
      <PlDiagramHeader headerMetrics={headerMetrics} />
      <Updates />
      <DashboardCardHolder
        name={'PROFIT AND LOSS STATEMENT (P&L) '}
        cards={pLStatementCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'PL'}
      />
      <DashboardCardHolder
        name={'REVENUE'}
        cards={revenueCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'PL'}
      />
      <DashboardCardHolder
        name={'GROSS PROFIT MARGIN  &  COST OF GOODS SOLD (COGS)'}
        cards={grossProfitMarginAndCogsCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'PL'}
      />
      <DashboardCardHolder
        name={'OPERATIONAL EXPENSES (OPEX)'}
        cards={operationalExpensesCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'PL'}
      />
      <DashboardCardHolder
        name={'EBITDA & NET PROFIT'}
        cards={ebitdaNetProfitCards}
        isLoading={loading}
        dateRange={dateRange}
        dashboardTab={'PL'}
      />
    </div>
  );
};

const Advanced = () => {
  return (
    <div className={'dashboard-advanced'}>
      <DiagramLookerHolderContext.Provider value={'LOOKER'}>
        <DiagramChartsHolderWithType chartType={'ADVANCED_METRICS'} />
      </DiagramLookerHolderContext.Provider>
    </div>
  );
};

const DashboardMenuItem = ({ name, onClick, isActive }: any) => {
  return (
    <div onClick={onClick} className={`menu-item ${isActive ? 'active' : ''}`}>
      {name}
    </div>
  );
};

export const DashboardPage = () => {
  const [chosenItem, setChosenItem] = useState<
    'SNAPSHOT' | 'PROFIT_LOSS' | 'CASH_FLOW' | 'ADVANCED'
  >('SNAPSHOT');
  const [_, setSelectedPeriod] = useState('Last 12 months');
  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  } | null>(getDateRange('Last 12 months'));

  // Memoizing the menu click handler
  const handleMenuClick = useCallback(
    (page: 'SNAPSHOT' | 'PROFIT_LOSS' | 'CASH_FLOW' | 'ADVANCED') => {
      setChosenItem(page);
    },
    []
  );

  const handlePeriodSelect = useCallback(
    (period: string, range: { startDate: Date; endDate: Date } | null) => {
      setSelectedPeriod(period);
      setDateRange(range);
    },
    []
  );

  const formattedDateRange = useMemo(() => {
    if (dateRange?.startDate && dateRange?.endDate) {
      const start = dateRange.startDate.toLocaleDateString('en-GB');
      const end = dateRange.endDate.toLocaleDateString('en-GB');
      return `${start} - ${end}`;
    }
    return '';
  }, [dateRange]);

  const renderDashboardContent = useMemo(() => {
    switch (chosenItem) {
      case 'SNAPSHOT':
        return <Snapshot dateRange={dateRange} />;
      case 'CASH_FLOW':
        return <CashFlow dateRange={dateRange} />;
      case 'PROFIT_LOSS':
        return <ProfitLoss dateRange={dateRange} />;
      case 'ADVANCED':
        return <Advanced />;
      default:
        return null;
    }
  }, [chosenItem, dateRange]);

  return (
    <>
      <div className="dashboard-menu">
        <div className="menu">
          <div>
            <DashboardMenuItem
              isActive={chosenItem === 'SNAPSHOT'}
              onClick={() => handleMenuClick('SNAPSHOT')}
              name={'SNAPSHOT'}
            />
            <DashboardMenuItem
              isActive={chosenItem === 'CASH_FLOW'}
              onClick={() => handleMenuClick('CASH_FLOW')}
              name={'CASH FLOW'}
            />
            <DashboardMenuItem
              isActive={chosenItem === 'PROFIT_LOSS'}
              onClick={() => handleMenuClick('PROFIT_LOSS')}
              name={'PROFIT & LOSS'}
            />
            <DashboardMenuItem
              isActive={chosenItem === 'ADVANCED'}
              onClick={() => handleMenuClick('ADVANCED')}
              name={'ADVANCED METRICS'}
            />
          </div>
          <div>
            {formattedDateRange && (
              <div className="selected-period">{formattedDateRange}</div>
            )}
            <PeriodSelector onSelectPeriod={handlePeriodSelect} />
          </div>
        </div>
        <div className="menu-underline"></div>
      </div>
      {renderDashboardContent}
    </>
  );
};
