import { instanceWithToken } from '../api';

export class ManagerHttpService {
  private static base = 'manager';

  public static info() {
    return instanceWithToken.get<{
      email: string;
      firstName: string;
      lastName: string;
      userImageLink: string;
    }>(`${ManagerHttpService.base}/info`);
  }
}
