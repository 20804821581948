import { gapi } from 'gapi-script';

export const googleAuthFlow = async (
  clientId: string,
  scopes: string[]
): Promise<string> => {
  // Load the Google Identity Services script
  const loadGisScript = async (): Promise<void> => {
    if (document.getElementById('gis')) return;
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.id = 'gis';
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Failed to load GIS script'));
      document.body.appendChild(script);
    });
  };

  try {
    await loadGisScript(); // Load GIS script

    return new Promise((resolve, reject) => {
      const tokenClient = google.accounts.oauth2.initCodeClient({
        client_id: clientId,
        scope: scopes.join(' '),
        ux_mode: 'popup', // Ensures the popup mode
        callback: (response) => {
          if (response.code) {
            resolve(response.code); // Resolve with authorization code
          } else {
            reject(new Error('Failed to obtain authorization code'));
          }
        },
      });

      tokenClient.requestCode(); // Trigger the OAuth 2.0 code flow
    });
  } catch (error) {
    console.error('Error during Google Auth flow:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};
