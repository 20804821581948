import clientOnboardingImageOne from '../../../../public/images/client-onboarding-1.png';
import clientOnboardingImageTwo from '../../../../public/images/client-onboarding-2.png';
import clientOnboardingImageThree from '../../../../public/images/client-onboarding-3.png';

import managerOnboardingImageOne from '../../../../public/images/manager-onboarding-1.png';
import managerOnboardingImageTwo from '../../../../public/images/manager-onboarding-2.png';
import managerOnboardingImageThree from '../../../../public/images/manager-onboarding-3.png';

export const ClientOnboardingContents = [
  {
    stepNumber: 1,
    image: clientOnboardingImageOne,
    title:
      'First, we will ask you to connect your QuickBooks, Stripe, HubSpot and Gusto accounts.',
    subtitle: '',
  },
  {
    stepNumber: 2,
    image: clientOnboardingImageTwo,
    title:
      "Then, we'll update you with every step we're taking to bring you closer to your business goal.",
    subtitle: '',
  },
  {
    stepNumber: 3,
    image: clientOnboardingImageThree,
    title:
      "Finally, you'll get best-practice dashboards to efficiently manage your business.",
    subtitle: '',
  },
];

export const ManagerOnboardingContents = [
  {
    stepNumber: 1,
    image: managerOnboardingImageOne,
    title: '',
    subtitle: '',
  },
  {
    stepNumber: 2,
    image: managerOnboardingImageTwo,
    title: 'Update your client with your current steps.',
    subtitle: '',
  },
  {
    stepNumber: 3,
    image: managerOnboardingImageThree,
    title:
      "Then, we'll update you with every step we're taking to bring you closer to your business goal.",
    subtitle: '',
  },
];
