import { useEffect, useState } from 'react';

export const useOverlay = (stepNumber: number) => {
  const [overlay, setOverlay] = useState(false);
  const checkOverlay = () => {
    const wasOverlayShown = localStorage.getItem('overlayShown');

    if (!wasOverlayShown) {
      setOverlay(true);
      localStorage.setItem('overlayShown', 'true');
    }
  };

  useEffect(() => {
    if (stepNumber >= 3) {
      checkOverlay();
    }
  }, []);

  return {
    overlay,
    setOverlay,
  };
};
