import React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useUser } from '../../../../context/user-details.context';
import { useDataSource } from '../../data-source.hook';
import { trackAddIntegrationPayroll } from '../../../../tools/analytics-events';
import { useFinchConnect } from '@tryfinch/react-connect';
import './styles.css';
import { ToasterContext } from '../../../../context/toaster.context';
import {
  PayrollIntegrations,
  ProvidersMap,
} from '../../../../tools/api-services/types/connected-sources';

export const PayrollSource = ({
  setLoading,
}: {
  setLoading: (loading: boolean) => void;
}) => {
  const { userDetails } = useUser();
  const [cred, setCred] = useState('');
  const { handleCreatePayrollIntegration } = useDataSource();
  const { updateToaster } = useContext(ToasterContext);
  const [provider, setProvider] = useState('');
  const CLIENT_ID = import.meta.env.VITE_FINCH_CLIENT_ID;

  const { open } = useFinchConnect({
    clientId: CLIENT_ID,
    products: [
      'company',
      'directory',
      'individual',
      'employment',
      'payment',
      'pay_statement',
    ],
    payrollProvider: provider.toLowerCase(),
    sandbox: false,

    onSuccess: ({ code }: any) => {
      setCred(code);
    },
    onError: () => {
      updateToaster({
        content: 'Connection failed',
        isError: true,
      });
    },
    onClose: () => {
      setProvider('');
      updateToaster({
        content: 'Connection aborted',
        isError: false,
      });
    },
  });

  const handlePayrollConnect = useCallback(async () => {
    try {
      trackAddIntegrationPayroll({
        companyName: userDetails.companyName,
        userName: userDetails.userName,
        userType: userDetails.userType,
      });
      open();
    } catch (error) {
      console.error('Error fetching Payroll code:', error);
    }
  }, [
    open,
    userDetails.companyName,
    userDetails.userName,
    userDetails.userType,
  ]);

  useEffect(() => {
    if (cred) {
      handleCreatePayrollIntegration(cred, provider);
      setCred('');
      setProvider('');
    }
  }, [provider, handleCreatePayrollIntegration, cred]);

  useEffect(() => {
    if (provider && !cred) {
      handlePayrollConnect();
    }
  }, [handlePayrollConnect, cred, provider]);

  return (
    <>
      {PayrollIntegrations.map((payrollIntegration) => (
        <button
          key={payrollIntegration}
          onClick={() => setProvider(payrollIntegration)}
          className={`payroll-systems-${payrollIntegration.toLowerCase()}`}
        >
          {ProvidersMap[payrollIntegration]}
        </button>
      ))}
    </>
  );
};
