import { useContext } from 'react';
import { ToasterContext } from '../../context/toaster.context';

export const SuccessToast = () => {
  const { isVisible, content } = useContext(ToasterContext);

  return (
    <div className={`success-message ${isVisible ? 'visible' : 'hidden'}`}>
      {content}
    </div>
  );
};
