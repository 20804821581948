import {
  CardDetails,
  SubscriptionDetails,
} from '../../../tools/api-services/types/payment';

export const CardDetailsComponent = ({
  subscriptionDetails,
  cardDetails,
  updateCard,
}: {
  subscriptionDetails: SubscriptionDetails | null;
  cardDetails: CardDetails | null;
  updateCard: (event: any) => void;
}) => {
  return (
    subscriptionDetails?.subscriptionStatus === 'active' && (
      <div className="payment-details-card-item">
        <p>
          {cardDetails?.brand} ending in {cardDetails?.last4}
        </p>
        <p>
          Expires: {cardDetails?.exp_month}/{cardDetails?.exp_year}
        </p>
        <a onClick={(e) => updateCard(e)} href="">
          Change card
        </a>
      </div>
    )
  );
};
