import { Ellipsis } from 'lucide-react';
import { useMetrics } from './metrics.hook';
import './metrics.style.css';
import { useState } from 'react';
import { Metric } from '../../tools/manager-api/types/metrics';
import MetricModal from './components/metric-modal/metric-modal.component';
import { Pagination } from '../mapping/components/pagination.component';

export const MetricsPage = () => {
  const { metrics, updateMetric, totalCount, fetchMetrics } = useMetrics();
  const [selectedMetric, setSelectedMetric] = useState<Metric | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownMetricId, setDropdownMetricId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageLimit = 50;

  const openModal = (metric: Metric) => {
    setSelectedMetric(metric);
    setIsModalOpen(true);
    setDropdownMetricId(null);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMetric(null);
  };

  const toggleDropdown = (metricId: number) => {
    setDropdownMetricId((prevId) => (prevId === metricId ? null : metricId));
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchMetrics(page, pageLimit);
  };

  return (
    <div className="metrics-container">
      <table className={'metrics-table'}>
        <thead>
          <tr>
            <th>METRIC NAME</th>
            <th>FORMULA</th>
            <th>BENCHMARK</th>
            <th>LAST 3-MON AVG ACTUAL</th>
            <th>NEXT 3-MON AVG TARGET</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {metrics.length > 0 &&
            metrics.map((metric) => (
              <tr key={metric.id}>
                <td>{metric.name}</td>
                <td>{metric.actualFormula}</td>
                <td>{metric.benchmark}</td>
                <td>{metric.lastThreeMonthAvgActual}</td>
                <td>{metric.nextThreeMonthAvgTarget}</td>
                <td style={{ position: 'relative' }}>
                  <Ellipsis
                    style={{
                      position: 'relative',
                      height: '20px',
                      width: '15px',
                      right: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleDropdown(metric.id);
                    }}
                  />
                  {dropdownMetricId === metric.id && (
                    <div className="metrics-dropdown-menu">
                      <div
                        className="metrics-dropdown-item"
                        onClick={() => openModal(metric)}
                      >
                        Edit
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <Pagination
        page={currentPage}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        pageLimit={pageLimit}
      />

      {isModalOpen && selectedMetric && (
        <MetricModal
          data={selectedMetric}
          onClose={closeModal}
          onUpdate={updateMetric}
        />
      )}
    </div>
  );
};
