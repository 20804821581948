import { IntegrationHttpService } from '../../tools/api-services/integrations.service';
import { PaymentHttpService } from '../../tools/api-services/payment.http-service';
import { DashboardHttpService } from '../../tools/api-services/dashboardHttpService';
import { useNavigate } from 'react-router-dom';
import { trackConnectQB } from '../../tools/analytics-events';

const getQBLink = async (userType: string) => {
  const { data } = await IntegrationHttpService.getQbLink(userType);

  return data.authUri;
};

const getPaymentLink = async () => {
  const { data } = await PaymentHttpService.getPaymentLink(true);

  return data.link;
};

const getWithPayment = async (userPayed: boolean, userType: string) => {
  if (!userPayed) {
    return getPaymentLink();
  }

  return getQBLink(userType);
};

export const useQbConnector = () => {
  const navigate = useNavigate();
  const getLinkAndRedirect = async (isPaymentRequired = true) => {
    trackConnectQB();

    const isQbConnected =
      await IntegrationHttpService.checkQuickbooksConnection();

    if (isQbConnected?.data?.isQuickbooksConnected) {
      navigate('/');
      return;
    }

    if (!isPaymentRequired) {
      window.location = (await getQBLink('bootstrap')) as string & Location;
      return;
    }

    const {
      data: { paymentFinished },
    } = await PaymentHttpService.getPaymentStatus();

    const link = await getWithPayment(paymentFinished, 'bootstrap');

    window.location = link as string & Location;
  };

  const createQuickbooksConnectToken = async () => {
    const onboardingStepResponse =
      await DashboardHttpService.getLatestOnboardingStep();
    await DashboardHttpService.createQuickbooksConnectToken();

    const { stepNumber } = onboardingStepResponse.data;
    const isStepTwo = stepNumber === 2;

    let path;
    if (isStepTwo) {
      path = '/tutorials/3';
    } else {
      path = '/';
    }

    navigate(path);
  };

  return {
    getLinkAndRedirect,
    getQBLink,
    createQuickbooksConnectToken,
  };
};
