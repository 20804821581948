import { useState } from 'react';
import { DateTime } from 'luxon';

export const CustomLineTooltip = (lineData: any, baseKey: string) => {
  const [tooltip, setTooltip] = useState<
    | { x: number; y: number; data: { x: number; y: number } }
    | { x: number; y: number; data: null }
  >({ x: 0, y: 0, data: null });

  const handleMouseEnter = (
    event: unknown,
    point: { x: number; y: number },
    data: {
      x: number;
      y: number;
    }
  ) => {
    setTooltip({ x: point.x, y: point.y, data });
  };

  const handleMouseLeave = () => {
    setTooltip({ x: 0, y: 0, data: null });
  };

  return ({ bars, xScale, yScale }: any) => {
    const barsMaxValue = Math.max(...bars.map((bar: any) => bar.data.value));

    const data = [
      {
        id: 'line1',
        data: lineData.line1,
      },
    ];
    const linePoints = data[0].data.map((point: any) => {
      const vv = DateTime.fromFormat(point.x, 'yyyy-MM').toFormat('yyyy-MM');
      const d = `${baseKey}.${vv}`;
      const bar = bars.find((bar: any) => bar.key === d);
      const yPoint = yScale((point.y * barsMaxValue) / 100);
      return {
        x: bar ? bar.x + bar.width / 2 : 0,
        y: yPoint,
      };
    });

    return (
      <g>
        <path
          d={linePoints.reduce(
            (path: unknown, point: { x: number; y: number }, index: number) => {
              if (index === 0) return `M${point.x},${point.y}`;
              return `${path} L${point.x},${point.y}`;
            },
            ''
          )}
          fill="none"
          stroke="black"
          strokeWidth={2}
        />
        {linePoints.map((point: { x: number; y: number }, index: number) => (
          <circle
            key={index}
            cx={point.x}
            cy={point.y}
            r={3}
            fill="black"
            onMouseEnter={(event) => {
              handleMouseEnter(event, point, data[0].data[index]);
            }}
            onMouseLeave={handleMouseLeave}
          />
        ))}
        {tooltip.data && (
          <foreignObject
            x={tooltip.x - 50}
            y={tooltip.y + 10}
            width={100}
            height={100}
            style={{ pointerEvents: 'none' }}
          >
            <div
              style={{
                backgroundColor: 'white',
                border: '1px solid black',
                borderRadius: '3px',
                padding: '5px',
                zIndex: 1000,
                position: 'relative',
                pointerEvents: 'none',
              }}
            >
              <div>{`Date: ${tooltip.data.x}`}</div>
              <div>{`Value: ${tooltip.data.y}%`}</div>
            </div>
          </foreignObject>
        )}
      </g>
    );
  };
};
