import { BasePage } from '../base';
import { Container } from '../../shared-components/container';
import { Head } from '../../shared-components/head';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQbConnector } from '../../shared-components/feature/qb-connector.hook';
import { useEffect } from 'react';
import { Button } from '../../shared-components/button';

export const PaymentSuccess = ({ content }: { content: string }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('qb');

  const navigate = useNavigate();

  const { getQBLink } = useQbConnector();

  const getLinkAndRedirect = async () => {
    if (query && Boolean(query)) {
      const link = await getQBLink('bootstrap');

      window.location = link as string & Location;
    }
  };

  useEffect(() => {
    getLinkAndRedirect();
  });

  return (
    <BasePage>
      <Container extended={true}>
        <Head center={true}>{content}</Head>
        <Button
          style={{ margin: '20px auto', width: '50%' }}
          onClick={() => {
            navigate('/');
          }}
          active={true}
          mini={true}
        >
          Go to dashboard
        </Button>
      </Container>
    </BasePage>
  );
};
