import { useState } from 'react';
import { ManagerCompaniesHttpService } from '../../tools/manager-api/http-companies';
import { Company } from '../../tools/manager-api/types/company';

export const useManagerHome = () => {
  const [companies, setCompanies] = useState<Company[]>([]);

  const getAvailableCompanies = async () => {
    const response = await ManagerCompaniesHttpService.availableCompanies();
    if (!response.data.result.length) {
      return;
    }

    return setCompanies(response.data.result);
  };

  return {
    companies,
    getAvailableCompanies,
  };
};
