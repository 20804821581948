import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { instanceWithToken } from '../../../tools/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  trackRegistrationFilled,
  trackRegistrationStart,
} from '../../../tools/analytics-events';
import { ToasterContext } from '../../../context/toaster.context';
import { OnboardingContext } from '../../../context/onboarding.context';
import { useHubspot } from './hubspot.hook';

export const registrationSchema = Yup.object().shape({
  firstName: Yup.string().required('Name is required'),
  lastName: Yup.string(),
  companyName: Yup.string(),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .min(12, 'At least 12 characters long')
    .matches(
      /(?=.*[a-z])/,
      'Password must contain at least one lowercase letter'
    )
    .matches(
      /(?=.*[A-Z])/,
      'Password must contain at least one uppercase letter'
    )
    .matches(/(?=.*[0-9])/, 'Password must contain at least one number')
    .matches(
      /(?=.*[!@#$%^&*])/,
      'Password must contain at least one special symbol'
    )
    .required('Password is required'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password')],
    "Passwords didn't match"
  ),
});

export const useDirectRegistrationHook = () => {
  const { hubspotSubmit } = useHubspot();
  const navigate = useNavigate();
  const location = useLocation();
  const ctx = useContext(ToasterContext);
  const [error, setError] = useState('');
  const context = useContext(OnboardingContext);

  const registrationSubmit = async (formValues: any) => {
    const urlParams = new URLSearchParams(location.search);
    return await instanceWithToken.post<string>(
      `api/auth/register?${urlParams}`,
      {
        ...formValues,
      }
    );
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    onSubmit: async (values) => {
      if (!context?.businessModel) {
        const onboardingData = localStorage.getItem('business-model');

        if (!context) {
          return;
        }

        context?.setOnboardingData?.(JSON.parse(onboardingData || ''));
      }

      try {
        await registrationSubmit({
          firstName: values.firstName,
          lastName: values.lastName,
          company: values.companyName,
          email: values.email,
          password: values.password,
          businessModel: context?.businessModel,
          experience: context?.experience,
          needs: context?.needs,
        });

        trackRegistrationStart(
          context?.businessModel || '',
          context?.experience || '',
          context?.needs || [],
          `${values.firstName} ${values.lastName}`
        );
        trackRegistrationFilled();
        await hubspotSubmit({
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          company: values.companyName,
        });
        navigate(`/verify-email?email=${values.email}`);
      } catch (error: any) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              ctx.updateToaster({
                content: error.response.data.message,
                isError: true,
              });
              break;
            case 409:
              ctx.updateToaster({
                content:
                  'Looks like you already have an account with us. Welcome back! Please login using the same email.',
                isError: true,
              });
              break;
            case 500:
              ctx.updateToaster({
                content: 'Internal server error. Please try again later.',
                isError: true,
              });
              break;
            default:
              ctx.updateToaster({
                content: 'An unexpected error occurred.',
                isError: true,
              });
          }
        } else {
          ctx.updateToaster({
            content: 'An error occurred. Please try again.',
            isError: true,
          });
        }
      }
    },
    validationSchema: registrationSchema,
  });

  return {
    errorState: { error, setError },
    form: formik,
  };
};
