import React, { useState } from 'react';
import './update-client.style.css';
import { SquareX } from 'lucide-react';
import { UpdatedButtonComponent } from '../../../../shared-components/button/updated.button.component';

interface UpdateClientModalProps {
  onClose: () => void;
  onSave: (
    id: number,
    clientName: string,
    startDate?: string,
    endDate?: string
  ) => void;
  client: {
    id: number;
    clientName: string;
    startDate?: string;
    endDate?: string;
  } | null;
}

const UpdateClientModal: React.FC<UpdateClientModalProps> = ({
  onClose,
  onSave,
  client,
}) => {
  const [clientName, setAccountName] = useState(client?.clientName || '');
  const [startDate, setStartDate] = useState(client?.startDate || '');
  const [endDate, setEndDate] = useState(client?.endDate || '');
  const [error, setError] = useState<string | null>(null);

  const handleSaveClick = () => {
    if (!clientName.trim()) {
      setError('Account name is required');
      return;
    }

    if (client) {
      onSave(client.id, clientName, startDate, endDate);
      onClose();
    }
  };

  return (
    <div className="modal-overlay">
      <div className="update-client-modal">
        <div className="update-client-modal__header">
          <h2>Update Client</h2>
          <SquareX
            className="close-cross-button"
            onClick={onClose}
            style={{ width: '45px', height: '45px' }}
            strokeWidth={'0.7'}
          />
        </div>
        <div className="update-client-modal__body">
          <div className="input-group">
            <input
              id="account-name"
              type="text"
              placeholder="Client name"
              value={clientName}
              onChange={(e) => {
                setAccountName(e.target.value);
                setError(null);
              }}
              name="clientName"
            />
            {error && <span className="error-message">{error}</span>}
          </div>
          <div className="input-group">
            <input
              id="start-date"
              type="date"
              placeholder="Start date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              name="startDate"
            />
          </div>
          <div className="input-group">
            <input
              id="end-date"
              type="date"
              placeholder="End date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              name="endDate"
            />
          </div>
        </div>

        <UpdatedButtonComponent
          mini={true}
          type={'default'}
          onClick={handleSaveClick}
        >
          Save
        </UpdatedButtonComponent>
      </div>
    </div>
  );
};

export default UpdateClientModal;
