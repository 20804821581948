import { createContext, Dispatch, SetStateAction } from 'react';

export interface OnboardingContextType {
  businessModel: string;
  experience: string;
  needs: string[];
  package: string;
  setOnboardingData?: Dispatch<SetStateAction<OnboardingContextType>>;
}

export const OnboardingContext = createContext<
  OnboardingContextType | undefined
>(undefined);
