import { instanceWithToken } from '../api';
import { CompanyResponse } from './types/company';
import { CompanyCsvFilesResponse } from './types/company-csv-files';
import { CompanyDataSourcesResponse } from './types/company-data-source';
import { CompanyDocumentsResponse } from './types/company-document';

export class ManagerCompaniesHttpService {
  private static base = 'manager/companies';

  public static async isManagerAssignedToCompany(companyId: number) {
    return instanceWithToken.get(
      `${ManagerCompaniesHttpService.base}/${companyId}/access`
    );
  }

  public static availableCompanies(): Promise<CompanyResponse> {
    return instanceWithToken.get(
      `${ManagerCompaniesHttpService.base}/available`
    );
  }

  public static companyDocuments(
    companyId: number
  ): Promise<CompanyDocumentsResponse> {
    return instanceWithToken.get(
      `${ManagerCompaniesHttpService.base}/${companyId}/documents`
    );
  }

  public static addCompanyDocument(
    companyId: number,
    document: { documentName: string; documentLink: string }
  ): Promise<void> {
    return instanceWithToken.post(
      `${ManagerCompaniesHttpService.base}/${companyId}/documents`,
      document
    );
  }

  public static deleteCompanyDocument(
    companyId: number,
    documentId: number
  ): Promise<void> {
    return instanceWithToken.delete(
      `${ManagerCompaniesHttpService.base}/${companyId}/documents/${documentId}`
    );
  }

  public static companyDataSources(
    companyId: number
  ): Promise<CompanyDataSourcesResponse> {
    return instanceWithToken.get(
      `${ManagerCompaniesHttpService.base}/${companyId}/data-sources`
    );
  }
}
