import React, { useState } from 'react';
import './mapping.style.css';
import { FinancialAccounts } from './financial-accounts/financial-account.component';
import { Employees } from './employees/employee.component';
import { Clients } from './clients/client.component';
import { Products } from './products/product.component';

const MappingMenuItem = ({ name, onClick, isActive }: any) => {
  return (
    <div
      onClick={onClick}
      className={`mapping-menu-item ${isActive ? 'active' : ''}`}
    >
      {name}
    </div>
  );
};

export const MappingPage: React.FC = () => {
  const [chosenItem, setChosenItem] = useState<
    'FINANCIAL_ACCOUNTS' | 'EMPLOYEES' | 'CLIENTS' | 'PRODUCTS'
  >('FINANCIAL_ACCOUNTS');

  const handleMenuClick = (
    page: 'FINANCIAL_ACCOUNTS' | 'EMPLOYEES' | 'CLIENTS' | 'PRODUCTS'
  ) => {
    setChosenItem(page);
  };
  return (
    <>
      <div className="mapping-menu">
        <div className="menu">
          <MappingMenuItem
            isActive={chosenItem === 'FINANCIAL_ACCOUNTS'}
            onClick={() => {
              handleMenuClick('FINANCIAL_ACCOUNTS');
            }}
            name={'FINANCIAL ACCOUNTS'}
          />
          <MappingMenuItem
            isActive={chosenItem === 'EMPLOYEES'}
            onClick={() => {
              handleMenuClick('EMPLOYEES');
            }}
            name={'EMPLOYEES'}
          />
          <MappingMenuItem
            isActive={chosenItem === 'CLIENTS'}
            onClick={() => {
              handleMenuClick('CLIENTS');
            }}
            name={'CLIENTS'}
          />
          <MappingMenuItem
            isActive={chosenItem === 'PRODUCTS'}
            onClick={() => {
              handleMenuClick('PRODUCTS');
            }}
            name={'PRODUCTS'}
          />
        </div>
        <div className="menu-underline"></div>
      </div>
      {
        {
          FINANCIAL_ACCOUNTS: <FinancialAccounts />,
          EMPLOYEES: <Employees />,
          CLIENTS: <Clients />,
          PRODUCTS: <Products />,
        }[chosenItem]
      }
    </>
  );
};
