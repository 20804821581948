import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { instanceWithToken } from '../../tools/api';
import { useContext, useState } from 'react';
import { ToasterContext } from '../../context/toaster.context';
import { useUser } from '../../context/user-details.context';
import { trackSettingPassword } from '../../tools/analytics-events';
import { confirmPasswordReset, getAuth } from 'firebase/auth';

const schema = Yup.object().shape({
  password: Yup.string()
    .min(12, 'At least 12 characters long')
    .matches(
      /(?=.*[a-z])/,
      'Password must contain at least one lowercase letter'
    )
    .matches(
      /(?=.*[A-Z])/,
      'Password must contain at least one uppercase letter'
    )
    .matches(/(?=.*[0-9])/, 'Password must contain at least one number')
    .matches(
      /(?=.*[!@#$%^&*])/,
      'Password must contain at least one special symbol'
    )
    .required('Password is required'),
  repeatPassword: Yup.string().oneOf(
    [Yup.ref('password')],
    "Passwords didn't match"
  ),
});

export const useReset = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const ctx = useContext(ToasterContext);
  const [error, setError] = useState('');
  const { userDetails } = useUser();

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
      signed: false,
    },
    onSubmit: async (values) => {
      await submit({
        password: values.password,
        repeatPassword: values.repeatPassword,
      });
    },
    validationSchema: schema,
  });

  const submit = async (values: FormikValues) => {
    const urlParams = new URLSearchParams(window.location.search);
    const oobCode = urlParams.get('oobCode');

    try {
      if (!oobCode) {
        console.error('No oobCode in URL');
        return;
      }
      await confirmPasswordReset(getAuth(), oobCode, values.password);

      trackSettingPassword({
        companyName: userDetails.companyName,
        userName: userDetails.userName,
        userType: userDetails.userType,
      });

      navigate('/login');
    } catch (error: any) {
      setError(error.message);
    }
  };

  return {
    errorState: { error, setError },
    form: formik,
  };
};
