import { Button } from '../../../shared-components/button';
import { useNavigate } from 'react-router-dom';
import { UpdatedButtonComponent } from '../../../shared-components/button/updated.button.component';
import { trackCancelSubscription } from '../../../tools/analytics-events';

interface PaymentHeaderComponentProps {
  renewalPayment: () => void;
  isSubscriptionCanBeRenewed: boolean | null;
  firstName: string;
  email: string;
}

export const PaymentHeaderComponent = ({
  headerProps,
}: {
  headerProps: PaymentHeaderComponentProps;
}) => {
  const navigation = useNavigate();
  const { renewalPayment, isSubscriptionCanBeRenewed, firstName } = headerProps;

  const renderActionButton = () => {
    if (isSubscriptionCanBeRenewed === null) return null;

    return isSubscriptionCanBeRenewed ? (
      <Button
        onClick={renewalPayment}
        active={true}
        mini
        light
        style={{ padding: '4px 8px 4px 8px' }}
      >
        Renew subscription
      </Button>
    ) : (
      <UpdatedButtonComponent
        mini
        type={'bordered'}
        onClick={() => {
          trackCancelSubscription();
          navigation('/cancel-subscription');
        }}
      >
        Cancel subscription
      </UpdatedButtonComponent>
    );
  };

  return (
    <>
      <div className="payment-details-header">
        <p className="payment-details__h1">Hi, {firstName}</p>
        {renderActionButton()}
      </div>
      <div className="separator"></div>
    </>
  );
};
