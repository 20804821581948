import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useNextLogin } from '../login/next-login.hook';
import { Preloader } from '../../shared-components/preloader';
import { BasePage, HeaderLogo } from '../base';

export const NextAuth = () => {
  const navigate = useNavigate();
  const { handleRedirectResult, handleOpenidRedirect, isNextLoading } =
    useNextLogin();

  const checkCustomToken = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const customToken = urlParams.get('customToken');

    if (customToken) {
      const user = await signInWithCustomToken(getAuth(), customToken);
      const token = await user.user.getIdToken();
      localStorage.setItem('token', token);
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    checkCustomToken();
    if (!sessionStorage.getItem('openidInitialized')) {
      handleOpenidRedirect();
      sessionStorage.setItem('openidInitialized', 'true');
    }
    handleRedirectResult();
  }, [checkCustomToken]);

  return (
    <>
      {/*{isNextLoading && <Preloader isLoading={true} />}*/}
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: 'white',
        }}
      >
        {/*<HeaderLogo/>*/}
        {/*<div style={{*/}
        {/*  display:'flex',*/}
        {/*  flexDirection:'row',*/}
        {/*  gap:'60px',*/}
        {/*  marginTop:'28px'*/}
        {/*}}>*/}
        {/*  <p>Runway Calculator</p>*/}
        {/*  <p>Dilution Calculator</p>*/}
        {/*  <p>Financial Reports</p>*/}
        {/*</div>*/}
      </div>
    </>
  );
};
