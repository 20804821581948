export type SourceType = 'quickbooks' | 'gsheet' | 'sales' | 'payroll' | 'crm';

export type DataSourceDto = {
  id: number;
  name: string;
  type: SourceType;
  lastImportStatus: string;
  lastImportTime: Date;
  provider: Provider;
};

export type DataSourceListResponseDto = {
  paymentSystems: DataSourceDto[];
  accountingSystems: DataSourceDto[];
  payrollSystems: DataSourceDto[];
  crmSystems: DataSourceDto[];
  gsheetFiles: DataSourceDto[];
};

export type Provider =
  | 'GUSTO'
  | 'PAYLOCITY'
  | 'STRIPE'
  | 'PAYPAL'
  | 'HUBSPOT'
  | 'SALES_FORCE';

export const PayrollIntegrations: Provider[] = ['GUSTO', 'PAYLOCITY'];
export const SalesIntegrations: Provider[] = ['STRIPE', 'PAYPAL'];
export const CrmIntegrations: Provider[] = ['HUBSPOT', 'SALES_FORCE'];

export const ProvidersMap = {
  GUSTO: 'Gusto',
  PAYLOCITY: 'Paylocity',
  STRIPE: 'Stripe',
  PAYPAL: 'PayPal',
  HUBSPOT: 'HubSpot',
  SALES_FORCE: 'Salesforce',
};
