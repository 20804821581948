import { Container } from '../../shared-components/container';
import { Head } from '../../shared-components/head';
import './comments.style.css';
import { CommentResponse } from '../../tools/api-services/types/Comment';
import { Button } from '../../shared-components/button';
import { useClientComments } from './client-comments.hook';
import { useContext, useEffect, useState } from 'react';
import { useManagerComments } from './manager-comments.hook';
import { RoleContext } from '../../context/role.context';
import { useNavigate, useParams } from 'react-router-dom';
import { trackOpenUpdates } from '../../tools/analytics-events';
import { useUser } from '../../context/user-details.context';
import { Trash2, Paperclip } from 'lucide-react';
import { ToasterContext } from '../../context/toaster.context';

const CommentComponent = ({
  comment,
  deleteComment,
}: {
  comment: CommentResponse;
  deleteComment?: (commentId: number) => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const openModal = (imageLink: string) => {
    setSelectedImage(imageLink);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  return (
    <div className="comment">
      <div
        style={{
          backgroundImage: `url(${comment.manager.userImageLink || ''})`,
          backgroundSize: 'cover',
        }}
        className="comment-avatar"
      ></div>
      <div className="comment-body">
        <div className="comment-date">{comment.createdAt}</div>
        <div className="comment-content">
          {comment.text.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
        <div className="comment-images">
          {comment.images.map((image) => (
            <img
              key={image.id}
              src={image.link}
              alt="Comment Image"
              className="comment-thumbnail"
              onClick={() => openModal(image.link)}
            />
          ))}
        </div>
      </div>
      {deleteComment && (
        <div className="comment-actions">
          <Trash2
            onClick={() => deleteComment(comment.id)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      )}
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img src={selectedImage!} alt="Full Size" className="modal-image" />
          </div>
        </div>
      )}
    </div>
  );
};

const CommentFormComponent = ({
  createComment,
}: {
  createComment: (text: string, images: File[]) => void;
}) => {
  const [comment, setComment] = useState('');
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const { updateToaster } = useContext(ToasterContext);

  useEffect(() => {
    if (selectedImages.length === 0) {
      setImagePreviews([]);
      return;
    }

    const previews: string[] = [];
    selectedImages.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        previews.push(reader.result as string);
        if (previews.length === selectedImages.length) {
          setImagePreviews(previews);
        }
      };
      reader.readAsDataURL(file);
    });
  }, [selectedImages]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      if (filesArray.length + selectedImages.length > 4) {
        updateToaster({
          content: 'The number of images exceeds the limit.',
          isError: true,
        });
        return;
      }
      setSelectedImages((prev) => [...prev, ...filesArray]);
    }
  };

  const handleDeleteImage = (index: number) => {
    setSelectedImages((prevImages) => {
      const updatedImages = prevImages.filter((_, i) => i !== index);
      const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
      setImagePreviews(updatedPreviews);
      return updatedImages;
    });
  };

  const handleSubmit = async () => {
    await createComment(comment, selectedImages);
    setComment('');
    setSelectedImages([]);
    setImagePreviews([]);
  };

  return (
    <div className="comment-form">
      <div className="comment-form-controls">
        <Head>Add Update</Head>
        <Button active={true} onClick={handleSubmit} mini={true}>
          Add
        </Button>
      </div>
      <div className="textarea-container">
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Write a comment"
        ></textarea>
        <label htmlFor="file-input" className="file-input-label">
          <Paperclip className="file-input-icon" />
        </label>
        <input
          id="file-input"
          type="file"
          multiple
          onChange={handleImageChange}
          accept="image/*"
          className="file-input"
        />
      </div>
      <div className="image-preview-container">
        {imagePreviews.map((preview, index) => (
          <div key={index} className="image-preview">
            <img
              src={preview}
              alt={`Preview ${index}`}
              className="preview-image"
            />
            <button
              onClick={() => handleDeleteImage(index)}
              className="delete-button"
            >
              <Trash2 />
            </button>
          </div>
        ))}
      </div>
      <div className="separator"></div>
    </div>
  );
};

const CommentsComponent = ({
  comments,
  deleteComment,
}: {
  comments: CommentResponse[];
  deleteComment?: (commentId: number) => void;
}) => {
  const { userDetails } = useUser();
  const { companyId } = useParams();
  const link = companyId
    ? '/manager/companies/' + companyId + '/comments'
    : '/comments';
  return (
    <>
      <div className="comments-controls">
        <Head>Updates</Head>
        {comments.length ? (
          <a
            href={link}
            onClick={() => {
              trackOpenUpdates({
                companyName: userDetails.companyName,
                userName: userDetails.userName,
                userType: userDetails.userType,
              });
            }}
          >
            view all
          </a>
        ) : null}
      </div>
      {comments.length ? (
        <div className="comments-content">
          {comments.map((comment: CommentResponse) => (
            <CommentComponent
              key={comment.id}
              comment={comment}
              deleteComment={deleteComment}
            />
          ))}
        </div>
      ) : (
        <div className="no-comments">No comments yet</div>
      )}
    </>
  );
};

export const AllCommentsComponent = ({
  comments,
}: {
  comments: CommentResponse[];
}) => {
  const navigate = useNavigate();
  const { companyId } = useParams();

  const redirectLink = companyId
    ? '/manager/companies/' + companyId + '/'
    : '/';

  return (
    <Container extended={true}>
      <div className="comments-controls all">
        <button
          onClick={() => {
            navigate(redirectLink);
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.29289 12.6722C2.90237 12.3009 2.90237 11.6991 3.29289 11.3278L9.65685 5.27842C10.0474 4.90719 10.6805 4.90719 11.0711 5.27842C11.4616 5.64964 11.4616 6.25151 11.0711 6.62273L6.41421 11.0494H20C20.5523 11.0494 21 11.475 21 12C21 12.525 20.5523 12.9506 20 12.9506H6.41421L11.0711 17.3773C11.4616 17.7485 11.4616 18.3504 11.0711 18.7216C10.6805 19.0928 10.0474 19.0928 9.65685 18.7216L3.29289 12.6722Z"
              fill="black"
            />
          </svg>
        </button>
        <Head>All Updates</Head>
      </div>
      {comments.length ? (
        <div className="comments-content">
          {comments.map((comment: CommentResponse) => (
            <CommentComponent key={comment.id} comment={comment} />
          ))}
        </div>
      ) : (
        <div className="no-comments">No comments yet</div>
      )}
    </Container>
  );
};

export const ClientCommentsHoc = () => {
  const { userType } = useContext(RoleContext);
  const { comments } = useClientComments();

  if (
    !userType ||
    (userType && ['BOOTSTRAP', 'BOOTSTRAP_NEXT'].includes(userType))
  ) {
    return null;
  }

  return (
    <Container extended={true}>
      <CommentsComponent comments={comments} />
    </Container>
  );
};

export const ManagerCommentsHoc = () => {
  const { comments, fetchComments, createComment, deleteComment } =
    useManagerComments();

  useEffect(() => {
    fetchComments(3);
  }, []);

  return (
    <Container extended={true}>
      <CommentFormComponent createComment={createComment} />
      <CommentsComponent comments={comments} deleteComment={deleteComment} />
    </Container>
  );
};
