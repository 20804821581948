import { Container } from '../container';
import './error-content.css';

export const ErrorContent = ({
  errorTitle,
  errorDescription,
}: {
  errorTitle: string;
  errorDescription: string;
}) => {
  return (
    <Container extended={true} style={{ backgroundColor: '#FF6F6F' }}>
      <div className="error-content">
        <div className="error-header">
          <p className="error-content-header">{errorTitle}</p>
        </div>
        <div className="error-description">
          <p className="error-content-description">{errorDescription}</p>
        </div>
      </div>
    </Container>
  );
};
