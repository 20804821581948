import { useCallback, useEffect, useState } from 'react';
import { DashboardHttpService } from '../../../../../tools/api-services/dashboardHttpService';
import { CompanyDocument } from '../../../../../tools/api-services/types/document';

export const useNewDocuments = () => {
  const [documents, setDocuments] = useState<CompanyDocument[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [areReportsUpdating, setAreReportsUpdating] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const clearDocumentsInterval = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [intervalId]);

  const fetchDocuments = async () => {
    try {
      const {
        data: { documentsLoading },
      } = await DashboardHttpService.getDocumentsLoadingStatus();
      setAreReportsUpdating(documentsLoading);

      const { data } = await DashboardHttpService.documents();
      setDocuments(data);

      if (data?.length === 0) {
        setIsLoading(true);
        const newIntervalId = setInterval(async () => {
          const res = await DashboardHttpService.documents();
          if (res.data?.length > 0) {
            setDocuments(res.data);
            clearDocumentsInterval();
            setIsLoading(false);
          }
        }, 2000);
        setIntervalId(newIntervalId);

        setTimeout(() => {
          if (isLoading) {
            clearDocumentsInterval();
            setIsLoading(false);
          }
        }, 30000);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    // return () => {
    //   clearDocumentsInterval();
    // };
    fetchDocuments();
  }, [clearDocumentsInterval]);

  return {
    documents,
    isLoading,
    areReportsUpdating,
    fetchDocuments,
    clearDocumentsInterval,
  };
};
