import { usePaymentDetails } from './payment-details.hook';
import { Preloader } from '../../shared-components/preloader';
import { BasePage } from '../base';
import { Container } from '../../shared-components/container';
import Footer from '../../shared-components/footer/footer.component';
import './payment-details.css';
import { useContext } from 'react';
import { PaymentHttpService } from '../../tools/api-services/payment.http-service';
import { PaymentHeaderComponent } from './components/payment-header.component';
import { CardDetailsComponent } from './components/card-details.component';
import {
  trackChangeCard,
  trackInvoicesLog,
  trackRenewSubscription,
} from '../../tools/analytics-events';
import { ToasterContext } from '../../context/toaster.context';

const handleInvoicesClick = () => {
  trackInvoicesLog();
};

const SubscriptionDetails = ({
  isSubscriptionActive,
  subscriptionExpriationDate,
  isSubscriptionGoingToBeCancelled,
}: any) => {
  if (!isSubscriptionActive) {
    return <p>You don't have active subscription</p>;
  }

  return (
    <div className="payment-details-card-item">
      {!isSubscriptionGoingToBeCancelled ? (
        <p>Your next payment bill</p>
      ) : (
        <p>Your subscription will be cancelled on</p>
      )}
      <p>{subscriptionExpriationDate}</p>
      <a href="transactions" onClick={handleInvoicesClick}>
        Invoices
      </a>
    </div>
  );
};

export const PaymentDetailsPage = () => {
  const {
    subscriptionDetails,
    cardDetails,
    isPaid,
    isPaymentLoading,
    isSubscriptionCanBeRenewed,
    email,
    firstName,
    flow,
  } = usePaymentDetails();

  const context = useContext(ToasterContext);

  const renewalPayment = async () => {
    trackRenewSubscription();
    const { data } = await PaymentHttpService.renewSubscription();

    if (data.subscriptionRenewed) {
      context.updateToaster({
        content: 'Your subscription was renewed',
      });
      if (data.link) {
        window.location = data.link;
      }
      flow();
    }

    if (!data.subscriptionRenewed) {
      window.location = data.link;
    }
  };

  const updateCard = async (e: any) => {
    e.preventDefault();
    trackChangeCard();
    const { data } = await PaymentHttpService.updateCard();
    window.location = data;
  };

  const headerProps = {
    renewalPayment,
    isSubscriptionCanBeRenewed,
    firstName,
    email,
  };

  return (
    <>
      <Preloader isLoading={isPaymentLoading} />
      <BasePage>
        <Container extended={true}>
          <PaymentHeaderComponent headerProps={headerProps} />
          <p style={{ display: !isPaid ? 'block' : 'none' }}>
            You don't have active subscriptions
          </p>
          <div style={{ display: isPaid ? 'block' : 'none' }}>
            <p className="payment-details__h1" style={{ marginBottom: '16px' }}>
              Your payment details
            </p>
            <div className="payment-details-card">
              <SubscriptionDetails
                isSubscriptionActive={
                  subscriptionDetails?.subscriptionStatus === 'active'
                }
                subscriptionExpriationDate={subscriptionDetails?.expirationDate}
                isSubscriptionGoingToBeCancelled={
                  subscriptionDetails?.isSubscriptionGoingToBeCancelled
                }
              />
              <CardDetailsComponent
                subscriptionDetails={subscriptionDetails}
                cardDetails={cardDetails}
                updateCard={updateCard}
              />
            </div>
          </div>
          <div className="separator"></div>
          <div>
            <p className="content-header-description">
              <span>To delete your account, please contact </span>
              <span style={{ textDecoration: 'underline' }}>
                support@fuelfinance.me
              </span>
            </p>
            <p className="content-header-description">
              <span>
                Please note that the email should be sent from the address you
                used for the registration.
              </span>
            </p>
          </div>
        </Container>
        <Footer></Footer>
      </BasePage>
    </>
  );
};
