import React, { useEffect, useMemo, useState } from 'react';
import './styles.css';
import { formatDateV2 } from '../../../../utils/dashboard/formatters';
import useDrillDownData from './drill-down.hook';
import { MetricsType } from '../../../../tools/manager-api/types/filter.dto';
import { Download, SquareX } from 'lucide-react';
import { useParams } from 'react-router-dom';

interface DrillDownProps {
  title: string;
  metricType: string;
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  onClose: () => void;
}

const DrillDownModal = ({
  title,
  metricType,
  dateRange,
  onClose,
}: DrillDownProps) => {
  const { companyId } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalFinancialPages, setTotalFinancialPages] = useState(1);
  const [totalSalesPages, setTotalSalesPages] = useState(1);

  const metricsDataFilterDto = useMemo(
    () => ({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      metricsType: metricType as MetricsType,
      page: currentPage,
      limit: 100,
    }),
    [dateRange.startDate, dateRange.endDate, metricType, currentPage]
  );

  const {
    drillDownData,
    loading,
    exportMetricsData,
    totalSalesRecords,
    totalFinancialRecords,
  } = useDrillDownData({
    companyId: Number(companyId),
    metricsDataFilterDto,
  });

  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    setTotalFinancialPages(
      Math.ceil(totalFinancialRecords / metricsDataFilterDto.limit)
    );
    setTotalSalesPages(
      Math.ceil(totalSalesRecords / metricsDataFilterDto.limit)
    );
  }, [metricsDataFilterDto.limit, totalFinancialRecords, totalSalesRecords]);

  useEffect(() => {
    if (drillDownData?.financialData?.length) {
      setActiveTab('FINANCIAL_DATA');
    } else if (drillDownData?.salesData?.length) {
      setActiveTab('SALES_DATA');
    } else {
      setActiveTab('');
    }
  }, [drillDownData]);

  const formatField = (field: string | undefined): string => {
    if (field === undefined || field === null) {
      return '';
    }
    return field.includes(',') ? `"${field.replace(/"/g, '""')}"` : field;
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'FINANCIAL_DATA':
        return (
          <>
            <div className="drilldown-table-container">
              <table>
                <thead>
                  <tr>
                    <th>Transaction Date</th>
                    <th>Transaction Type</th>
                    <th>Transaction #</th>
                    <th>Category BS</th>
                    <th>Category PL</th>
                    <th>Category CF</th>
                    <th>Amount</th>
                    <th>Counterpart / Name</th>
                    <th>Description</th>
                    <th>Project</th>
                    <th>Product</th>
                    <th>Organization</th>
                    <th>Region</th>
                    <th>Customer</th>
                    <th>Last Modified Date</th>
                    <th>Projected/Actual</th>
                  </tr>
                </thead>
                <tbody>
                  {drillDownData?.financialData?.map((transaction, index) => (
                    <tr key={index}>
                      <td>{transaction.date?.split('T')[0] || ''}</td>
                      <td>{transaction.type || ''}</td>
                      <td>{transaction.transactionId || ''}</td>
                      <td>{formatField(transaction.bsCategory)}</td>
                      <td>{formatField(transaction.plCategory)}</td>
                      <td>{formatField(transaction.cfCategory)}</td>
                      <td>
                        {transaction.sign
                          ? transaction.amount / 100
                          : -(transaction.amount / 100) || ''}
                      </td>
                      <td>{formatField(transaction.vendor)}</td>
                      <td>{formatField(transaction.description)}</td>
                      <td>{formatField(transaction.project)}</td>
                      <td>{formatField(transaction.product)}</td>
                      <td>{formatField(transaction.organization)}</td>
                      <td>{formatField(transaction.region)}</td>
                      <td>{formatField(transaction.customer)}</td>
                      <td>{transaction.updatedDate?.split('T')[0] || ''}</td>
                      <td>{transaction.projectedActual || ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="pagination">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>{`${currentPage} / ${totalFinancialPages}`}</span>
              <button
                onClick={() =>
                  setCurrentPage((prev) =>
                    Math.min(prev + 1, totalFinancialPages)
                  )
                }
                disabled={currentPage === totalFinancialPages}
              >
                Next
              </button>
            </div>
          </>
        );
      case 'SALES_DATA':
        return (
          <>
            <div className="drilldown-table-container">
              <table>
                <thead>
                  <tr>
                    <th>Invoice/Sale ID</th>
                    <th>Customer ID</th>
                    <th>Customer name</th>
                    <th>Subscription ID</th>
                    <th>Transaction ID</th>
                    <th>Transaction type</th>
                    <th>Date of creation</th>
                    <th>Payment date</th>
                    <th>Start date (Invoice or Subscription)</th>
                    <th>End date (Invoice or Subscription)</th>
                    <th>Status</th>
                    <th>Currency</th>
                    <th>Revenue amount in reporting currency</th>
                    <th>Number of units sold</th>
                    <th>Amount refunded in reporting currency</th>
                    <th>Fee amount in reporting currency</th>
                    <th>Tax amount in reporting currency</th>
                    <th>Description</th>
                    <th>Product name</th>
                    <th>Region</th>
                  </tr>
                </thead>
                <tbody>
                  {drillDownData?.salesData?.map((transaction, index) => (
                    <tr key={index}>
                      <td>{transaction.invoiceId || ' '}</td>
                      <td>{transaction.customerId || ' '}</td>
                      <td>{transaction.customerName || ' '}</td>
                      <td>{transaction.subscriptionId || ' '}</td>
                      <td>{transaction.transactionId || ' '}</td>
                      <td>{transaction.transactionType || ' '}</td>
                      <td>{transaction.createdDate?.split('T')[0] || ' '}</td>
                      <td>{transaction.paymentDate?.split('T')[0] || ' '}</td>
                      <td>{transaction.startDate?.split('T')[0] || ' '}</td>
                      <td>{transaction.endDate?.split('T')[0] || ' '}</td>
                      <td>{transaction.status || ' '}</td>
                      <td>{transaction.currency || ' '}</td>
                      <td>{transaction.revenueAmount / 100}</td>
                      <td>{transaction.unitsSold || ' '}</td>
                      <td>{transaction.refundedAmount / 100 || ' '}</td>
                      <td>{transaction?.feeAmount / 100 || ' '}</td>
                      <td>{transaction?.taxAmount / 100 || ' '}</td>
                      <td>{formatField(transaction.description) || ' '}</td>
                      <td>{transaction.product || ' '}</td>
                      <td>{transaction.region || ' '}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="pagination">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>{`${currentPage} / ${totalSalesPages}`}</span>
              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalSalesPages))
                }
                disabled={currentPage === totalSalesPages}
              >
                Next
              </button>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="drilldown-modal">
      <div className="drilldown-modal-header">
        <div className="drilldown-title">
          <h2>{`${title} drill-down`}</h2>
          <span className="daterange">
            <span style={{ color: '#777' }}>DATE RANGE: </span>
            <b>
              {`${formatDateV2(
                dateRange.startDate.toISOString().split('T')[0]
              ).toUpperCase()} - ${formatDateV2(
                dateRange.endDate.toISOString().split('T')[0]
              ).toUpperCase()}`}
            </b>
          </span>
        </div>
        <div className="drilldown-header-actions">
          <Download
            onClick={(e) => {
              e.stopPropagation();
              exportMetricsData();
            }}
          />
          <SquareX
            onClick={onClose}
            style={{ width: '35px', height: '35px' }}
            strokeWidth={'0.7'}
          />
        </div>
      </div>

      <div className="drilldown-modal-tabs">
        {drillDownData?.financialData?.length > 0 && (
          <button
            className={`drilldown-tab ${
              activeTab === 'FINANCIAL_DATA' ? 'active' : ''
            }`}
            onClick={(e) => {
              e.stopPropagation();
              setActiveTab('FINANCIAL_DATA');
            }}
          >
            FINANCIAL DATA
          </button>
        )}
        {drillDownData?.salesData?.length > 0 && (
          <button
            className={`drilldown-tab ${
              activeTab === 'SALES_DATA' ? 'active' : ''
            }`}
            onClick={(e) => {
              e.stopPropagation();
              setActiveTab('SALES_DATA');
            }}
          >
            SALES DATA
          </button>
        )}
      </div>

      <div className="drilldown-tab-content">
        {loading ? (
          <div className="drilldown-loading-container">
            <div className="drilldown-loader"></div>
          </div>
        ) : (
          renderTabContent()
        )}
      </div>
    </div>
  );
};

export default DrillDownModal;
