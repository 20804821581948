import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

interface ButtonProps {
  onClick?: any;
  active: boolean;
  mini?: boolean;
  light?: boolean;
  invisible?: boolean;
  style?: CSSProperties;
  children: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  grey?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  active,
  children,
  mini,
  light,
  invisible,
  style,
  type,
  grey,
}: ButtonProps) => {
  const buttonClasses = classNames('button', {
    active: active,
    'btn-mini': mini,
    light: light,
    invisible: invisible,
    grey: grey,
  });

  return (
    <button
      onClick={onClick}
      className={buttonClasses}
      style={style}
      type={type}
      disabled={!active}
    >
      {children}
    </button>
  );
};
