import { BasePage } from '../base';
import { Container } from '../../shared-components/container';
import { UpdatedButtonComponent } from '../../shared-components/button/updated.button.component';
import './payment-details.css';
import { Typography } from '../../shared-components/typography/typography.component';
import { PaymentHttpService } from '../../tools/api-services/payment.http-service';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { ToasterContext } from '../../context/toaster.context';

export const SubscriptionCancelPage = () => {
  const navigate = useNavigate();
  const context = useContext(ToasterContext);

  const submit = async () => {
    await PaymentHttpService.cancelSubscription();
    context.updateToaster({
      content: 'Subscription cancelled',
    });
    navigate('/payment-details');
  };

  const cancel = () => {
    navigate('/payment-details');
  };

  return (
    <BasePage>
      <Container extended={true}>
        <Typography type={'h1'} size={'medium'}>
          ️☠ 38% of startups fail because they run out of cash
        </Typography>
        <Typography type={'p'} size={'medium'}>
          Are you sure you want to cancel your subscription?
        </Typography>
        <div className="subscription-cancel__buttons">
          <UpdatedButtonComponent onClick={cancel} type={'default'}>
            No, please
          </UpdatedButtonComponent>
          <UpdatedButtonComponent onClick={submit} type={'empty'}>
            Yes please
          </UpdatedButtonComponent>
        </div>
      </Container>
    </BasePage>
  );
};
