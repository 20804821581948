import { createContext } from 'react';
import { UserRole, UserType } from '../tools/api-services/types/client';

export type userType =
  | 'BOOTSTRAP'
  | 'HUBSPOT'
  | 'MANUAL'
  | 'MANAGER'
  | 'UNKNOWN'
  | 'BOOTSTRAP_NEXT';

interface RoleContextType {
  role: UserRole | null;
  userType?: UserType;
  setRole: (role: UserRole | null) => void;
  setRoleType: (type: UserType) => void;
  accessibleResources?: string[];
  setAccessibleResources: (resources: string[]) => void;
}

export const RoleContext = createContext<RoleContextType>({
  role: null,
  setRole: (role: UserRole | null) => {
    console.warn('setRole is not implemented');
  },
  setRoleType: (type: UserType) => {
    console.warn('setRoleType is not implemented');
  },
  setAccessibleResources: (resources: string[]) => {
    console.warn('setAccessibleResources is not implemented');
  },
});
