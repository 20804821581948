import { reverseSign } from '../../../../utils/dashboard/formatters';
import { ChartDataItem } from '../../../../hooks/client/dashboard/interfaces/dashboard-data-item.interface';
import { HeaderItem, HeaderItemProps } from './header-item';

export const SnapshotDiagramHeader = ({
  headerMetrics,
}: {
  headerMetrics: {
    revenue: ChartDataItem;
    margin: ChartDataItem;
    opex: ChartDataItem;
    profitLoss: ChartDataItem;
    cashbalance: ChartDataItem;
    runwayMetrics: ChartDataItem;
  };
}) => {
  if (!headerMetrics) {
    return null;
  }

  const items: HeaderItemProps[] = [
    {
      name: '💰 REVENUE, $',
      value: headerMetrics.revenue,
      valueType: 'sum',
    },
    {
      name: '🫰 GROSS MARGIN, $',
      value: headerMetrics.margin,
      valueType: 'sum',
    },
    {
      name: '💰 OPEX, $',
      value: {
        month: headerMetrics.opex.month,
        year: headerMetrics.opex.year,
        monthData: {
          value: reverseSign(headerMetrics.opex.monthData.value),
        },
        prevMonthData: {
          value: reverseSign(headerMetrics.opex.prevMonthData.value),
        },
      },
      valueType: 'sum',
    },
    {
      name: '💵 NET PROFIT, $',
      value: headerMetrics.profitLoss,
      valueType: 'sum',
    },
    {
      name: '💳 CASH BALANCE, $',
      value: headerMetrics.cashbalance,
      valueType: 'sum',
    },
    {
      name: '💸 RUNWAY, MONTHS',
      value: headerMetrics.runwayMetrics,
      valueType: 'number',
    },
  ];

  return (
    <div className={'header-holder'}>
      {items.map((item, index) => {
        return (
          <HeaderItem
            key={index}
            name={item.name}
            value={item.value}
            valueType={item.valueType}
          />
        );
      })}
    </div>
  );
};
