import { DefaultProps } from '../types/default';
import './style.css';

export interface CheckboxInputProps extends DefaultProps {
  handleChange: any;
  value: string;
  controlName: string;
  checked?: boolean;
}

export const CheckboxInput = (props: CheckboxInputProps) => {
  return (
    <label>
      <input
        id={props.controlName}
        type="checkbox"
        value={props.value}
        checked={!!props.checked}
        onChange={props.handleChange}
      />
      <span></span>
      {props.value}
    </label>
  );
};
