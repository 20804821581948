import { useContext } from 'react';
import { ToasterContext } from '../../context/toaster.context';

export const ErrorToast = () => {
  const { isVisible, content } = useContext(ToasterContext);

  return (
    <div
      className={`error-toaster-message ${isVisible ? 'visible' : 'hidden'}`}
    >
      {content}
    </div>
  );
};
