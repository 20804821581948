import { Typography } from '../../../../shared-components/typography/typography.component';

export const DocumentsLoadingComponent = ({
  documentsLoading,
}: {
  documentsLoading: boolean;
}) => {
  return (
    <div>
      <img
        className="document-loader"
        style={{ width: '40px', height: '40px' }}
        src="images/document-loader.png"
        alt="document-loader"
      />
      <Typography size="medium" type="p" align="center">
        {documentsLoading
          ? 'Your documents are loading. It might take a few minutes.'
          : 'Your reports are updating. It might take a few minutes.'}
      </Typography>
    </div>
  );
};
