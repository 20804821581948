import { useState } from 'react';
import './period-selector.style.css';
import DateRangeModal from './date-range-modal/date-range-modal.component';

interface PeriodSelectorProps {
  onSelectPeriod: (
    period: string,
    dateRange: { startDate: Date; endDate: Date } | null
  ) => void;
}

export const getDateRange = (period: string) => {
  const now = new Date();
  const endDate = new Date(now.getFullYear(), now.getMonth(), 0);

  let startDate;

  switch (period) {
    case 'Last 12 months':
      startDate = new Date(endDate.getFullYear() - 1, endDate.getMonth(), 1);
      break;
    case 'Last month':
      startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
      break;
    case 'Last 3 months':
      startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 2, 1);
      break;
    case 'Half year':
      startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 5, 1);
      break;
    default:
      return null;
  }

  return { startDate, endDate };
};

const PeriodSelector = ({ onSelectPeriod }: PeriodSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('Last 12 months');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const periods = [
    'Last 12 months',
    'Specific range',
    'Last month',
    'Last 3 months',
    'Half year',
  ];

  const handleSelectPeriod = (period: string) => {
    if (period === 'Specific range') {
      setIsModalOpen(true);
      setIsOpen(false);
      return;
    }
    setSelectedPeriod(period);
    const dateRange = getDateRange(period);
    onSelectPeriod(period, dateRange);
    setIsOpen(false);
  };

  const handleModalConfirm = (dateRange: {
    startDate: Date;
    endDate: Date;
  }) => {
    setSelectedPeriod('Specific range');
    onSelectPeriod('Specific range', dateRange);
    setIsModalOpen(false);
  };

  return (
    <div className="period-selector">
      <button
        className="period-selector-button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedPeriod}
        <span className="period-selector-icon">&#9662;</span>
      </button>
      {isOpen && (
        <div className="period-selector-dropdown">
          {periods.map((period, index) => (
            <div
              key={index}
              className={`period-selector-item ${
                period === selectedPeriod ? 'selected' : ''
              }`}
              onClick={() => handleSelectPeriod(period)}
            >
              {period}
            </div>
          ))}
        </div>
      )}
      <DateRangeModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleModalConfirm}
      />
    </div>
  );
};

export default PeriodSelector;
