import { reverseSign } from '../../../../utils/dashboard/formatters';
import { ChartDataItem } from '../../../../hooks/client/dashboard/interfaces/dashboard-data-item.interface';
import { HeaderItem, HeaderItemProps } from './header-item';

export const CashflowDiagramHeader = ({
  headerMetrics,
}: {
  headerMetrics: {
    inflow: ChartDataItem;
    outflow: ChartDataItem;
    freeCashFlow: ChartDataItem;
    burnRate: ChartDataItem;
    cashbalance: ChartDataItem;
    runwayMetrics: ChartDataItem;
  };
}) => {
  if (!headerMetrics) {
    return null;
  }

  const items: HeaderItemProps[] = [
    {
      name: '💰 CASH BALANCE, $',
      value: headerMetrics.cashbalance,
      valueType: 'sum',
    },
    {
      name: '🔥 BURN RATE, $',
      value: {
        month: headerMetrics.burnRate.month,
        year: headerMetrics.burnRate.year,
        monthData: {
          value: reverseSign(headerMetrics.burnRate.monthData.value),
        },
        prevMonthData: {
          value: reverseSign(headerMetrics.burnRate.prevMonthData.value),
        },
      },
      valueType: 'sum',
    },
    {
      name: '🛟 RUNWAY, MONTHS',
      value: headerMetrics.runwayMetrics,
      valueType: 'number',
    },
    {
      name: '💵 INFLOW, $',
      value: headerMetrics.inflow,
      valueType: 'sum',
    },
    {
      name: '💵 OUTFLOW, $',
      value: headerMetrics.outflow,
      valueType: 'sum',
    },
    {
      name: '💰 FREE CASH FLOW, $',
      value: headerMetrics.freeCashFlow,
      valueType: 'sum',
    },
  ];

  return (
    <div className={'header-holder'}>
      {items.map((item, index) => {
        return (
          <HeaderItem
            key={index}
            name={item.name}
            value={item.value}
            valueType={item.valueType}
          />
        );
      })}
    </div>
  );
};
