import { DefaultProps } from './types/default';

interface HeadProps extends DefaultProps {
  center?: boolean;
}

export const Head = ({ children, center }: HeadProps) => {
  return (
    <p
      style={{
        textAlign: center ? 'center' : 'left',
      }}
      className="text-head"
    >
      {children}
    </p>
  );
};
