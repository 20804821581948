import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const FirebaseCallbackPage = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const oobCode = urlParams.get('oobCode');
  const mode = urlParams.get('mode');

  useEffect(() => {
    if (mode === 'veirfyEmail') {
      navigate('/login');
      return;
    }

    if (mode === 'resetPassword') {
      navigate('/complete-reset?oobCode=' + oobCode);
      return;
    }
  }, []);

  return <p>Mode {mode}</p>;
};
