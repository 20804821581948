import { instanceWithToken } from '../api';
import { AxiosData } from '../api-services/interfaces';
import { CommentPaginatedResponse } from '../api-services/types/Comment';
import { ChartPaginatedResponse } from '../api-services/types/chart.response';
import { MetricsDataFilterDto } from './types/filter.dto';

export class ManagerHttpDashboardService {
  public static comments(
    companyId: number,
    limit = 10
  ): Promise<CommentPaginatedResponse> {
    return instanceWithToken.get(
      `manager/company/${companyId}/comments?limit=${limit}`
    );
  }

  public static getMetricsList(
    companyId: number,
    page: number,
    limit: number
  ): Promise<any> {
    return instanceWithToken.post(
      `manager/company/${companyId}/metrics/filter`,
      {
        page,
        limit,
      }
    );
  }

  public static updateMetric(
    companyId: number,
    metricId: number,
    data: {
      description: string;
      metricGroup: string;
      metricType: string;
      benchmark: number;
    }
  ): Promise<any> {
    return instanceWithToken.put(
      `manager/company/${companyId}/metrics/${metricId}`,
      data
    );
  }

  public static createComment(
    companyId: number,
    formData: FormData
  ): Promise<void> {
    return instanceWithToken.post(
      `manager/company/${companyId}/comments`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  public static deleteComment(
    companyId: number,
    commentId: number
  ): Promise<void> {
    return instanceWithToken.delete(
      `manager/company/${companyId}/comments/${commentId}`
    );
  }

  public static markOnboardingCompleted(): Promise<void> {
    return instanceWithToken.post('manager/onboarding/complete');
  }

  public static getCharts(companyId: number): Promise<ChartPaginatedResponse> {
    return instanceWithToken.get(`manager/company/${companyId}/charts`);
  }

  public static createChart(
    companyId: number,
    data: {
      source: string;
      type: string;
    }
  ) {
    return instanceWithToken.post(`manager/company/${companyId}/charts`, data);
  }

  public static deleteChart(companyId: number, chartId: number) {
    return instanceWithToken.delete(
      `manager/company/${companyId}/charts/${chartId}`
    );
  }

  public static getMetricData(
    companyId: number,
    metricsDataFilterDto: MetricsDataFilterDto
  ): Promise<
    AxiosData<{
      financialData: {
        data: any[];
        total: number;
      };
      salesData: {
        data: any[];
        total: number;
      };
    }>
  > {
    return instanceWithToken.post(
      `manager/companies/${companyId}/dashboard/metrics/drill-down`,
      {
        ...metricsDataFilterDto,
      }
    );
  }

  public static exportMetricsData(
    companyId: number,
    metricsDataFilterDto: MetricsDataFilterDto
  ): Promise<any> {
    return instanceWithToken.post(
      `manager/companies/${companyId}/dashboard/metrics/export`,
      {
        ...metricsDataFilterDto,
      }
    );
  }
}
