import { BasePage } from '../base';
import { Container } from '../../shared-components/container';
import { Head } from '../../shared-components/head';
import { useReset } from './complete-reset.hook';
import { Input } from '../../shared-components/input';
import { Button } from '../../shared-components/button';
import { useState } from 'react';
import { isNotEmpty } from '../../tools/utils';
import './style.css';

export const CompleteResetPage = () => {
  const { form } = useReset();
  const { password, repeatPassword } = form.values;
  const isActive =
    isNotEmpty(password) &&
    isNotEmpty(repeatPassword) &&
    !form.errors.password &&
    !form.errors.repeatPassword;

  const [isPasswordInputFocused, setPasswordInputFocused] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    await form.submitForm();
  };

  return (
    <BasePage mini>
      <Container extended={false}>
        <Head>Please set new password</Head>
        <form onSubmit={handleSubmit}>
          <div className="reset-password-section">
            <div className="reset-password-inputs">
              <Input
                controlName={'password'}
                type={'password'}
                handleChange={form.handleChange}
                value={password}
                placeholder={'Password'}
                onFocus={() => setPasswordInputFocused(true)}
                onBlur={() => setPasswordInputFocused(false)}
                error={
                  (form.errors.password && password.length) ||
                  (repeatPassword !== password && repeatPassword.length)
                }
                showPasswordToggle={true}
              />
              {((form.errors.password && password.length) ||
                repeatPassword !== password) && (
                <div className="form-error">{form.errors.password}</div>
              )}
              <Input
                controlName={'repeatPassword'}
                type={'password'}
                handleChange={form.handleChange}
                value={repeatPassword}
                placeholder={'Repeat password'}
                error={
                  (form.errors.repeatPassword && repeatPassword.length) ||
                  (repeatPassword !== password && repeatPassword.length)
                }
              />
              {((form.errors.repeatPassword && repeatPassword.length) ||
                repeatPassword !== password) && (
                <div className="form-error">{form.errors.repeatPassword}</div>
              )}
            </div>
            {isPasswordInputFocused && (!password || form.errors.password) && (
              <div className="reset-password-requirements">
                <ul>
                  <li>At least 12 characters long</li>
                  <li>
                    At least one of each: uppercase letter, lowercase letter,
                    number, and a special symbols (!@#$%^&*)
                  </li>
                  <li>
                    Tip: it's best not to use a word that can be found in a
                    dictionary or the name of a person, character, product, or
                    organization
                  </li>
                </ul>
              </div>
            )}
          </div>
          <Button
            active={isActive}
            style={{ marginTop: '16px', width: '100%' }}
          >
            Set password
          </Button>
        </form>
      </Container>
    </BasePage>
  );
};
