export const parseCustomOptions = (customOptions: string) => {
  if (customOptions === null) {
    return [];
  }

  if (!customOptions.length) {
    return [];
  }

  return customOptions
    .split(';')
    .map((it) => it.trim())
    .filter((it) => it !== '')
    .map((it) => ({ name: it }));
};

export const isNotEmpty = (value: string) => Boolean(value.length);
