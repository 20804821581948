import React, { useState } from 'react';
import './add-employee.style.css';
import { SquareX } from 'lucide-react';
import { UpdatedButtonComponent } from '../../../../shared-components/button/updated.button.component';

interface AddEmployeeModalProps {
  onClose: () => void;
  onAdd: (
    employeeName: string,
    deliveryTeam?: boolean,
    startDate?: string,
    endDate?: string
  ) => void;
}

const AddEmployeeModal: React.FC<AddEmployeeModalProps> = ({
  onClose,
  onAdd,
}) => {
  const [employeeName, setAccountName] = useState('');
  const [deliveryTeam, setDeliveryTeam] = useState<boolean>(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleAddClick = () => {
    if (employeeName.trim() === '') {
      setError('Account name is required');
      return;
    }

    onAdd(employeeName, deliveryTeam, startDate, endDate);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="add-employee-modal">
        <div className="add-employee-modal__header">
          <h2>Add Employee</h2>
          <SquareX
            className="close-cross-button"
            onClick={onClose}
            style={{ width: '45px', height: '45px' }}
            strokeWidth={'0.7'}
          />
        </div>
        <div className="add-employee-modal__body">
          <div className="input-group">
            <input
              id="account-name"
              type="text"
              placeholder="Employee name"
              value={employeeName}
              onChange={(e) => {
                setAccountName(e.target.value);
                setError(null);
              }}
              name="employeeName"
            />
            {error && <span className="error-message">{error}</span>}
          </div>
          <div className="input-group checkbox-group">
            <input
              id="delivery-team"
              type="checkbox"
              checked={deliveryTeam}
              onChange={(e) => setDeliveryTeam(e.target.checked)}
              name="deliveryTeam"
            />
            <label htmlFor="delivery-team">Delivery Team</label>
          </div>
          <div className="input-group">
            <input
              id="start-date"
              type="date"
              placeholder="Start date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              name="startDate"
            />
          </div>
          <div className="input-group">
            <input
              id="end-date"
              type="date"
              placeholder="Start date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              name="endDate"
            />
          </div>
        </div>

        <UpdatedButtonComponent
          mini={true}
          type={'default'}
          onClick={handleAddClick}
        >
          Add
        </UpdatedButtonComponent>
      </div>
    </div>
  );
};

export default AddEmployeeModal;
