import { BasePage } from '../base';
import { Container } from '../../shared-components/container';
import { Head } from '../../shared-components/head';
import { RadioInput } from '../../shared-components/radio-input/radio.component';
import { Button } from '../../shared-components/button';
import Footer from '../../shared-components/footer/footer.component';
import './contact.css';
import { useBugReport } from './hooks/bug-report.hook';
export const BugReportPage = () => {
  const { bugReportForm, bugTypeValues, errors, touched } = useBugReport();

  return (
    <BasePage>
      <Container extended={true}>
        <Head>Report a bug</Head>
        <p id="first-subtitle" className="bug-subtitle">
          Please choose the type of bug you have
        </p>
        <form>
          <div className="bug-radio">
            {bugTypeValues.map((value, idx) => {
              return (
                <RadioInput
                  key={idx}
                  value={value}
                  handleChange={bugReportForm.handleChange}
                  checked={bugReportForm.values.bugType === value}
                  name="bugType"
                />
              );
            })}
          </div>
          <p id="last-subtitle" className="bug-subtitle">
            Please provide more bug details
          </p>
          <textarea
            className="bug-textarea"
            onChange={bugReportForm.handleChange}
            placeholder="Hey there! Describe your request"
            name="bugDescription"
          ></textarea>
          {errors.bugType && touched.bugType && (
            <div className="form-error">{errors.bugType}</div>
          )}
          {errors.bugDescription && touched.bugDescription && (
            <div className="form-error">{errors.bugDescription}</div>
          )}
        </form>

        <Button
          onClick={bugReportForm.submitForm}
          active={true}
          mini={true}
          type={'submit'}
          style={{ width: 200, height: 40, alignSelf: 'flex-end' }}
        >
          Submit
        </Button>
      </Container>
      <Footer></Footer>
    </BasePage>
  );
};
