import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { instanceWithToken } from '../../tools/api';
import { useContext } from 'react';
import { ToasterContext } from '../../context/toaster.context';

export const useResetPassword = () => {
  const navigate = useNavigate();
  const ctx = useContext(ToasterContext);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      try {
        await instanceWithToken.post('api/auth/password-reset', values);
        navigate('/reset-success');
      } catch (error: any) {
        if (error.response) {
          switch (error.response.status) {
            case 404:
              ctx.updateToaster({
                content:
                  "Looks like you don't have an account with us. Please register using the link below",
                isError: true,
              });
              break;
            case 500:
              ctx.updateToaster({
                content: 'Internal server error. Please try again later',
                isError: true,
              });
              break;
            default:
              ctx.updateToaster({
                content: 'An unexpected error occurred',
                isError: true,
              });
          }
        } else {
          ctx.updateToaster({
            content: 'An error occurred. Please try again',
            isError: true,
          });
        }
      }
    },
  });

  return {
    form: formik,
  };
};
