import { useContext, useEffect } from 'react';
import { Head } from '../../../../shared-components/head';
import { Container } from '../../../../shared-components/container';
import './style.css';
import HighlightArrow from '../../../../../assets/highlight-arrow.svg';
import { useNewDocuments } from './hooks/new-documents.hook';
import { CompanyDocument } from '../../../../tools/api-services/types/document';
import { QuickbooksConnectorComponent } from '../../../../shared-components/feature/quickbooks-connector.component';
import { useOverlay } from './hooks/overlay.hook';
import { Typography } from '../../../../shared-components/typography/typography.component';
import { useNotificationService } from '../../../../tools/notification';
import { trackOpenFile } from '../../../../tools/analytics-events';
import { RoleContext } from '../../../../context/role.context';

const DocumentsLoadingComponent = ({
  documentsLoading,
}: {
  documentsLoading: boolean;
}) => {
  return (
    <div>
      <img
        className="document-loader"
        style={{ width: '40px', height: '40px' }}
        src="images/document-loader.png"
        alt="document-loader"
      />
      <Typography size="medium" type="p" align="center">
        {documentsLoading
          ? 'Your documents are loading. It might take a few minutes.'
          : 'Your reports are updating. It might take a few minutes.'}
      </Typography>
    </div>
  );
};
export const OnboardingDocuments = ({
  stepNumber,
  isQuickbooksConnected,
}: {
  stepNumber: number;
  isQuickbooksConnected: boolean;
}) => {
  const { userType } = useContext(RoleContext);
  const { overlay, setOverlay } = useOverlay(stepNumber);
  const { isLoading, documents, fetchDocuments, areReportsUpdating } =
    useNewDocuments();

  const token = localStorage.getItem('token');
  const { importStatuses, reportStatuses } = useNotificationService(token);

  const documentClick = (link: string, name: string) => (): void => {
    trackOpenFile(name);
    setOverlay(false);
    window.open(link, '_blank');
  };

  const DocumentButton = ({ id, link, name }: CompanyDocument) => (
    <button key={id} onClick={documentClick(link, name)} className="document">
      {name}
    </button>
  );

  useEffect(() => {
    fetchDocuments();
  }, [importStatuses, reportStatuses, isLoading]);

  return (
    <Container extended={true}>
      {areReportsUpdating || isLoading ? (
        <DocumentsLoadingComponent documentsLoading={isLoading} />
      ) : (
        <>
          {userType === 'BOOTSTRAP' && overlay && !isLoading && (
            <>
              <div
                onClick={() => {
                  setOverlay(false);
                }}
                className="overlay"
              ></div>
              <span className="overlay-text">
                Hey! Here's a P&L template for your business model.
              </span>
              <div className="overlay-icon">
                <img src={HighlightArrow} alt="arrow" />
              </div>
            </>
          )}
          <div className="document-container">
            <div className="document-header">
              <Head>Your reports</Head>
            </div>
            <div className="document-holder">
              {documents.map((document, index) => (
                <DocumentButton key={index} {...document} />
              ))}
            </div>
            {!isQuickbooksConnected && <QuickbooksConnectorComponent />}
          </div>
        </>
      )}
    </Container>
  );
};
