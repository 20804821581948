import React from 'react';
import classNames from 'classnames';

interface ContainerProps {
  isLoading?: boolean;
  extended: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  maxWidth?: boolean;
}

export const Container: React.FC<ContainerProps> = ({
  isLoading,
  extended,
  children,
  style,
  maxWidth,
}) => {
  const containerClasses = classNames('container', {
    extended: extended,
    'is-loading': isLoading,
    'max-width': maxWidth,
  });

  return (
    <div className={containerClasses} style={style}>
      {children}
    </div>
  );
};
