import { BasePage } from '../base';
import { Preloader } from '../../shared-components/preloader';
import { ContactComponent } from './components/contact/contact.component';
import { useDashboard } from './hooks/dashboard.hook';
import { OnboardingDocuments } from './components/documents/documents.component';
import { OnboardingTutorials } from './components/tutorials/tutorials.component';
import { useStepper } from './hooks/stepper.hook';
import Footer from '../../shared-components/footer/footer.component';
import { DashboardHeader } from './components/header/header.component';
import { ErrorContent } from '../../shared-components/error-content/error-content';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { useQbConnector } from '../../shared-components/feature/qb-connector.hook';
import { ClientCommentsHoc } from '../../role-based-components/comments/comments.component';
import { RoleContext } from '../../context/role.context';
import { ManagerComponent } from '../../role-based-components/manager/manager.component';

export const BootstrapMainPage = () => {
  const { userType } = useContext(RoleContext);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const checkAuthQbConnectivity = async () => {
    const withIntegrationParam = searchParams.get('with-integration');

    if (withIntegrationParam) {
      await getLinkAndRedirect();
    }
  };

  const {
    userData,
    isLoading: isDashboardLoading,
    isOnboardingCompleted,
    isQuickbooksConnectPageValid,
  } = useDashboard();
  const {
    isQuickbooksConnected,
    currentStep,
    currentStepCompleted,
    isLoading: isStepperLoading,
  } = useStepper();
  const onboardingCompleted = isOnboardingCompleted && currentStep === 5;

  const { getLinkAndRedirect } = useQbConnector();
  useEffect(() => {
    checkAuthQbConnectivity();
    if (isQuickbooksConnectPageValid && !isQuickbooksConnected) {
      navigate('/quickbooks-connect');
    }
  }, [isQuickbooksConnected, isQuickbooksConnectPageValid, navigate]);

  const isStepTwo = currentStep === 2;
  const packageType = localStorage.getItem('package');
  if (
    packageType === 'bootstrapped' &&
    isStepTwo &&
    ((userType && ['BOOTSTRAP', 'BOOTSTRAP_NEXT'].includes(userType)) ||
      !userType)
  ) {
    navigate('/quickbooks-connect');
    return;
  } else if (
    packageType === 'free' &&
    isStepTwo &&
    ((userType && ['BOOTSTRAP', 'BOOTSTRAP_NEXT'].includes(userType)) ||
      !userType)
  ) {
    navigate('/tutorials/3');
    return;
  }

  const displaySuccessfull =
    userData?.paymentInfo?.isPaid &&
    !isQuickbooksConnected &&
    !userData?.paymentInfo?.stripeFailedPaymentAttempt;

  const displayPaymentError = userData?.paymentInfo?.stripeFailedPaymentAttempt;
  const isLoading = isDashboardLoading || isStepperLoading;

  return (
    <>
      <Preloader isLoading={isLoading} />
      {!isLoading && (
        <BasePage>
          <ManagerComponent />
          {displaySuccessfull ? (
            <ErrorContent
              errorTitle="Payment was successful!"
              errorDescription="Now connect your QuickBooks account to import your data"
            />
          ) : null}
          {displayPaymentError ? (
            <ErrorContent
              errorTitle="Oops something went wrong... we can not proceed your payment this time."
              errorDescription="Please try again to integrate your QuickBooks data into your Fuel reports"
            />
          ) : null}
          {onboardingCompleted ? (
            <ContactComponent
              active
              calendlyActive={userData?.hasCalendlyActive}
            />
          ) : null}
          {currentStep === 5 && currentStepCompleted ? null : (
            <DashboardHeader />
          )}
          <OnboardingDocuments
            stepNumber={currentStep}
            isQuickbooksConnected={isQuickbooksConnected}
          />
          {<ClientCommentsHoc />}
          <OnboardingTutorials stepNumber={currentStep} />

          {!onboardingCompleted ? (
            <ContactComponent calendlyActive={userData?.hasCalendlyActive} />
          ) : null}
          <Footer />
        </BasePage>
      )}
    </>
  );
};
