import { BasePage } from '../../pages/base';
import { CompanyDocuments } from './company-documents/company-documents.component';
import { ManagerCommentsHoc } from '../../role-based-components/comments/comments.component';
import Footer from '../../shared-components/footer/footer.component';
import { useParams } from 'react-router-dom';

export const ManagerCompanyPage = () => {
  return (
    <BasePage>
      <CompanyDocuments />
      <ManagerCommentsHoc />
      <Footer />
    </BasePage>
  );
};
