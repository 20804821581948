import { useContext, useState } from 'react';
import { useManagerCharts } from '../../../managers-pages/charts/manager-charts.hook';
import { RoleContext } from '../../../context/role.context';
import { ModalComponent } from '../../../shared-components/modal/modal-component';
import lookerLogo from '../../../../../public/images/looker-logo.png';
import { DiagramLookerHolderContext } from '../../../context/iframe-chart.context';

export const ChartIframeContainer = ({ source }: { source: string }) => {
  return (
    <div
      style={{
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '16px',
      }}
      className={'dashboard-advanced-chart'}
    >
      Loading...
      <iframe
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          borderRadius: '10px',
          boxSizing: 'border-box',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        className={'chart-iframe'}
        title="looker-chart"
        src={source}
      />
    </div>
  );
};

const ClientChartPlaceholder = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
      }}
      className={'dashboard-advanced-placeholder'}
    >
      <p>🤖</p>
      <p>There would be custom metrics for you</p>
    </div>
  );
};

export const ManagerChartPlaceholder = ({ chartType, createChart }) => {
  const [value, handleChangeValue] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const lookerContext = useContext(DiagramLookerHolderContext);

  const isLookerContext = lookerContext === 'LOOKER';

  const chartLinkValueChange = (e: any) => {
    handleChangeValue(e.target.value);
  };

  const addNewChart = async () => {
    await createChart({
      source: value,
    });
    handleChangeValue('');
  };

  return (
    <div className={'dashboard-advanced'}>
      <div className={'dashboard-advanced-placeholder'}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          {isLookerContext && (
            <img
              style={{
                width: '116px',
                height: '116px',
              }}
              src={lookerLogo}
              alt={'looker-logo'}
            />
          )}
          <div>
            <input
              className="dashboard-advanced-input"
              value={value}
              onChange={chartLinkValueChange}
              placeholder={'Link'}
            />
            <button onClick={addNewChart} className="dashboard-advanced-button">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ManagerDisconnectLooker = ({
  selectedChartId,
  deleteChart,
  createChart,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { role } = useContext(RoleContext);

  const lookerContext = useContext(DiagramLookerHolderContext);

  const isLookerContext = lookerContext === 'LOOKER';

  const disconnectingModalText =
    lookerContext === 'LOOKER'
      ? 'Looker disconnecting'
      : 'Disconnecting report';
  const modalText = `Are you sure you want to disconnect ${
    isLookerContext ? 'Looker' : 'this report'
  }?`;
  const disconnectText = isLookerContext
    ? 'Disconnect Looker'
    : 'Disconnect report';

  if (role !== 'MANAGER') {
    return null;
  }

  const deleteLooker = async () => {
    deleteChart(selectedChartId);
    setModalOpen(false);
    await deleteChart(selectedChartId);
  };

  return (
    <>
      {modalOpen && (
        <ModalComponent
          name={disconnectingModalText}
          isOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
        >
          <p>{modalText}</p>
          <button className={'disconnect-looker-button'} onClick={deleteLooker}>
            {disconnectText}
          </button>
        </ModalComponent>
      )}
      <button
        className={'disconnect-looker-button'}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {disconnectText}
      </button>
    </>
  );
};

export const ChartsPlaceholder = ({ chartType, createChart }) => {
  const { role } = useContext(RoleContext);

  if (role === 'MANAGER') {
    return (
      <ManagerChartPlaceholder
        createChart={createChart}
        chartType={chartType}
      />
    );
  }

  return <ClientChartPlaceholder />;
};

export const DiagramChartsHolderWithType = ({
  chartType,
}: {
  chartType: string;
}) => {
  const { charts, createChart, deleteChart } = useManagerCharts(chartType);
  if (!charts) {
    return (
      <ChartsPlaceholder createChart={createChart} chartType={chartType} />
    );
  }

  return (
    <div className={'dashboard-advanced'}>
      <ManagerDisconnectLooker
        deleteChart={deleteChart}
        createChart={createChart}
        selectedChartId={charts.id}
      />
      <ChartIframeContainer key={charts.id} source={charts.source} />
    </div>
  );
};
