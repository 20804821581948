import { Container } from '../../shared-components/container';
import './chart.style.css';
import { Head } from '../../shared-components/head';
import { Button } from '../../shared-components/button';
import { X } from 'lucide-react';

interface ChartComponentProps {
  source: string;
  onDelete?: () => Promise<void> | void | null;
}

export const ChartsRenderer = ({ charts }: { charts: any[] }) => {
  if (!charts.length) {
    return (
      <Container extended={true} maxWidth={true}>
        <Head>There are no charts prepared.</Head>
        <Button
          style={{ marginTop: '20px' }}
          light={true}
          mini={true}
          active={true}
          onClick={() => (window.location.href = '/')}
        >
          Go back
        </Button>
      </Container>
    );
  }

  return (
    <>
      {charts.map((chart, index) => (
        <ChartComponent key={index} source={chart.source} />
      ))}
    </>
  );
};

const DeleteComponent = ({
  onDelete,
}: {
  onDelete: () => Promise<void> | void | null;
}) => {
  return (
    <div className="chart-delete" onClick={onDelete}>
      <X className="cross-icon" size={'10px'} />
    </div>
  );
};

export const ChartComponent = ({ source, onDelete }: ChartComponentProps) => {
  return (
    <Container maxWidth={true} extended={true}>
      {onDelete && <DeleteComponent onDelete={onDelete} />}
      <div className="diagram-holder">
        <iframe frameBorder="0" scrolling={'no'} src={source}></iframe>
      </div>
    </Container>
  );
};
