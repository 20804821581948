import {
  signInWithPopup,
  getAuth,
  OAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { DashboardHttpService } from '../../tools/api-services/dashboardHttpService';

export const useNextLogin = () => {
  const navigate = useNavigate();
  const provider = new OAuthProvider('oidc.hivebrite');
  const [isNextLoading, setIsNextLoading] = useState(false);

  const handleRedirectResult = async () => {
    try {
      const result = await getRedirectResult(getAuth());
      console.log(result);
      console.log('openIdRedirectResult: ', result);
      if (result) {
        setIsNextLoading(true);
        const tkn = await result.user.getIdToken();
        localStorage.setItem('token', tkn);

        const response = await DashboardHttpService.nextRegistrationCompleted();
        navigate(response.data.redirectUrl);

        const url = new URL(window.location.href);
        url.searchParams.delete('next-login');
        window.history.replaceState({}, '', url.toString());
        sessionStorage.removeItem('openidInitialized');
      }
    } catch (error) {
      console.error(error);
      sessionStorage.removeItem('openidInitialized');
    } finally {
      setIsNextLoading(false);
    }
  };

  const processOpenidResult = async (data: any) => {
    const {
      user: { accessToken },
    } = data;
    localStorage.setItem('token', accessToken);
    navigate('/');
  };

  const handleOpenidPopup = async () => {
    const result = await signInWithPopup(getAuth(), provider);
    if (result) {
      await processOpenidResult(result);
    }
  };

  const handleOpenidRedirect = async () => {
    await signInWithRedirect(getAuth(), provider);
  };

  return {
    handleOpenidPopup,
    handleOpenidRedirect,
    handleRedirectResult,
    isNextLoading,
  };
};
