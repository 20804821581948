import { SquareX } from 'lucide-react';
import './modal-component.style.css';
interface ModalComponentProps {
  name: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalComponent = ({
  name,
  onClose,
  children,
}: ModalComponentProps) => {
  return (
    <div className="shared-modal-wrapper">
      <div className="shared-modal">
        <div className="shared-modal-controls">
          <span>{name}</span>
          <SquareX
            className="close-cross-button"
            onClick={onClose}
            style={{ width: '45px', height: '45px' }}
            strokeWidth={'1'}
          />
        </div>
        <div className="modal-data">{children}</div>
      </div>
    </div>
  );
};
