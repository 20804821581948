import './updates.style.css';
export const Updates = () => {
  return null;
  // return (
  //   <div className="update-component">
  //     <img
  //       src="https://avatars.slack-edge.com/2021-12-23/2863612894663_907979cd40810a6661b4_192.png"
  //       alt="Profile Picture"
  //       className="profile-pic"
  //     />
  //     <div>
  //       <div className="update-header">
  //         <div className="header-text">
  //           <span className="update-title">Update from CFO</span>
  //           <span className="update-date">/ Wed, Mar 19, 2024</span>
  //         </div>
  //         <div className="update-actions">
  //           <a href="#" className="update-link">
  //             ADD UPDATE
  //           </a>
  //           <a href="#" className="update-link">
  //             VIEW PREVIOUS UPDATES
  //           </a>
  //         </div>
  //       </div>
  //       <div className="update-message">
  //         Thank you for connecting your data sources and passing your data to
  //         us. Next step is us working with your data. It will take up to 4
  //         business days. You will be notified once it is done.
  //       </div>
  //     </div>
  //   </div>
  // );
};
