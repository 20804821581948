import { useState, useCallback, useEffect } from 'react';
import { ManagerHttpDashboardService } from '../../../../tools/manager-api/manager-http-dashboard';
import { MetricsDataFilterDto } from '../../../../tools/manager-api/types/filter.dto';
import { DashboardHttpService } from '../../../../tools/api-services/dashboardHttpService';

interface DrillDownData {
  financialData: any[];
  salesData: any[];
}

interface UseDrillDownDataProps {
  companyId: number;
  metricsDataFilterDto: MetricsDataFilterDto;
}

const useDrillDownData = ({
  companyId,
  metricsDataFilterDto,
}: UseDrillDownDataProps) => {
  const [drillDownData, setDrillDownData] = useState<DrillDownData>({
    financialData: [],
    salesData: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [totalFinancialRecords, setTotalFinancialRecords] = useState<number>(0);
  const [totalSalesRecords, setTotalSalesRecords] = useState<number>(0);
  const role = localStorage.getItem('role');

  const fetchDrillDownData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      let response;
      if (role === 'MANAGER') {
        response = await ManagerHttpDashboardService.getMetricData(
          companyId,
          metricsDataFilterDto
        );
      } else if (role === 'CLIENT') {
        response = await DashboardHttpService.getMetricData(
          metricsDataFilterDto
        );
      }

      setDrillDownData({
        financialData: response?.data.financialData.data || [],
        salesData: response?.data.salesData.data || [],
      });
      setTotalFinancialRecords(response?.data.financialData?.total || 0);
      setTotalSalesRecords(response?.data.salesData?.total || 0);
    } catch (err) {
      setError('Failed to fetch drill-down data');
    } finally {
      setLoading(false);
    }
  }, [companyId, metricsDataFilterDto, role]);

  const exportMetricsData = async () => {
    setLoading(true);
    setError(null);
    try {
      let response;

      if (role === 'MANAGER') {
        response = await ManagerHttpDashboardService.exportMetricsData(
          companyId,
          metricsDataFilterDto
        );
      } else if (role === 'CLIENT') {
        response = await DashboardHttpService.exportMetricsData(
          metricsDataFilterDto
        );
      }

      if (response?.data?.downloadUrls?.length > 0) {
        for (const url of response.data.downloadUrls) {
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = url;
          document.body.appendChild(iframe);

          setTimeout(() => {
            document.body.removeChild(iframe);
          }, 5000);
        }
      }
    } catch (err) {
      setError('Failed to export metrics data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDrillDownData();
  }, [fetchDrillDownData]);

  return {
    drillDownData,
    loading,
    error,
    fetchDrillDownData,
    totalFinancialRecords,
    totalSalesRecords,
    exportMetricsData,
  };
};

export default useDrillDownData;
