import classNames from 'classnames';
import './typography.style.css';

interface TypographyComponentProps {
  children: string;
  type: 'p' | 'h1';
  size: 'medium';
  align?: 'center';
}

export const Typography = (props: TypographyComponentProps) => {
  const { type, children, size } = props;

  const classes = classNames('typography', {
    typography__medium: size === 'medium',
    'align-center': props.align === 'center',
  });

  if (type === 'p') {
    return <p className={classes}>{children}</p>;
  }

  if (type === 'h1') {
    return <h1 className={classes}>{children}</h1>;
  }
};
