import { DashboardValueBuilderv2 } from '../../../../../../utils/dashboard/formatters';

export const CustomBarLabel =
  (chartWidth: number, isPercents: boolean) =>
  ({ bars }: any) => {
    const formattedValue = (initialValue: number) => {
      return new DashboardValueBuilderv2(initialValue)
        .formatValue(1)
        .shouldIncludeLetter(true)
        .getValue();
    };
    return bars.map((bar: any) => (
      <g
        key={bar.key}
        transform={`translate(${bar.x + bar.width / 2}, ${
          bar.data.value < 0 ? bar.y + bar.height + 10 : bar.y - 10
        })`}
      >
        {/* Text Outline (Stroke) */}
        <text
          textAnchor="middle"
          style={{
            fill: 'none', // No fill for the stroke layer
            stroke: 'white', // Outline color (white)
            strokeWidth: 2, // Outline thickness
            fontSize: chartWidth < 500 ? 8 : 10,
          }}
        >
          {bar.data.value !== 0 && formattedValue(bar.data.value)}
        </text>

        {/* Text Fill */}
        <text
          textAnchor="middle"
          style={{
            fill: 'black', // Fill color for the text
            fontSize: chartWidth < 500 ? 8 : 10, // Same font size
          }}
        >
          {bar.data.value !== 0 && formattedValue(bar.data.value)}
        </text>
      </g>
    ));
  };
