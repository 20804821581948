export const ErrorContent = ({ errorHeaders }: { errorHeaders: any }) => (
  <>
    <div>We can’t find next columns or they don’t have data:</div>
    <hr style={{ border: 'none' }} />
    <div style={{ marginTop: '20px' }}>
      {Array.isArray(errorHeaders) &&
        errorHeaders.map((header, index) => (
          <div key={index}>
            <strong>{header}</strong>
          </div>
        ))}
    </div>
    <hr style={{ border: 'none' }} />
    <div>
      Please update your file following the data structure in{' '}
      <a
        href="https://docs.google.com/spreadsheets/d/1rTuTaTh7FQF5BEzysU1Xrm-WwB95Sosw2_hlGmyDRx8/edit?gid=594633118#gid=594633118"
        target="_blank"
        rel="noopener noreferrer"
      >
        template
      </a>
      .
    </div>
  </>
);

export const ErrorNoAccess = () => (
  <>
    <div>We don’t have access to the file.</div>
    <hr style={{ border: 'none' }} />
    <hr style={{ border: 'none' }} />
    <div>Please, check if you have access to the provided file.</div>
  </>
);
