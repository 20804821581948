import { useParams } from 'react-router-dom';
import { IntegrationHttpService } from '../../../../tools/api-services/integrations.service';
import { ManagerHttpDataSourceService } from '../../../../tools/manager-api/manager-http-datasource';
import './styles.css';
import React, { useContext, useEffect, useState } from 'react';
import { useRootfiLink } from 'rootfi-react-sdk';
import { ToasterContext } from '../../../../context/toaster.context';
import { useUser } from '../../../../context/user-details.context';
import { trackAddIntegrationHubspot } from '../../../../tools/analytics-events';
import {
  CrmIntegrations,
  Provider,
  ProvidersMap,
} from '../../../../tools/api-services/types/connected-sources';

type ConnectHandlerMap = {
  [key in Provider]?: () => void;
};

export const CrmSystemsSource = ({
  setLoading,
}: {
  setLoading: (loading: boolean) => void;
}) => {
  const { companyId } = useParams();
  const [inviteLinkId, setInviteLinkId] = useState('');
  const { updateToaster } = useContext(ToasterContext);
  const { userDetails } = useUser();

  const [data, setData] = useState({
    authUri: '',
    integrationId: 0,
  });

  const getCrmAuthUrl = async (provider: Provider) => {
    const role = localStorage.getItem('role');
    trackAddIntegrationHubspot({
      companyName: userDetails.companyName,
      userName: userDetails.userName,
      userType: userDetails.userType,
    });
    try {
      if (role === 'MANAGER') {
        const { data } = await ManagerHttpDataSourceService.getCrmAuthUrl(
          Number(companyId),
          provider
        );
        setData({
          authUri: data.authUri,
          integrationId: data.integrationId,
        });
        return data.authUri;
      } else if (role === 'CLIENT') {
        const { data } = await IntegrationHttpService.getCrmAuthLink(provider);
        setData({
          authUri: data.authUri,
          integrationId: data.integrationId,
        });
        return data.authUri;
      }
      throw new Error('Unauthorized role');
    } catch (error) {
      console.error(`Failed to fetch ${ProvidersMap[provider]} URL`, error);
      return '';
    }
  };

  const abortCrmAuthUrl = async (data: { integrationId: number }) => {
    const role = localStorage.getItem('role');
    if (role === 'MANAGER') {
      await ManagerHttpDataSourceService.deleteCrmIntegration(
        data.integrationId
      );
    } else {
      await IntegrationHttpService.deleteCrmIntegration(data.integrationId);
    }
  };

  const { closeLink, openLink } = useRootfiLink({
    environment: 'global',
    inviteLinkId: inviteLinkId,
    onSuccess: (data: any) => {
      setTimeout(closeLink, 1000);
      setInviteLinkId('');
      setLoading(false);
      updateToaster({
        content: 'Connection successful',
        isError: false,
      });
      window.location.reload();
    },
    onExit: async () => {
      closeLink();
      setInviteLinkId('');
      setLoading(false);
      updateToaster({
        content: 'Connection aborted',
        isError: true,
      });
      await abortCrmAuthUrl(data);
    },
  });

  useEffect(() => {
    if (inviteLinkId) {
      openLink();
    }
  }, [inviteLinkId, openLink]);

  const handleConnect = async (provider: Provider) => {
    setLoading(true);
    const authId = await getCrmAuthUrl(provider);
    if (authId) {
      setInviteLinkId(authId);
    } else {
      setLoading(false);
    }
  };

  const connectHandlerMap: ConnectHandlerMap = CrmIntegrations.reduce(
    (map, provider) => {
      map[provider] = () => handleConnect(provider);
      return map;
    },
    {} as ConnectHandlerMap
  );

  return (
    <>
      {CrmIntegrations.map((crmIntegration) => (
        <button
          key={crmIntegration}
          onClick={connectHandlerMap[crmIntegration]}
          className={`crm-systems__${crmIntegration.toLowerCase()}`}
        >
          {ProvidersMap[crmIntegration]}
        </button>
      ))}
    </>
  );
};
