import { useContext, useEffect, useState } from 'react';
import { DashboardHttpService } from '../../../tools/api-services/dashboardHttpService';
import { IntegrationHttpService } from '../../../tools/api-services/integrations.service';
import { useNavigate } from 'react-router-dom';
import { ClientInfo } from '../../../tools/api-services/types/client';
import { RoleContext } from '../../../context/role.context';

export const useStepper = () => {
  const navigate = useNavigate();
  const { userType } = useContext(RoleContext);
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepCompleted, setCurrentStepCompleted] =
    useState<boolean>(false);
  const [isQuickbooksConnected, setIsQuickbooksConnected] = useState(false);
  const [businessModel, setBusinessModel] = useState('');
  const [stepName, setStepName] = useState('');
  const getBaseInfo = async () => {
    try {
      if (!localStorage.getItem('token')) {
        return;
      }

      await DashboardHttpService.isAuthenticated();

      const clientData = await DashboardHttpService.me();
      const client = clientData.data as ClientInfo;
      const model = client.clientCompanies[0].businessModel.businessModel;
      setBusinessModel(model);

      if (userType && ['BOOTSTRAP', 'BOOTSTRAP_NEXT'].includes(userType)) {
        const latestOnboardingStep =
          await DashboardHttpService.getLatestOnboardingStep();
        setCurrentStep(latestOnboardingStep.data.stepNumber);
        setCurrentStepCompleted(latestOnboardingStep.data.completed);

        const currentVideo = await DashboardHttpService.getTutorialVideo(
          latestOnboardingStep.data.stepNumber,
          model
        );
        setStepName(currentVideo.data.stepName);

        const quickbooksConnection =
          await IntegrationHttpService.checkQuickbooksConnection();
        setIsQuickbooksConnected(
          quickbooksConnection.data.isQuickbooksConnected
        );
      }
    } catch (e) {
      console.error('Error fetching base info:', e);
      navigate('/login');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBaseInfo();
  }, []);

  return {
    currentStep,
    isQuickbooksConnected,
    currentStepCompleted,
    stepName,
    businessModel,
    isLoading,
  };
};
