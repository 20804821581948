import React, { useState } from 'react';
import './metric-modal.style.css';
import { SquareX, ChevronDown } from 'lucide-react';
import { UpdatedButtonComponent } from '../../../../shared-components/button/updated.button.component';
import { Metric } from '../../../../tools/manager-api/types/metrics';
import { metricGroups, metricTypes } from '../../metric-standard-data';

interface MetricModalProps {
  data: Metric;
  onClose: () => void;
  onUpdate: (
    id: number,
    data: {
      description: string;
      metricGroup: string;
      metricType: string;
      benchmark: number;
    }
  ) => void;
}

const MetricModal: React.FC<MetricModalProps> = ({
  data,
  onClose,
  onUpdate,
}) => {
  const [metricData, setMetricData] = useState({
    description: data.description || '',
    metricGroup: data.metricGroup || '',
    metricType: data.metricType || '',
    benchmark: data.benchmark || 0,
  });

  const handleSaveButton = () => {
    onUpdate(data.id, metricData);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="metric-modal">
        <div className="metric-modal__header">
          <h2>{data.name}</h2>
          <div className="metric-modal__header_actions">
            <UpdatedButtonComponent
              mini={true}
              type={'default'}
              onClick={handleSaveButton}
            >
              Save
            </UpdatedButtonComponent>
            <SquareX
              className="metric-modal__close-button"
              onClick={onClose}
              strokeWidth={'0.7'}
            />
          </div>
        </div>
        <div className="metric-modal__body">
          <form>
            <div className="form-row">
              <div>
                <label className="metric-modal_input_label">
                  Actual formula
                </label>
                <input
                  className="metric-modal_input"
                  type="text"
                  value={data.actualFormula}
                  readOnly
                  placeholder="e.g. Ev to revenue"
                />
                <small className="connected-metrics">
                  CONNECTED METRICS:{' '}
                  {data?.connectedMetrics &&
                  data.connectedMetrics.length > 0 ? (
                    data.connectedMetrics.map((item, index) => (
                      <span key={item.name} className="metrics-name">
                        {item.name}
                        {index < data.connectedMetrics.length - 1 ? ', ' : ''}
                      </span>
                    ))
                  ) : (
                    <span className="metrics-name">No connected metrics</span>
                  )}
                </small>
              </div>
              <div>
                <label className="metric-modal_input_label">Plan formula</label>
                <input
                  className="metric-modal_input"
                  type="text"
                  value={data.planFormula}
                  readOnly
                  placeholder="e.g. EV / Revenue"
                />
              </div>
            </div>
            <div className="form-row full-width">
              <label className="metric-modal_input_label">
                Metric description
              </label>
              <textarea
                className="metric-modal_textarea"
                value={metricData.description}
                placeholder="Enter metric description"
                onChange={(e) =>
                  setMetricData({ ...metricData, description: e.target.value })
                }
              />
            </div>
            <div className="form-row">
              <div className="metric-modal_selector-container">
                <label className="metric-modal_input_label">Metric group</label>
                <select
                  className="metric-modal_selector"
                  value={metricData.metricGroup}
                  onChange={(e) =>
                    setMetricData({
                      ...metricData,
                      metricGroup: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    Select group
                  </option>{' '}
                  {metricGroups.map((group, index) => (
                    <option key={index} value={group}>
                      {group}
                    </option>
                  ))}
                </select>
                <ChevronDown className="metric-modal_selector-icon" />{' '}
              </div>
              <div className="metric-modal_selector-container">
                <label className="metric-modal_input_label">Metric type</label>
                <select
                  className="metric-modal_selector"
                  value={metricData.metricType}
                  onChange={(e) =>
                    setMetricData({
                      ...metricData,
                      metricType: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    Select type
                  </option>{' '}
                  {metricTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                <ChevronDown className="metric-modal_selector-icon" />{' '}
              </div>
            </div>
            <div className="divider-horizontal"></div>
            <div className="form-row">
              <div>
                <label className="metric-modal_input_label">Benchmark</label>
                <input
                  className="metric-modal_input"
                  type="number"
                  value={metricData.benchmark}
                  placeholder="e.g. 100"
                  onChange={(e) =>
                    setMetricData({
                      ...metricData,
                      benchmark: Number(e.target.value),
                    })
                  }
                />
              </div>
              <div>
                <label className="metric-modal_input_label">
                  Last 3-mon avg actual
                </label>
                <input
                  className="metric-modal_input"
                  type="number"
                  value={data.lastThreeMonthAvgActual}
                  readOnly
                  placeholder="e.g. 7"
                />
              </div>
              <div>
                <label className="metric-modal_input_label">
                  Next 3-mon avg target
                </label>
                <input
                  className="metric-modal_input"
                  type="number"
                  value={data.nextThreeMonthAvgTarget}
                  readOnly
                  placeholder="e.g. 7"
                />
              </div>
            </div>
            {/* Insert chart component or placeholder here */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default MetricModal;
