import { AxiosResponse } from 'axios';
import { instanceWithToken } from '../api';
import { AxiosData } from './interfaces';
import { Provider } from './types/connected-sources';
export class IntegrationHttpService {
  public static getQbLink(
    userType: string
  ): Promise<AxiosData<{ authUri: string }>> {
    return instanceWithToken.get(
      `/api/integrations/quickbooks/auth-uri/client?userType=${userType}`
    );
  }

  public static checkQuickbooksConnection(): Promise<
    AxiosData<{ isQuickbooksConnected: boolean }>
  > {
    return instanceWithToken.get(
      '/api/integrations/quickbooks/check-connection'
    );
  }

  public static deleteQuickbooksIntegration(integrationId: number) {
    return instanceWithToken.delete(
      `api/integrations/quickbooks/${integrationId}/client`
    );
  }

  public static deleteSalesIntegration(integrationId: number) {
    return instanceWithToken.delete(
      `api/integrations/sales/${integrationId}/client`
    );
  }

  public static deleteCrmIntegration(integrationId: number) {
    return instanceWithToken.delete(
      `api/integrations/crm/${integrationId}/client`
    );
  }

  public static getSalesAuthLink(
    provider: Provider
  ): Promise<AxiosData<{ authUri: string; integrationId: number }>> {
    return instanceWithToken.get(
      `/api/integrations/sales/auth-uri/client?provider=${provider}`
    );
  }

  public static getCrmAuthLink(
    provider: Provider
  ): Promise<AxiosData<{ authUri: string; integrationId: number }>> {
    return instanceWithToken.get(
      `/api/integrations/crm/auth-uri/client?provider=${provider}`
    );
  }

  public static deletePayrollIntegration(integrationId: number) {
    return instanceWithToken.delete(
      `api/integrations/payroll/${integrationId}/client`
    );
  }

  public static createPayrollIntegration(
    authCode: string,
    provider: string
  ): Promise<AxiosResponse<any>> {
    return instanceWithToken.post(`api/integrations/payroll`, {
      authCode: authCode,
      provider: provider,
    });
  }

  public static checkSalesConnection(
    provider: Provider
  ): Promise<AxiosData<{ isConnected: boolean }>> {
    return instanceWithToken.get(
      `/api/integrations/sales/check-connection?provider=${provider}`
    );
  }

  public static importSalesData(): Promise<AxiosData<void>> {
    return instanceWithToken.get('/api/import/sales');
  }
}
